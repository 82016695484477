import {
    ORG_SLACK_PAGE,
    GET_ORG_SLACK,
    DELETE_ORG_SLACK,
    FETCH_ORG_SLACK,
    EDIT_ORG_SLACK,
    ADD_ORG_SLACK,
    PENDING_ORG_SLACK,
    CLEAR_ORG_SLACK,
  } from "constants/actions";
  
  const initialState = {
    org_slack: [],
    pageNo: 1,
    pending: false,
    updated: false,
  };
  
  export default function OrgSlack(state = initialState, action) {
    switch (action.type) {
      case PENDING_ORG_SLACK:
        return {
          ...state,
          pending: true,
        };
      case ORG_SLACK_PAGE:
        return {
          ...state,
          pageNo: action.payload,
        };
      case GET_ORG_SLACK:
        return {
          ...state,
          org_slack: action.payload,
          pending: false,
          updated: false,
        };
      case FETCH_ORG_SLACK:
        return {
          ...state,
          [action.payload.org]: action.payload,
          pending: false,
          updated: false,
        };
      case EDIT_ORG_SLACK:
   
        return {
          ...state,
          pending: false,
          updated: action.payload,
          [action.payload.id]: action.payload,
        };
      case DELETE_ORG_SLACK:
        return {
          ...state,
          pending: false,
          updated: Math.random(),
        };
      case ADD_ORG_SLACK:
        return {
          ...state,
          pending: false,
          updated: action.payload,
          org_slack: [...state.org_slack, action.payload],
        };
      case CLEAR_ORG_SLACK:
        return {
          ...state,
          org_slack: [],
          updated: false,
        };
      default:
        return state;
    }
  }