import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
// Actions
import {
  SG_GET_TASK_SCHEDULES,
  SG_GET_TASK_TEMPLATES,
  SG_GET_TASK_TEMPLATE_SELECT,
} from "constants/actions";

// Pages
import LandingPage from "./LandingPage";
import SurveyFormPage from "./SurveyFormPage";
import SelectionPage from "./SelectionPage";
import PulseBuilderPage from "./PulseBuilderPage";
import PulseLibraryPage from "./PulseLibraryPage";
import PulseDataViewPage from "./PulseDataViewPage";
import EmptyPage from "./EmptyPage";

const PulseSurvey = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentView, setCurrentView] = useState();
  const [userHasData, setUserHasData] = useState(false);

  // Define navigateToForm function
  const navigateToForm = (survey) => {
    navigate("/leader/audit/AI/pulse/create", {
      state: { initialSurveyData: survey }, // survey contains only serializable fields
    });
  };

  const { scheduledPulseSurveys, allPulseSurveys, get_auth } = useSelector(
    (state) => ({
      scheduledPulseSurveys: state.task_templates.scheduled_tasks,
      allPulseSurveys: state.task_templates.task_templates,
      get_auth: state.auth,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (
      Number(get_auth?.employee_id) &&
      scheduledPulseSurveys?.results &&
      allPulseSurveys?.results
    ) {
      const filteredScheduled = scheduledPulseSurveys.results.filter(
        (survey) =>
          survey.created_by === Number(get_auth.employee_id) ||
          survey?.employee_access?.includes(Number(get_auth.employee_id))
      );

      const filteredAll = allPulseSurveys.results.filter((template) =>
        template?.user_tasks?.find(
          (task) =>
            task?.task_type === "created_by" &&
            task?.user_id === Number(get_auth.employee_id)
        )
      );

      if (filteredScheduled.length > 0 || filteredAll.length > 0) {
        setUserHasData(true);
      }
    }
  }, [get_auth, scheduledPulseSurveys, allPulseSurveys]);

  useEffect(() => {
    dispatch({ type: SG_GET_TASK_TEMPLATE_SELECT });
    dispatch({ type: SG_GET_TASK_SCHEDULES });
    dispatch({ type: SG_GET_TASK_TEMPLATES });
  }, [dispatch]);

  useEffect(() => {
    // add these to the regex, separated by a |
    const pathList = [
      "create",
      "edit",
      "view",
      "survey-selection",
      "builder",
      "survey-library",
    ];
    const regex = new RegExp(
      `^/leader/audit/AI/pulse/(?:(${pathList.join("|")})(?:/(\\d+))?)?$`
    );

    // Regex to parse the path
    // const regex = /\/AI\/pulse(?:\/(create|edit|view|survey-selection)(?:\/(\d+))?)?$/;
    const match = location.pathname.match(regex);

    if (match) {
      const operation = match[1]; // "create", "edit", or "view"
      const id = match[2]; // ID if present

      switch (operation) {
        case "survey-selection":
          setCurrentView(<SelectionPage />);
          break;

        case "survey-library":
          setCurrentView(<PulseLibraryPage navigateToForm={navigateToForm} />);
          break;

        case "builder":
          setCurrentView(<PulseBuilderPage navigateToForm={navigateToForm} />);
          break;

        case "create":
          setCurrentView(<SurveyFormPage mode="create" />);
          break;

        case "edit":
          setCurrentView(<SurveyFormPage mode="edit" surveyId={id} />);
          break;

        case "view":
          setCurrentView(<PulseDataViewPage surveyId={id} />);
          break;

        default:
          if (!scheduledPulseSurveys?.results && !allPulseSurveys?.results) {
            setCurrentView(null);
            return;
          }

          if (
            (scheduledPulseSurveys?.results?.length === 0 &&
              allPulseSurveys?.results?.length === 0) ||
            ((scheduledPulseSurveys?.results?.length > 0 ||
              allPulseSurveys?.results?.length > 0) &&
              !userHasData)
          ) {
            setCurrentView(<EmptyPage />);
          } else {
            setCurrentView(<LandingPage />);
          }
          setCurrentView(<LandingPage />);
          break;
      }
    } else {
      if (!scheduledPulseSurveys?.results && !allPulseSurveys?.results) {
        setCurrentView(null);
        return;
      }

      if (
        (scheduledPulseSurveys?.results?.length === 0 &&
          allPulseSurveys?.results?.length === 0) ||
        ((scheduledPulseSurveys?.results?.length > 0 ||
          allPulseSurveys?.results?.length > 0) &&
          !userHasData)
      ) {
        setCurrentView(<EmptyPage />);
      } else {
        setCurrentView(<LandingPage />);
      }
    }
  }, [location.pathname, scheduledPulseSurveys, allPulseSurveys, userHasData]);

  if (!currentView) {
    return null; // Optionally render a loader or fallback
  }

  return currentView;
};

export default PulseSurvey;
