import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import DataLoading from "app/audit/DataLoaders/LeaderDataLoader";
import SimpleAnalytics from "app/audit/simple/index";
import Reports from "app/audit/reports/index";
import AuditSurvey from "auditSurvey";
import AI from "reports/AI/index";
import DataStory from "reports/DataStories/index";
import LeaderAccountability from "app/audit/manage/LeaderAccountability";
import UserProfile from "app/audit/manage/LeaderAccountability/UserProfile";
import WelcomeOnboard from "app/audit/manage/Welcome";
import Tasks from "app/audit/manage/Tasks";
import EditTemplatePage from "app/audit/manage/Tasks/TaskTemplates_V2/actions/EditTemplate";
import ScheduleTemplatePage from "app/audit/manage/Tasks/TaskTemplates_V2/actions/ScheduleTemplate";
import OpenTasks from "app/audit/manage/Tasks/OpenTasks";
import Action from "app/audit/manage/Action";
import InsightView from "app/audit/simple/insights/View";
import InsightCreate from "app/audit/simple/insights/Create";
import PDFReport from "reports/PDFReport";
import CustomSurveyCreate from "app/audit/manage/Tasks/TaskTemplates_V2/Create";
import CustomSurveyReportView_V2 from "app/audit/manage/Tasks/TaskTemplates_V2/View";
import TaskTemplates_V2 from "app/audit/manage/Tasks/TaskTemplates_V2";
import QuestionData_V2 from "app/audit/manage/Tasks/TaskTemplates_V2/QuestionData";
import ROIView from "app/audit/reports/roi/View";
import ChangeView from "app/audit/reports/change";
import Diagnostic from "app/audit/simple/ActionReport/index";
import Presentation from "app/audit/reports/presentation/index";
import PulseBuilder from "ProtoType/PulseBuilder";
import SelectionScreen from "ProtoType/PulseBuilder/SelectionScreen";
import PulseLibrary from "ProtoType/PulseBuilder/Library";
import PulseSurvey from "app/audit/AI/PulseSurvey";
import DataSource from "ProtoType/DataSource";
import Slack from "ProtoType/Slack/proto/index";
import SlackLandingPage from "ProtoType/Slack/public/index";
import Files from "ProtoType/Files";
import PresentationView from "app/audit/reports/components/PresentationView";
import CSVUpload from "ProtoType/CSVUpload";
import Intervention from "ProtoType/Intervention";
import CoilotV2 from "ProtoType/CopilotV2";
import Iceberg from "ProtoType/Iceburg";
import AIV2 from "app/audit/AI/Copilot";
import SlackReports from "ProtoType/SlackReports";

import RawExport from "ProtoType/RawExport";
// import EditMemberPage from "app/audit/Members/EditMember";

import {
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_SURVEY_STANDARD,
  SG_GET_EMPLOYEE_CATEGORIES,
  SG_GET_SURVEY_QUESTIONS,
  SG_GET_OUTCOME_QUESTIONS,
  SG_GET_EMPLOYEE_USER_CATEGORIES,
} from "constants/actions";
import TaskQuestion from "app/audit/manage/Tasks/TaskQuestion";
import CreateTemplate from "app/audit/manage/Tasks/TaskTemplates_V2/actions/CreateTemplate";


const SURVEY_TYPE = 6;

const PERMISSIONS_MAP = [
  {
    route: "/reports/recV4",
    permission: ["access_recommendations"],
  },
];

function userHasAccess(data, permissionsMap) {
  const permissions = permissionsMap.permission;
  const userPerms = data?.filter((p) => p?.response)?.map((p) => p?.value);

  // check that the user has at least one of the required permissions
  if (!permissions?.some((p) => userPerms?.includes(p))) {
    return false;
  }

  return true;
}

function hasRequiredStructure(data, structure, redirect) {
  const userPermissions = data?.permissions;
  // If the user is not an admin, check if they have access to the page.
  const route = window.location.pathname;
  // use regex to extract everything after /leader/audit
  const result = route.match(/\.*\/audit(.*)/)?.[1];
  const permission = PERMISSIONS_MAP.find((p) => p.route === result);

  if (userPermissions?.account_type !== 5) {
    if (
      permission &&
      userPermissions?.employee_employee_permission?.auth_details &&
      !userHasAccess(
        userPermissions?.employee_employee_permission?.auth_details,
        permission
      )
    ) {
      redirect();
      return;
    }
  }

  // Check each top-level property in the structure against the data
  return Object.keys(structure).every((key) => data?.hasOwnProperty(key));
}

const LeaderRoutes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const core_data = useSelector(
    (state) => state.audit?.core_data,
    shallowEqual
  );
  // const [isManager, setIsManager] = useState();

  const {
    get_auth,
    get_survey_structure,
    get_survey_questions,
    get_employee_categories,
    employeeId,
    get_employees,
    employee_permission,
  } = useSelector(
    (state) => ({
      get_employees: state.employees,
      get_selectedOrg: state.selectedOrg,
      get_culture_audit_reports: state.debrief_schedule.culture_audit_reports,
      ambassadorId: Number(state.auth?.ambassador_id),
      get_organizations: state.organizations,
      employeeId: Number(state.auth?.employee_id),
      get_employee_categories:
        state.employees.userEmp.employee_employee_category,
      get_auth: state.auth,
      get_employee_user_category:
        state.employee_category.employee_user_category,
      get_employee_org_category: state.employee_category?.employee_org_category,
      get_survey_structure: state.surveystructure.survey_structure,
      survey_version: state.audit.survey_version,
      get_survey_questions: state.surveyquestions.survey_questions,
      get_standards: state.survey_standard.survey_standards,
      nav_state: state.audit.nav,
      employee_permission: state.employee_permission,
    }),
    shallowEqual
  );

  const isManager =
    core_data?.permissions?.account_type !== 5 &&
    get_employees?.userEmp?.id === Number(get_auth?.employee_id) &&
    get_employees?.userEmp?.employee_employee_permission?.access_level?.manager
      ?.active;

  useEffect(() => {
    // Check the route, if it equals /simple/audit/manage/questionnaire/:id
    // Update it so that its /leader/audit/manage/questionnaire/:id
    const route = window.location.pathname;
    if (route.includes("/simple/audit/manage/questionnaire") && !isManager) {
      navigate(route.replace("/simple", "/leader"));
    }
  }, [navigate]);

  // console.log(get_auth);
  // const isManager = false;
  // Fetch the survey structures and standards
  useEffect(() => {
    if (Number(get_auth.organization_id)) {
      console.log("fetching survey structures");
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${Number(get_auth.organization_id)}`,
      });
    }
  }, [get_auth.organization_id, dispatch]);

  useEffect(() => {
    dispatch({
      type: SG_GET_SURVEY_STANDARD,
      payload: `survey_type=${SURVEY_TYPE}`,
    });
  }, [dispatch]);

  // Fetch employee categories
  useEffect(() => {
    if (employeeId && get_employee_categories?.categories?.length === 0) {
      dispatch({
        type: SG_GET_EMPLOYEE_CATEGORIES,
        payload: `employee=${employeeId}`,
      });
    }

    if (employeeId) {
      dispatch({
        type: SG_GET_EMPLOYEE_USER_CATEGORIES,
        payload: `employee=${employeeId}`,
      });
    }
  }, [get_auth, dispatch]);

  // Fetch outcome questions
  useEffect(() => {
    if (get_survey_questions?.length > 0) {
      dispatch({
        type: SG_GET_OUTCOME_QUESTIONS,
        payload: `survey_type=10&status=2`,
      });
    }
  }, [dispatch, get_survey_questions]);

  // Basic Dispatches
  // Fetch Survey Questions
  useEffect(() => {
    if (SURVEY_TYPE && get_survey_questions?.length === 0) {
      dispatch({
        type: SG_GET_SURVEY_QUESTIONS,
        payload: `survey_type=${SURVEY_TYPE}&status=2`,
      });
    }
  }, [dispatch, SURVEY_TYPE]);

  // Here we create the required data structure to validate the data
  const dataStructure = {
    categories: null,
    questions: null,
    standards: null,
    surveyQuestions: null,
    get_standards: null,
  };

  const onboardingWrapper = (element, path, userData) => {
    if (
      userData?.permissions?.employee_employee_permission?.extra_data
        ?.onboarding_complete
      // Uncomment the next line and remove the comment above if you want to test without completing onboard.
      // !userData?.employee_employee_permission?.extra_data?.onboarding_complete
    ) {
      return <Route path={path} element={element} />;
      // Uncomment the next line and remove the above line if you want to re-add onboarding.
      // return <Route path={"*"} element={<WelcomeOnboard />} />;
    } else {
      return <Route path={path} element={element} />;
    }
  };

  const LEADER_ROUTES = [
    {
      path: "simple",
      element: <SimpleAnalytics />,
    },
    {
      path: "pdf",
      element: <PDFReport />,
    },
    {
      path: "simple/*",
      element: <SimpleAnalytics />,
    },
    {
      path: "reports",
      element: <Reports page={0} />,
    },
    {
      path: "reports/:id",
      element: <Reports page={0} />,
    },
    {
      path: "reports/stories",
      element: <Reports page={1} />,
    },
    {
      path: "reports/stories/create",
      element: <Reports page={1} />,
    },
    {
      path: "reports/stories/:id/:category",
      element: <DataStory tracked={false} />,
    },
    {
      path: "AI/insights/insight/:id/:share_id",
      element: <InsightView />,
    },
    {
      path: "AI/insights/create",
      element: <InsightCreate />,
    },
    {
      path: "datastory/:id",
      element: <DataStory tracked={false} />,
    },
    {
      path: "reports/recV4",
      element: <Reports page={2} />,
    },
    {
      path: "reports/recV4/:id",
      element: <Reports page={2} />,
    },
    {
      path: "AI/chat",
      element: <AI />,
    },
    {
      path: "AI/history",
      element: <AI currentTab="History" />,
    },
    {
      path: "AI/insights",
      element: <SimpleAnalytics />,
    },
    {
      path: "calendar",
      element: <AuditSurvey />,
    },
    {
      path: "manage",
      element: <LeaderAccountability />,
    },
    {
      path: "manage/userprofile/:id",
      element: <UserProfile />,
    },
    {
      path: "reports/workshop",
      element: <Reports page={3} />,
    },
    {
      path: "manage/tasktemplates",
      element: <TaskTemplates_V2 />,
    },
    {
      path: "AI/tasktemplates/edit/:id",
      element: <EditTemplatePage />,
    },
    {
      path: "AI/tasktemplates/schedule/:id",
      element: <ScheduleTemplatePage />,
    },
    {
      path: "AI/tasktemplates/create",
      element: <CreateTemplate />,
    },
    {
      path: "manage/task/:id",
      element: <QuestionData_V2 />,
    },
    {
      path: "manage/tasks",
      element: <OpenTasks />,
    },
    {
      path: "manage/questionnaire/:id",
      element: <TaskQuestion />,
    },
    {
      path: "action",
      element: <Action />,
    },
    {
      path: "manage/task/report/:id/:reportGroupId",
      element: <CustomSurveyReportView_V2 />,
    },
    {
      path: "manage/tasks/schedule/:id",
      element: <CustomSurveyCreate currentStep="schedule" />,
    },
    {
      path: "manage/tasktemplates/create",
      element: <CustomSurveyCreate currentStep="create" />,
    },
    {
      path: "manage/tasks/edit/:id",
      element: <CustomSurveyCreate currentStep="edit" />,
    },
    {
      path: "reports/roi/:id",
      element: <ROIView />,
    },
    {
      path: "reports/change/:id",
      element: <ChangeView />,
    },
    {
      path: "diagnostic",
      element: <Diagnostic />,
    },
    {
      path: "presentation",
      element: <Presentation />,
    },
    {
      path: "presentation/list",
      element: <PresentationView />,
    },
    {
      path: "bitoolkit/analysisdashboard",
      element: <SimpleAnalytics />,
    },
    {
      path: "AI/relationships",
      element: <SimpleAnalytics />,
    },
    {
      path: "pulsebuilder",
      element: <PulseBuilder />,
    },
    {
      path: "survey-selection",
      element: <SelectionScreen />,
    },
    {
      path: "survey-library",
      element: <PulseLibrary />,
    },
    {
      path: "AI/pulse/*",
      element: <PulseSurvey />,
    },
    {
      path: "survey-library",
      element: <PulseLibrary />,
    },
    {
      path: "data-source",
      element: <DataSource />,
    },
    {
      path: "slack-landing-page",
      element: <Slack />,
    },
    {
      path: "slack",
      element: <SlackLandingPage />,
    },
    {
      path: "files",
      element: <Files />,
    },
    {
      path: "raw-export",
      element: <RawExport />,
    },
    {
      path: "csv-upload",
      element: <CSVUpload />,
    },
    {
      path: "intervention",
      element: <Intervention />,
    },
    {
      path: "copilot",
      element: <CoilotV2 />,
    },
    {
      path: "iceberg",
      element: <Iceberg />,
    },
    {
      path: "AI/copilot",
      element: <AIV2 />,
    },
    {
      path: "slack-reports",
      element: <SlackReports />,
    },
  ];

  const MANAGER_ROUTES = [
    {
      path: "manage/welcome",
      element: <WelcomeOnboard />,
    },
    {
      path: "manage/tasks",
      element: <Tasks />,
    },
    {
      path: "reports/stories/:id/:category",
      element: <DataStory tracked={true} />,
    },
    {
      path: "AI/tasktemplates",
      element: <TaskTemplates_V2 />,
    },
    {
      path: "AI/questionnaire/:id",
      element: <TaskQuestion />,
    },
  ];

  return (
    <>
      {!hasRequiredStructure(core_data, dataStructure, () =>
        navigate("/404")
      ) && <DataLoading />}
      <Routes>
        {isManager
          ? MANAGER_ROUTES.map((route) => {
              return onboardingWrapper(
                route.element,
                route.path,
                get_employees?.userEmp
              );
            })
          : LEADER_ROUTES.map((route) => {
              return <Route path={route.path} element={route.element} />;
            })}
      </Routes>
    </>
  );
};

export default LeaderRoutes;
