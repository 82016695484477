import { v4 as uuidv4 } from "uuid";

export const buildParams = ({
  feedback_length,
  tags,
  selectedFactors,
  dataSources,
}) => ({
  feedback_length,
  tags,
  factors: selectedFactors,
  date: "",
  // NOTE: don't overwrite this, as it is an expected param for the API.
  context_data_length: 0,
  dataSources,
});

export const generateTopicName = (promptQuery) =>
  promptQuery
    .slice(0, 50)
    .toLowerCase()
    .replace(/ /g, "_")
    .replace(/[^a-zA-Z0-9_]/g, "");

export const addPromptToHistoryIfNeeded = (
  chatHistory,
  setChatHistory,
  promptQuery
) => {
  const lastMsg = chatHistory[chatHistory.length - 1];
  if (!lastMsg || lastMsg.type !== "prompt") {
    setChatHistory((prev) => [
      ...prev,
      { type: "prompt", message: promptQuery },
    ]);
  }
};

// Used to obtain meta options for params.
const getMetaOptions = (id, core) => {
  const options = core?.categories?.[0]?.categories?.find(
    (cat) => cat?.id === id
  );
  return options;
};