import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Components
import ImageButton from "new_components/molecules/ImageButton";
import DropdownContent from "new_components/atoms/DropdownContent";
import Text from "new_components/atoms/Text";

// Constants
import colors from "styles/colors";
import { ICON_SET } from "new_components/atoms/Icon/IconSet";
import Icon from "new_components/atoms/Icon";
import typography, { applyTypography } from "styles/typography";

// Styled Components
const SelectBoxWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: ${({ width }) => width || "auto"};
`;

const DropdownWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 8px;
  z-index: 1000;
`;

const StyledLabel = styled.label`
  position: absolute;
  top: ${({ isActive }) => (isActive ? "-8px" : "16px")};
  ${applyTypography(typography.body.regular)};
  font-size: ${({ isActive }) => (isActive ? "12px" : "14px")};
  color: ${({ isActive }) => (isActive ? colors.text.dark : colors.text.gray)};
  transition: all 0.2s ease-in-out;
  pointer-events: none;
  background-color: transparent; /* Prevent overlap */
  padding: 0 4px; /* Matches padding for alignment */
  z-index: ${({ isActive }) => (isActive ? "1" : "0")};
  font-weight:600;
  font-family: 'Raleway', sans-serif;
`;

const SelectButtonWrapper = styled.div`
  padding: 16px 0 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
`;

const SelectBox = ({ label, options, onSelect, width, value, settings }) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [hovered, setHovered] = useState(null);

  // Check if this component should support multiple selections
  const isMulti = settings?.includes("multi");

  // If multi, store an array of selected options; if single, just store one.
  const [selectedValue, setSelectedValue] = useState(
    isMulti
      ? Array.isArray(value) // If the user passed an array in "value", use it; else empty array
        ? value
            .map((val) =>
              typeof val === "string"
                ? options.find((o) => o.value === val) || null
                : val
            )
            .filter(Boolean) // remove any nulls
        : []
      : value || null
  );

  const handleToggle = () => setIsOpen((prev) => !prev);

  const handleOptionClick = (option) => {
    if (isMulti) {
      // Toggle selection in multi-select mode
      setSelectedValue((prev) => {
        const isAlreadySelected = prev.some(
          (selected) => selected.value === option.value
        );
        let updated;
        if (isAlreadySelected) {
          // Remove from selected if already chosen
          updated = prev.filter((selected) => selected.value !== option.value);
        } else {
          // Add to selected
          updated = [...prev, option];
        }
        // Call onSelect with the array of values
        onSelect(updated.map((item) => item.value));
        return updated;
      });
    } else {
      // Single-select mode
      setSelectedValue(option);
      setIsOpen(false);
      onSelect(option.value);
    }
  };

  // If "value" changes externally, update our internal selectedValue
  useEffect(() => {
    if (isMulti) {
      if (Array.isArray(value)) {
        const newValues = value.map((val) =>
          typeof val === "string"
            ? options.find((o) => o.value === val) || null
            : val
        );
        setSelectedValue(newValues.filter(Boolean));
      }
    } else {
      if (typeof value === "string") {
        setSelectedValue(
          options.find((option) => option.value === value) || null
        );
      } else {
        setSelectedValue(value || null);
      }
    }
  }, [value, options, isMulti]);

  const handleClickAway = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickAway);
    return () => document.removeEventListener("click", handleClickAway);
  }, []);

  // For single selection, just show selectedValue?.label
  // For multi selection, show comma-separated labels
  const displayLabel = isMulti
    ? selectedValue.map((item) => item.label).join(", ")
    : selectedValue?.label || "";

  // For floating label logic, consider multi or single
  // If multi, we are "active" if we have at least one selection
  // If single, we are "active" if we have selectedValue
  const isActive = isMulti ? selectedValue.length > 0 : !!selectedValue;

  return (
    <SelectBoxWrapper width={width} ref={ref}>
      {/* Floating Label */}
      <StyledLabel isActive={isActive}>{label}</StyledLabel>

      {/* Select Button */}
      <SelectButtonWrapper onClick={handleToggle}>
        <ImageButton
          text={displayLabel}
          icon={ICON_SET.dropdown}
          iconPosition="right"
          textStyle={true}
          isDropdown={true}
          color={colors.text.gray}
          iconProps={{ rotate: isOpen ? 180 : 0 }}
        />
      </SelectButtonWrapper>

      {/* Dropdown Content */}
      {isOpen && (
        <DropdownWrapper onMouseLeave={() => setHovered(null)}>
          <DropdownContent scrollable maxHeight="200px">
            {options.map((option, index) => {
              const isSelected = isMulti
                ? selectedValue.some(
                    (sel) => sel.value?.id === option.value?.id
                  )
                : selectedValue?.value === option.value;
              const isHovered = hovered?.value === option.value;

              const iconName = isSelected
                ? ICON_SET.checkBoxPostselected
                : isHovered
                ? ICON_SET.checkBoxPreselected
                : ICON_SET.checkBoxUnselected;
              return (
                <div
                  key={index}
                  onClick={() => handleOptionClick(option)}
                  onMouseOver={isMulti ? () => setHovered(option) : undefined}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 12px",
                    cursor: "pointer",
                    borderRadius: "4px",
                    backgroundColor: isSelected
                      ? colors.neutral[200]
                      : colors.background.white,
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = colors.neutral[200])
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = colors.background.white)
                  }
                >
                  {isMulti && (
                    <Icon name={iconName} style={{ marginRight: 4 }} />
                  )}
                  <Text typographyStyle={typography.body.regular}>
                    {option.label}
                  </Text>
                </div>
              );
            })}
          </DropdownContent>
        </DropdownWrapper>
      )}
    </SelectBoxWrapper>
  );
};

SelectBox.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  width: PropTypes.string,
  // value can be a single object or array of objects/strings in multi mode
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    PropTypes.array,
  ]),
  // NEW PROP: optional array of strings. If it includes "multi", use multi-select
  settings: PropTypes.arrayOf(PropTypes.string),
};

export default SelectBox;