import React, { useState } from "react";
import styled from "styled-components";
import Modal from "new_components/molecules/Modal";
import TextInput from "new_components/molecules/TextInput";
import Button from "new_components/atoms/Button";
import Text from "new_components/atoms/Text";

function DeleteModal({ isOpen, onClose, onConfirm, itemName, textOverride }) {
  const [inputValue, setInputValue] = useState("");
  const isDeleteConfirmed = inputValue.trim().toLowerCase() === "delete";

  const handleConfirm = () => {
    if (isDeleteConfirmed) {
      onConfirm();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      modalHeading="Confirm Deletion"
      modalHeight="280px"
      modalBody={
        <div>
          <TextWrapper>
            {!textOverride ? (
              <Text>
                Are you sure you want to delete <strong>{itemName}</strong>?
                This action cannot be undone.
              </Text>
            ) : (
              <Text>{textOverride}</Text>
            )}
          </TextWrapper>
          <TextInput
            label="Type 'delete' to confirm"
            placeholder="delete"
            value={inputValue}
            onChange={setInputValue}
          />
        </div>
      }
      modalFooter={
        <FooterContainer>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirm}
            disabled={!isDeleteConfirmed}
          >
            Delete
          </Button>
        </FooterContainer>
      }
    />
  );
}

const TextWrapper = styled.div`
  margin-bottom: 16px;
`;

const FooterContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: right;
  width: 100%;
`;

export default DeleteModal;
