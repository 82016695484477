import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  color: inherit; // Inherit color from parent for flexibility
  transform: ${({ rotate }) => (rotate ? `rotate(${rotate}deg)` : "none")};
  transition: transform 0.2s ease-in-out; // Smooth rotation transition

  cursor: ${({ showPointer }) =>
    showPointer
      ? "pointer"
      : "default"}; // Ensure pointer cursor when onClick exists

  svg {
    width: 100%;
    height: 100%;
    cursor: inherit; // Inherit cursor from parent
  }
`;

// Dynamically load icons
export const loadIcon = async (name) => {
  try {
    const module = await import(`./iconset/${name}`);
    return module.default || module;
  } catch (error) {
    console.error(`Icon "${name}" could not be loaded:`, error);
    return null;
  }
};

const Icon = ({ name, size = 24, color, rotate, className, ...props }) => {
  const [SvgIcon, setSvgIcon] = React.useState(null);
  React.useEffect(() => {
    let isMounted = true;
    const fetchIcon = async () => {
      const module = await import(`./iconset/${name}`);
      const icon = module.default || module;
      if (isMounted) setSvgIcon(() => icon);
    };

    fetchIcon();
    return () => {
      isMounted = false;
    };
  }, [name]);

  if (!SvgIcon) {
    console.warn(`Icon "${name}" is not available.`);
    return null;
  }

  return (
    <StyledIcon
      {...props}
      size={size}
      color={color}
      rotate={rotate}
      className={className}
      showPointer={props.onClick}
    >
      <SvgIcon width={size} height={size} {...props} color={color} />
    </StyledIcon>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  rotate: PropTypes.number,
  className: PropTypes.string,
};

export default Icon;
