import { call, put, takeLatest } from "redux-saga/effects";
import * as api from "../constants/api";
import {
  SG_GET_SLACK_USER_LIST,
  GET_SLACK_USER_LIST,
  SG_GET_SLACK_CHANNEL_LIST,
  GET_SLACK_CHANNEL_LIST,
  PENDING_SLACK_CALL,
  CLEAR_SLACK_CALL,
  SG_LINK_SLACK_ID_TO_EMPLOYEE_ID,
  LINK_SLACK_ID_TO_EMPLOYEE_ID,
  SG_DELETE_SLACK_ID_TO_EMPLOYEE_ID,
  DELETE_SLACK_ID_TO_EMPLOYEE_ID,
  SG_UPDATE_SLACK_CHANNEL_LIST,
  UPDATE_SLACK_CHANNEL_LIST,
} from "../constants/actions";

function* fetchSlackUserList(action) {
  try {
    yield put({ type: PENDING_SLACK_CALL });
    const response = yield call(api.GET_SLACK_USER_LIST, action.payload);
    yield put({ type: GET_SLACK_USER_LIST, payload: response.data });
  } catch (error) {
    yield put({ type: CLEAR_SLACK_CALL });
  }
}


function* fetchSlackChannelList(action) {
  try {
    yield put({ type: PENDING_SLACK_CALL });
    const response = yield call(api.GET_SLACK_CHANNEL_LIST, action.payload);
    yield put({ type: GET_SLACK_CHANNEL_LIST, payload: response.data });
  } catch (error) {
    yield put({ type: CLEAR_SLACK_CALL });
  }
}

function* linkSlackIdToEmployeeId(action) {
  try {
    yield put({ type: PENDING_SLACK_CALL });
    const response = yield call(api.LINK_SLACK_ID_TO_EMPLOYEE_ID, action.payload);
    yield put({ type: LINK_SLACK_ID_TO_EMPLOYEE_ID, payload: response.data });
  } catch (error) {
    yield put({ type: CLEAR_SLACK_CALL });
  }
}

function* deleteSlackIdToEmployeeId(action) {
  try {
    yield put({ type: PENDING_SLACK_CALL });
    const response = yield call(api.DELETE_SLACK_ID_TO_EMPLOYEE_ID, action.payload);
    yield put({ type: DELETE_SLACK_ID_TO_EMPLOYEE_ID, payload: response.data });
  } catch (error) {
    yield put({ type: CLEAR_SLACK_CALL });
  }
}

function* updateSlackChannelList(action) {
  try {
    yield put({ type: PENDING_SLACK_CALL });
    const response = yield call(api.UPDATE_SLACK_CHANNEL_LIST, action.payload);
    yield put({ type: UPDATE_SLACK_CHANNEL_LIST, payload: response.data });
  } catch (error) {
    yield put({ type: CLEAR_SLACK_CALL });
  }
}

export default function* slackSaga() {
  yield takeLatest(SG_GET_SLACK_USER_LIST, fetchSlackUserList);
  yield takeLatest(SG_GET_SLACK_CHANNEL_LIST, fetchSlackChannelList);
  yield takeLatest(SG_LINK_SLACK_ID_TO_EMPLOYEE_ID, linkSlackIdToEmployeeId);
  yield takeLatest(SG_DELETE_SLACK_ID_TO_EMPLOYEE_ID, deleteSlackIdToEmployeeId);
  yield takeLatest(SG_UPDATE_SLACK_CHANNEL_LIST, updateSlackChannelList);
}