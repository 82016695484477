import React, { useState, useEffect } from 'react';
import styled,{keyframes} from 'styled-components';
import { Loader } from 'semantic-ui-react';

const AICard = ({ start, alreadyRun }) => {
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    // If we have not already run, then trigger a new generation
    if (!alreadyRun) {
      const t = setTimeout(() => {
        start();
        setFinished(true);
      }, 3000);

      return () => clearTimeout(t);
    } else {
      // If already run, skip new generation and just appear "finished"
      setFinished(true);
    }
  }, [alreadyRun, start]);

  return (
    <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center',width:'100%',marginTop:20}}>
      <Card>
        {!finished ? (
          <>
            <Title>Building model & running analysis</Title>
            <Loader active inline size="mini" style={{marginLeft:20}} />
          </>
        ) : (
          <Title>Completed Analysis</Title>
        )}
      </Card>
    </div>
  );
};

export default AICard;




const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;


const Card = styled.div`
    padding: 20px;
    border-radius: 10px;
    background-color: #f5f5f5;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
        opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
`

const Title = styled.div`
    font-size: 14px;
    font-weight: 600;
    font-family: 'Raleway', sans-serif;

 opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
`