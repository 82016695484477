import React, { useMemo, useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { CSVLink } from "react-csv";


const ExportCsvButton = () => {
  const [organizationData, setOrganizationData] = useState(null);
  const [outcomeQ, setOutcomeQ] = useState(null);


  const get_auth = useSelector((state) => state.auth);
  const raw_data = useSelector(
    (state) => state.audit?.raw_data,
    shallowEqual
  );

  const core_data = useSelector((state) => state.audit?.core_data);
  const get_organizations = useSelector((state) => state.organizations);
  const get_survey_questions = useSelector(
    (state) => state.surveyquestions
  );

  useEffect(() => {
    if (get_organizations) {
      setOrganizationData(
        get_organizations[
          get_auth?.organization_id || get_organizations?.organization?.id
        ]
      );
    }
  }, [get_organizations]);

  useEffect(() => {
    if (organizationData) {
      const sortOrder = organizationData?.styling?.survey_sequence?.find(
        (item) => item.value === "outcome_question"
      )?.question?.sort_order;

      if (sortOrder) {
        const outcomeQ = get_survey_questions?.outcome_questions.find(
          (item) => item.sort_order === sortOrder
        );
        setOutcomeQ(outcomeQ);
      }
    }
  }, [organizationData, get_survey_questions]);

  const questionColumns = useMemo(() => {
    if (!core_data) return {};
    const columns = {};
    core_data?.questions?.dimensions?.forEach((dimension) => {
      dimension?.factors?.forEach((factor) => {
        factor?.questions?.forEach((question) => {
          if (question?.id && question?.q) {
            columns[question.id] = question.q;
          }
        });
      });
    });
    return columns;
  }, [core_data]);

  const binaryData = useMemo(() => {
    if (!core_data) return {};
    const columns = {};
    core_data?.questions?.dimensions?.forEach((dimension) => {
      dimension?.factors?.forEach((factor) => {
        factor?.questions?.forEach((question) => {
          if (question?.id && question?.q) {
            columns[question.id] = question?.binary;
          }
        });
      });
    });
    return columns;
  }, [core_data]);

  const lookUpCategory = (cat) => {
    if (!core_data) return "";
    if (cat.priority === "primary") {
      const categoryOptions = core_data.categories[0].categories?.find(
        (item) => item.id === cat.id
      )?.options;

      if ("subfactors" in categoryOptions[0]) {
        const subfactorOptions = categoryOptions?.find(
          (item) => item.id === cat.response
        )?.subfactors;
        return (
          subfactorOptions?.find((item) => item.id === cat.subfactor)?.name || ""
        );
      }
      return (
        categoryOptions?.find((item) => item.id === cat.response)?.name || ""
      );
    }

    if (!cat.response) {
      return "";
    }

    const categoryOptions = core_data.categories[0].categories?.find(
      (item) => item.id === cat.id
    )?.options;
    return (
      categoryOptions?.find((item) => item.id === cat.response)?.name || ""
    );
  };

  function sanitizeForCSV(value) {
    if (typeof value === "string") {
      // Convert any existing " into "" (this is the standard CSV escape)
      // Optionally you could also handle newlines or other characters if needed
      return value.replace(/"/g, '""');
    }
    return value;
  }

  const hashString = async (str) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(str);
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
  };

  const csvData = useMemo(() => {
    if (!raw_data || !core_data) return [];
    
    const categoryOrder = core_data.categories?.[0]?.categories || [];
    
    return raw_data[0].responses.map((item) => {
      // Build categories in a stable order based on core_data categories
      const categories = {};
      categoryOrder.forEach((coreCat, index) => {
        const matchedCat = item.categories.find((c) => c.id === coreCat.id);
        categories[`Category ${index + 1}`] = matchedCat ? lookUpCategory(matchedCat) : "";
      });

      const scores = {};
      // let isChampion = false;
      // item.questions.forEach((q) => {
      //   const questionText = questionColumns[q.ques_order];
      //   if (questionText) {
      //     scores[questionText] = q.response;
      //     if (binaryData[q.ques_order]) {
      //       isChampion = true;
      //     }
      //   }
      // });

      let isChampion = false;
      Object.keys(questionColumns).forEach((key) => {
        const questionText = questionColumns[key];
        scores[questionText] =item.questions.find((q) => q.ques_order === parseInt(key)).response
      });
        

      if (isChampion) {
        console.log("Champion", item.employee);
      }

      const outcomes = {};
      if (item.employee_outcomes) {
        outcomeQ?.questions?.forEach((q) => {
          q?.questions?.forEach((question) => {
              const outcome = item.employee_outcomes?.responses.find((r) => r.s === question.s && r.q === question.q);
              outcomes[question.question] = outcome?.response
          
      } );
      } );
      }

      const comments = {};
      if(item.comments){
        item.comments.forEach((comment,i) => {
          const comment_q = core_data.questions.comments.find((q,idx) => idx == i)?.question;
          comments[comment_q] = sanitizeForCSV(comment.feedback)
        });
      }



      const employee_id = item.employee;
      const categories_in_org = core_data.orgCategories.find((f) => f.employee == employee_id);

      if (isChampion) {
        console.log("Champion", item.employee, categories_in_org);
      }

      console.log(comments)


      return {
        ...categories,
        ...scores,
        ...outcomes,
        ...comments,
      };
    });
  }, [raw_data, questionColumns, core_data, outcomeQ, binaryData]);

  const headers = useMemo(() => {
    if (csvData.length === 0) return [];
    const allKeys = Object.keys(csvData[0]);
    return allKeys.map((key) => ({
      label: key,
      key: key,
    }));
  }, [csvData]);

  if (
    !core_data ||
    !raw_data ||
    core_data?.permissions?.account_type !== 5
  ) {
    return <div>Loading data...</div>;
  }

  return (
    <div style={{ marginTop: 30 }}>
      <CSVLink
        data={csvData}
        headers={headers}
        filename="raw_scores.csv"
        separator=","
        enclosingCharacter={'"'}
      >
        <button>Export CSV</button>
      </CSVLink>
    </div>
  );
};

export default ExportCsvButton;
