import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CoachingSetup from './CoachingSetup';

export default function App() {
  const [tasks, setTasks] = useState([
    {
      id: 1,
      type: 'coaching',
      focusArea: 'leadership',
      schedule: { frequency: 'daily', time: '08:30' },
      progress: '3/10 sessions',
      participants: ['Alice', 'Bob'],
      goal: 'Improve leadership communication',
    },
  ]);

  const [showSetup, setShowSetup] = useState(false);
  const [editingTask, setEditingTask] = useState(null);

  const handleCreateNew = () => {
    setEditingTask(null);
    setShowSetup(true);
  };

  const handleEditTask = (task) => {
    setEditingTask(task);
    setShowSetup(true);
  };

  const handleDeleteTask = (id) => {
    setTasks((prev) => prev.filter((t) => t.id !== id));
  };

  const handleSaveTask = (updatedTask) => {
    setTasks((prev) => {
      const existingIndex = prev.findIndex((t) => t.id === updatedTask.id);
      if (existingIndex >= 0) {
        return prev.map((t, idx) => (idx === existingIndex ? updatedTask : t));
      }
      return [...prev, updatedTask];
    });
    setShowSetup(false);
  };

  return (
    <MainContainer>
      {!showSetup ? (
        <Dashboard
          tasks={tasks}
          onCreateNew={handleCreateNew}
          onEditTask={handleEditTask}
          onDeleteTask={handleDeleteTask}
        />
      ) : (
        <CoachingSetup
          editingTask={editingTask}
          onBack={() => setShowSetup(false)}
          onSave={handleSaveTask}
        />
      )}
    </MainContainer>
  );
}

function Dashboard({ tasks, onCreateNew, onEditTask, onDeleteTask }) {
  return (
    <>
      <TitleLarge>Coaching Automations</TitleLarge>
      <DescriptionText>
        Below is a list of all your current coaching automations. You can edit or delete existing ones or create a new coaching plan.
      </DescriptionText>

      <ActionBar>
        <PrimaryButton onClick={onCreateNew}>+ New Coaching Plan</PrimaryButton>
      </ActionBar>

      <CardContainer>
        {tasks.map((task) => (
          <TaskCard key={task.id} onClick={() => onEditTask(task)}>
            <CardTitle>Coaching Plan</CardTitle>
            <CardDescription>
              <strong>Focus Area:</strong> {task.focusArea}
              <br />
              <strong>Goal:</strong> {task.goal}
              <br />
              <strong>Frequency:</strong> {task.schedule.frequency}
              <br />
              <strong>Time:</strong> {task.schedule.time}
              <br />
              <strong>Participants:</strong> {task.participants.join(', ')}
              <br />
              <strong>Progress:</strong> {task.progress}
            </CardDescription>

            <ActionBar>
              <DeleteButton
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteTask(task.id);
                }}
              >
                Delete
              </DeleteButton>
            </ActionBar>
          </TaskCard>
        ))}
      </CardContainer>
    </>
  );
}

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Raleway", sans-serif;
  margin-top: 20px;
  padding: 20px;
`;

const TitleLarge = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #2A3039;
`;

const DescriptionText = styled.div`
  margin-bottom: 20px;
  max-width: 700px;
  color: #706C85;
  font-size: 15px;
  line-height: 1.4;
`;

const ActionBar = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

const CardContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const TaskCard = styled.div`
  width: calc(33% - 20px);
  background-color: #F8FAFF;
  border-radius: 5px;
  padding: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  box-sizing: border-box;
`;

const CardTitle = styled.div`
  color: #2A3039;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const CardDescription = styled.div`
  color: #706C85;
  font-size: 13px;
  margin-bottom: 10px;
  line-height: 1.4;
`;

const PrimaryButton = styled.button`
  padding: 10px 20px;
  background-color: #2D70E2;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  font-family: 'Raleway', sans-serif;
  &:hover {
    background-color: #1c4db8;
  }
`;

const DeleteButton = styled.button`
  color: #E01E5A;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  background: transparent;
  border: none;
  &:hover {
    text-decoration: underline;
  }
`;
