import React, { createContext, useState, useEffect, useContext } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { SET_NEW_FILTERS, SET_FILTERED_DATA } from "constants/actions";
import { filter_data } from "reports/Tag/calculations";

export const FilterContext = createContext();

export const useFilterContext = () => useContext(FilterContext);
// Function to generate modalData from the current filter structure
const parseFilterData = (categories) => {
  if (!categories || categories.length === 0) {
    return null;
  }

  const items = categories.map((category) => ({
    id: category.id,
    categoryTitle: category.name,
    categoryContent: (
      <div>
        {category.options.map((option) => (
          <div key={option.id}>
            <h4>{option.name}</h4>
            {option.subfactors?.map((sub) => (
              <div key={sub.id}>
                <h5>{sub.name}</h5>
              </div>
            ))}
          </div>
        ))}
      </div>
    ),
    serializableData: {
      id: category.id,
      name: category.name,
      options: category.options.map((option) => ({
        id: option.id,
        name: option.name,
        subfactors: option.subfactors?.map((sub) => ({
          id: sub.id,
          name: sub.name,
        })),
      })),
    }, // Add only serializable data
  }));

  return {
    groupTitle: "Filter Categories", // Customize as needed
    items: items,
  };
};

export const FilterProvider = ({ children }) => {
  const dispatch = useDispatch();

  const [selectedSubfactor, setSelectedSubfactor] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [modalData, setModalData] = useState(null);

  const { get_survey_structure, core_data, selectedFilters } = useSelector(
    (state) => ({
      get_survey_structure: state.surveystructure.survey_structure,
      core_data: state.audit?.core_data,
      selectedFilters: state.audit?.new_filters,
    }),
    shallowEqual
  );

  useEffect(() => {
    let structure =
      core_data?.categories?.[0]?.categories?.length > 0
        ? core_data?.categories
        : get_survey_structure;

    if (!Array.isArray(structure)) {
      structure = [structure];
    }

    setSelectedCategory(
      structure[0]?.categories?.sort((a, b) => {
        const priorities = { primary: 1, secondary: 2, tertiary: 3 };

        if (a.priority === "tertiary" && a.demographic) return 1;
        if (b.priority === "tertiary" && b.demographic) return -1;

        return priorities[a.priority] - priorities[b.priority];
      })[0]
    );
  }, [get_survey_structure, core_data]);

  useEffect(() => {
    let structure =
      core_data?.categories?.[0]?.categories?.length > 0
        ? core_data?.categories[0]?.categories
        : get_survey_structure?.[0]?.categories;

    if (structure) {
      const modalDataStructure = parseFilterData(structure);
      setModalData(modalDataStructure);
    }
  }, [core_data, get_survey_structure]);

  // ...
  useEffect(() => {
    // MOVE THIS TO FEEDBACKCONTEXT
    // if (selectedFilters.length > 0) {
    //   let _tags = selectedFilters.map((item) => item.name);
    //   setTags(_tags);
    // } else {
    //   setTags([]);
    // }

    // if we have reportData, dispatch new filtered data
    if (core_data?.reportData && core_data?.reportData.length > 0) {
      const filtered = filter_data(
        core_data?.reportData[0]?.responses,
        selectedFilters
      );

      dispatch({
        type: SET_FILTERED_DATA,
        payload: filtered,
      });
    }
  }, [selectedFilters, core_data?.reportData]);

  const handleSelect = (item, parent) => {
    item.parent = parent?.id || selectedSubfactor?.id;

    const exists = selectedFilters.some(
      (filter) => filter.id === item.id && filter.parent === item.parent
    );

    const updatedFilters = exists
      ? selectedFilters.filter(
          (filter) => !(filter.id === item.id && filter.parent === item.parent)
        )
      : [...selectedFilters, { ...item, isSubfactor: !!parent?.subfactors }];

    // Sync state with Redux
    dispatch({
      type: SET_NEW_FILTERS,
      payload: updatedFilters,
    });
  };

  const clearAllFilters = () => {
    dispatch({
      type: SET_NEW_FILTERS,
      payload: [],
    });
  };

  return (
    <FilterContext.Provider
      value={{
        selectedFilters,
        selectedCategory,
        selectedSubfactor,
        setSelectedCategory,
        setSelectedSubfactor,
        handleSelect,
        clearAllFilters,
        modalData,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
