import React, { useState, useEffect } from "react";
import {
  Card,
  Heading,
  Paragraph,
  Button,
  Spacer,
  Table,
  SyncIcon,
} from "ProtoType/Slack/styles/Common";
import styled from "styled-components";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  SG_GET_ORG_SLACK,
  SG_GET_SLACK_CHANNEL_LIST,
  SG_UPDATE_SLACK_CHANNEL_LIST,
  SG_EDIT_ORG_SLACK,
} from "constants/actions";

const ChannelToggle = styled.input.attrs({ type: "checkbox" })``;

const SlackAppSettings = () => {
  const dispatch = useDispatch();
  const [useChannels, setUseChannels] = useState(false);
  const [channels, setChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [enabledChannels, setEnabledChannels] = useState(null);
  const [toggledChannels, setToggledChannels] = useState({});


  useEffect(() => {
    handleSync();
  }, []);

  const { get_employee, get_employee_slack, get_slack_calls, get_org_slack } =
    useSelector(
      (state) => ({
        get_errors: state.errors,
        get_employee: state.employees,
        get_employee_slack: state.employee_slack,
        get_slack_calls: state.slack_calls,
        get_org_slack: state.org_slack,
      }),
      shallowEqual
    );

  // slackChannels: [],
  // Fetch channel list when useChannels is turned on

  useEffect(() => {
    if (get_org_slack?.org_slack?.[0]?.enabled_channels) {
      setEnabledChannels(get_org_slack?.org_slack?.[0]?.enabled_channels);
      setToggledChannels(get_org_slack?.org_slack?.[0]?.enabled_channels);
    }
    if (get_org_slack?.updated) { 
      const timer = setTimeout(() => {
        dispatch({
          type: SG_GET_ORG_SLACK,
        });
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [get_org_slack, dispatch]);


  useEffect(() => {
    setChannels(get_slack_calls?.slackChannels?.channels || []);
  }, [get_slack_calls?.slackChannels]);

  const handleChannelToggle = (channel) => {
    setSelectedChannels((prev) => {
      const newSelectedChannels = { ...prev };
      const {
        id: channelId,
        name: channelName,
        num_members: members,
        purpose: description,
      } = channel;
      if (newSelectedChannels[channelId]) {
        delete newSelectedChannels[channelId];
      } else {
        newSelectedChannels[channelId] = {
          name: channelName,
          members: members,
          description: description.value,
          report_info: {
            last_report_date: null,
            next_report_date: null,
            frequency: null,
          },
        };
      }
      return newSelectedChannels;
    });

    setToggledChannels((prev) => {
      const newToggledChannels = { ...prev };
      if (newToggledChannels[channel.id]) {
        delete newToggledChannels[channel.id];
      } else {
        newToggledChannels[channel.id] = channel;
      }
      return newToggledChannels;
    });
  };

  function addChanelToOrganization() {
    dispatch({
      type: SG_EDIT_ORG_SLACK,
      payload: {
        id: get_org_slack?.org_slack?.[0]?.id,
        enabled_channels: selectedChannels,
      },
    });
  }

  function handleSync() {
    dispatch({
      type: SG_GET_ORG_SLACK,
    });
  }

  const fetchSlackChannelList = () => {
    dispatch({
      type: SG_GET_SLACK_CHANNEL_LIST,
    });
    setUseChannels(!useChannels);
  };

  return (
    <Card>
      <Heading>
        Data Source Settings{" "}
        <SyncIcon onClick={handleSync} title="Start Sync Process">
          🔄 Refresh
        </SyncIcon>
      </Heading>
      <Paragraph>Current Slack channels used as data sources.</Paragraph>
      <Table>
        <thead>
          <tr>
            <th>Channel</th>
            <th>Description</th>
            <th>Last Report</th>
          </tr>
        </thead>
        <tbody>
          {enabledChannels &&
            Object.entries(enabledChannels).map(([channelId, channel]) => (
              <tr key={channelId}>
                <td>{channel?.name}</td>
                <td>{channel?.description}</td>
                <td>{channel?.report_info?.last_report_date || "N/A"}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Spacer height="20px" />
      <Paragraph>
        Include Slack channels as data sources for your analytics.
      </Paragraph>
      <Spacer height="20px" />
      <ToggleContainer>
        <Switch>
          <input
            type="checkbox"
            checked={useChannels}
            onChange={fetchSlackChannelList}
          />
          <span></span>
        </Switch>
        <Label>Use Slack channels as data source</Label>
      </ToggleContainer>

      {useChannels && (
        <>
          <Paragraph>Select which channels to use as data sources:</Paragraph>
          <Table>
            <thead>
              <tr>
                <th>Channel</th>
                <th>Use as Data Source</th>
              </tr>
            </thead>
            <tbody>
              {channels &&
                channels.map((channel) => (
                  <tr key={channel.id}>
                    <td>{channel.name}</td>
                    <td>
                      <ChannelToggle
                        checked={toggledChannels[channel.id] ? true : false}
                        onChange={() => handleChannelToggle(channel)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          <Button
            style={{ backgroundColor: "blue", color: "white" }}
            onClick={() => addChanelToOrganization()}
          >
            Connect
          </Button>
        </>
      )}
    </Card>
  );
};

export default SlackAppSettings;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing(2)};
`;

const Label = styled.label`
  margin-left: ${(props) => props.theme.spacing(1)};
  font-size: ${(props) => props.theme.fontSizes.medium};
  color: ${(props) => props.theme.colors.text};
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 42px;
  height: 20px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }

  span:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + span {
    background-color: ${(props) => props.theme.colors.primary};
  }

  input:checked + span:before {
    transform: translateX(20px);
  }
`;
