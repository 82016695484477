import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

export default function CoachingSetup({ editingTask, onBack, onSave }) {
  const [formData, setFormData] = useState(() =>
    editingTask || {
      id: null,
      type: 'coaching',
      focusArea: 'communication',
      schedule: { frequency: 'daily', time: '08:30', daysOfWeek: [] },
      progress: '0/10 sessions',
      participants: [],
      goal: '',
    }
  );

  useEffect(() => {
    if (editingTask) setFormData(editingTask);
  }, [editingTask]);

  const handleSave = () => {
    const updatedTask = { ...formData, id: formData.id ?? Date.now() };
    onSave(updatedTask);
  };

  const allPeople = ['Alice', 'Bob', 'Charlie', 'Dana'];
  const allDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  return (
    <CardNoHover>
      {/* 1. Participants (multi-select) */}
      <SectionLabel>1. Participants</SectionLabel>
      <ParticipantsRow>
        {allPeople.map((person) => {
          const selected = formData.participants.includes(person);
          return (
            <PillButton
              key={person}
              active={selected}
              onClick={() => {
                const next = selected
                  ? formData.participants.filter((p) => p !== person)
                  : [...formData.participants, person];
                setFormData({ ...formData, participants: next });
              }}
            >
              {person}
            </PillButton>
          );
        })}
      </ParticipantsRow>

      {/* 2. Focus Area */}
      <SectionLabel>2. Focus Area</SectionLabel>
      <Select
        value={formData.focusArea}
        onChange={(e) => setFormData({ ...formData, focusArea: e.target.value })}
      >
        <option value="communication">Communication</option>
        <option value="leadership">Leadership</option>
        <option value="teamwork">Teamwork</option>
        <option value="adaptability">Adaptability</option>
      </Select>

      {/* 3. Schedule */}
      <SectionLabel>3. Schedule</SectionLabel>
      <ScheduleGrid>
        <div>
          <SectionLabel>Frequency</SectionLabel>
          <Select
            value={formData.schedule.frequency}
            onChange={(e) => {
              const frequency = e.target.value;
              setFormData({
                ...formData,
                schedule: { ...formData.schedule, frequency, daysOfWeek: frequency === 'daily' ? [] : formData.schedule.daysOfWeek },
              });
            }}
          >
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="biweekly">Bi-Weekly</option>
          </Select>
        </div>
        <div>
          <SectionLabel>Time</SectionLabel>
          <Input
            type="time"
            value={formData.schedule.time}
            onChange={(e) =>
              setFormData({
                ...formData,
                schedule: { ...formData.schedule, time: e.target.value },
              })
            }
          />
        </div>
      </ScheduleGrid>
      {['weekly', 'biweekly'].includes(formData.schedule.frequency) && (
        <>
          <SectionLabel>Days of the Week</SectionLabel>
          <ParticipantsRow>
            {allDays.map((day) => {
              const selected = formData.schedule.daysOfWeek.includes(day);
              return (
                <PillButton
                  key={day}
                  active={selected}
                  onClick={() => {
                    const next = selected
                      ? formData.schedule.daysOfWeek.filter((d) => d !== day)
                      : [...formData.schedule.daysOfWeek, day];
                    setFormData({
                      ...formData,
                      schedule: { ...formData.schedule, daysOfWeek: next },
                    });
                  }}
                >
                  {day}
                </PillButton>
              );
            })}
          </ParticipantsRow>
        </>
      )}
      
      {/* 4. Goal/Context */}
      <SectionLabel>4. Goal / Context</SectionLabel>
      <Input
        value={formData.goal}
        onChange={(e) => setFormData({ ...formData, goal: e.target.value })}
      />

      <ButtonRow>
        <PrimaryButton onClick={handleSave}>
          {formData.id ? 'Update Coaching' : 'Activate Coaching'}
        </PrimaryButton>
        <SecondaryButton onClick={onBack}>Cancel</SecondaryButton>
      </ButtonRow>
    </CardNoHover>
  );
}


// ============ Styled Components ============
const CardNoHover = styled.div`
  background-color: #f8faff;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  font-family: "Raleway", sans-serif;
`;

const SectionLabel = styled.div`
  font-size: 16px;
  color: #2a3039;
  font-weight: 600;
  margin: 20px 0 10px;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const ScheduleGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const ParticipantsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

const PillButton = styled.button`
  padding: 8px 12px;
  border: 1px solid ${({ active }) => (active ? '#2d70e2' : '#ddd')};
  border-radius: 20px;
  background: ${({ active }) => (active ? '#eef5ff' : 'white')};
  color: ${({ active }) => (active ? '#2d70e2' : '#2a3039')};
  cursor: pointer;
  font-size: 13px;
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;

const PrimaryButton = styled.button`
  padding: 10px 20px;
  background-color: #2d70e2;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #1c4db8;
  }
`;

const SecondaryButton = styled(PrimaryButton)`
  background-color: #ccc;
  color: #2a3039;
  &:hover {
    background-color: #bbb;
  }
`;
