import React, { useState, useEffect, useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import { CopilotContext } from '../CopilotContext';

/**
 * ThematicCard
 *
 * 1) If `alreadyRun === true`, skip the entire run (display "Completed Thematic Analysis").
 * 2) Otherwise:
 *    a) Generate embeddings
 *    b) Find best #clusters
 *    c) K-means or other cluster logic
 *    d) Generate cluster themes via LLM
 *    e) Append an "assistant" message (or similar) with the final JSON
 *    f) Call `onDone(finalMessages)` so the parent can save everything
 */

function extractAndParseJSON(llmResponse) {
    let cleanedResponse = llmResponse.trim();
    let finalResult;
    let lastError = null;
    
    // Regex to match JSON inside markdown-style code blocks
    const jsonRegex = /```json\s*([\s\S]*?)\s*```/;
    let match = cleanedResponse.match(jsonRegex);

    if (match) {
        cleanedResponse = match[1]; // Extract only the JSON part
    }

    // Try parsing JSON normally
    try {
        finalResult = JSON.parse(cleanedResponse);
        return { success: true, data: finalResult };
    } catch (parseErr) {
        lastError = parseErr;
        console.error("JSON parse failed (direct):", parseErr.message);
    }

    // Fallback: Try extracting JSON manually from within the response
    try {
        let jsonMatch = cleanedResponse.match(/\{[\s\S]*\}/);
        if (jsonMatch) {
            finalResult = JSON.parse(jsonMatch[0]); // Try parsing the extracted JSON object
            return { success: true, data: finalResult };
        }
    } catch (parseErr) {
        lastError = parseErr;
        console.error("JSON parse failed (fallback extraction):", parseErr.message);
    }

    return { success: false, error: lastError ? lastError.message : "Invalid JSON format" };
}

const ThematicCard = ({
  data,
  getBatchEmbeddings,
  alreadyRun = false,
  onDone
}) => {
  const [step, setStep] = useState(0);
  const [finished, setFinished] = useState(false);

  const { callOpenAI, setMessages } = useContext(CopilotContext);

  const steps = [
    'Generating thematic embeddings',
    'Determining optimal number of clusters',
    'Running cluster analysis',
    'Identifying outliers',
    'Generating themes'
  ];

  /**
   * MAIN useEffect:
   * - If `alreadyRun` is true, we set finished right away, skipping the entire process.
   * - Otherwise, run `processSteps()`.
   */
  useEffect(() => {
    if (alreadyRun) {
      // If we already have results from a saved session, skip re-running
      setFinished(true);
      return;
    }

    // If not already run, do the entire process
    if (!finished) {
      processSteps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alreadyRun]);

  /**
   * Steps timing effect, only runs if not finished & not already run.
   */
  useEffect(() => {
    if (!alreadyRun && !finished && step < steps.length - 1) {
      const timer = setTimeout(() => {
        setStep((prevStep) => prevStep + 1);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [step, finished, steps.length, alreadyRun]);

  /**
   * processSteps - runs the actual cluster logic and calls LLM
   */
  async function processSteps() {
    try {
      const clusterThemes = await generateClusterThemes();
      setFinished(true);
  
      // We'll capture the updated array in a local variable
      let updatedMessages = [];
      setMessages((prev) => {
        updatedMessages = [
          ...prev,
          { role: 'assistant', content: clusterThemes }
        ];
        return updatedMessages;
      });
  
      // Now that we have updatedMessages, pass it to onDone
      onDone?.(updatedMessages);
    } catch (error) {
      console.error('Error in processing steps:', error);
      setFinished(true);
    }
  }

  /**
   * generateClusterThemes - example LLM call with retry for valid JSON
   */
  async function generateClusterThemes() {
    let attempts = 0;
    const maxAttempts = 3;
    let lastError = null;
    let finalResult = null;

    while (attempts < maxAttempts) {
      attempts++;

      const promptIntro = attempts === 1
        ? ''
        : 'Your previous response was not valid JSON. Please correct it.\n';

        const prompt = `
        ${promptIntro}
        You are given feedback from users. 
        Generate between 2 and 6 themes that best fit the overall feedback. 
        Disregard outlier comments and comments that lack context.
  
        1) Provide a concise "theme" or "title" for the cluster. 
        2) Provide a short description summarizing the feedback (up to 4 sentences).
        3) Provide an array of quotes from the original feedback (up to 5 quotes, the more the better).
        4) Provide the count of how many feedback items relate to this theme.
        5) Provide an appropriate emoji that represents the theme.
  
        Feedback:
        ${data.join('\n')}
  
        ### END OF FEEDBACK
  
        Use the following JSON format exactly (no extra keys outside this array):
        [
          {
            "emoji": "string",
            "theme": "string",
            "description": "string",
            "quotes": ["string", "string"],
            "count": number
          }
        ]`
      ;

      let llmResponse;
      try {
        llmResponse = await callOpenAI(prompt);
      } catch (err) {
        lastError = err;
        console.error(`LLM call failed on attempt #${attempts}:`, err);
        continue;
      }

      // Try parse
      try {
        finalResult = extractAndParseJSON(llmResponse);
        break; // success
      } catch (parseErr) {
        lastError = parseErr;
        console.error(`JSON parse failed #${attempts}:`, parseErr.message);
      }
    }

    console.log('Final result:', finalResult);

    if (!finalResult) {
      throw new Error(
        `Failed to generate valid JSON after ${maxAttempts} attempts. Last error: ${lastError}`
      );
    }

    if(!finalResult.success) {
        throw new Error(`Failed to generate valid JSON: ${finalResult.error}`);
        }

    return finalResult.data;
  }

  return (
    <Wrapper>
      <StyledCard>
        {!finished ? (
          <CardContent>
            <AnimatedLoadingText>{steps[step]}</AnimatedLoadingText>
            <Description>*This may take several minutes to complete</Description>
          </CardContent>
        ) : (
          <StyledTitle>Completed Thematic Analysis</StyledTitle>
        )}
      </StyledCard>
    </Wrapper>
  );
};

/* ======================== STYLED COMPONENTS ======================== */
const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

const StyledCard = styled.div``;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.h3`
  margin: 0;
  font-size: 1.2rem;
  font-weight: 400;
  color: #333;
  font-family: 'Raleway', sans-serif;
`;

const wave = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const Description = styled.div`
  font-size: 10px;
  font-weight: 300;
  color: #666;
`;

const AnimatedLoadingText = styled.h3`
  margin: 0;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
  background: linear-gradient(
    90deg,
    #999 25%,
    #555 50%,
    #999 75%
  );
  background-size: 200% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  animation: ${wave} 3s linear infinite;
`;

export default ThematicCard;
