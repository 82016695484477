import { call, put, takeEvery, all } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_TASK_TEMPLATES,
  SG_GET_TASK_TEMPLATES,
  ADD_TASK_TEMPLATE,
  SG_ADD_TASK_TEMPLATE,
  UPDATE_TASK_TEMPLATE,
  SG_UPDATE_TASK_TEMPLATE,
  DELETE_TASK_TEMPLATE,
  SG_DELETE_TASK_TEMPLATE,
  ALL_ERRORS,
  ADD_USER_TASK,
  DELETE_USER_TASK,
  SG_ADD_USER_TASK,
  SG_DELETE_USER_TASK,
  GET_TASK_TEMPLATE_SELECT,
  SG_GET_TASK_TEMPLATE_SELECT,
  SG_CREATE_TASK_SCHEDULE,
  CREATE_TASK_SCHEDULE,
  GET_TASK_SCHEDULES,
  SG_GET_TASK_SCHEDULES,
  SG_FETCH_TASK_TEMPLATE,
  FETCH_TASK_TEMPLATE,
  SG_FETCH_TASK_SCHEDULE,
  FETCH_TASK_SCHEDULE,
  GENERATE_TASK_REPORT,
  SG_GENERATE_TASK_REPORT,
  SG_GET_TASK_REPORTS,
  GET_TASK_REPORTS,
  ADD_EMPLOYEE_REPORT_ACCESS,
  SG_ADD_EMPLOYEE_REPORT_ACCESS,
  SG_REMOVE_EMPLOYEE_REPORT_ACCESS,
  REMOVE_EMPLOYEE_REPORT_ACCESS,
  ADD_EMAIL_TEMPLATE,
  SG_ADD_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
  SG_UPDATE_EMAIL_TEMPLATE,
} from "constants/actions";

function* getTaskTemplates(action) {
  try {
    const json = yield call(api.GET_TASK_TEMPLATES, action.payload);
    yield put({ type: GET_TASK_TEMPLATES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* taskTemplates() {
  yield takeEvery(SG_GET_TASK_TEMPLATES, getTaskTemplates);
}

function* createTaskTemplate(action) {
  try {
    const json = yield call(api.ADD_TASK_TEMPLATE, action.payload);
    yield put({ type: ADD_TASK_TEMPLATE, payload: json.data, newObj: action.payload });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* taskTemplateCreate() {
  yield takeEvery(SG_ADD_TASK_TEMPLATE, createTaskTemplate);
}

function* updateTaskTemplate(action) {
  try {
    const json = yield call(api.UPDATE_TASK_TEMPLATE, action.payload);
    yield put({ type: UPDATE_TASK_TEMPLATE, payload: action.payload });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* taskTemplateUpdate() {
  yield takeEvery(SG_UPDATE_TASK_TEMPLATE, updateTaskTemplate);
}

function* deleteTaskTemplate(action) {
  try {
    const json = yield call(api.DELETE_TASK_TEMPLATE, action.payload);
    yield put({ type: DELETE_TASK_TEMPLATE, payload: action.payload });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* taskTemplateDelete() {
  yield takeEvery(SG_DELETE_TASK_TEMPLATE, deleteTaskTemplate);
}

function* addUserTask(action) {
  try {
    const json = yield call(api.ADD_USER_TASK, action.payload);
    yield put({ type: "ADD_USER_TASK", payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* userTaskAdd() {
  yield takeEvery(SG_ADD_USER_TASK, addUserTask);
}

function* deleteUserTask(action) {
  try {
    const json = yield call(api.DELETE_USER_TASK, action.payload);
    yield put({ type: DELETE_USER_TASK, payload: action.payload });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* userTaskDelete() {
  yield takeEvery(SG_DELETE_USER_TASK, deleteUserTask);
}

function* getTaskTemplateSelect() {
  try {
    const json = yield call(api.GET_TASK_TEMPLATE_SELECT);
    yield put({ type: GET_TASK_TEMPLATE_SELECT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* taskTemplateSelect() {
  yield takeEvery(SG_GET_TASK_TEMPLATE_SELECT, getTaskTemplateSelect);
}

function* createTaskSchedule(action) {
  try {
    const json = yield call(api.CREATE_TASK_SCHEDULE, action.payload);
    yield put({ type: CREATE_TASK_SCHEDULE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* taskScheduleCreate() {
  yield takeEvery(SG_CREATE_TASK_SCHEDULE, createTaskSchedule);
}

function* getTaskSchedules(action) {
  try {
    const json = yield call(api.GET_TASK_SCHEDULES, action.payload);
    yield put({ type: GET_TASK_SCHEDULES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* taskSchedules() {
  yield takeEvery(SG_GET_TASK_SCHEDULES, getTaskSchedules);
}

function* fetchTaskTemplate(action) {
  try {
    const json = yield call(api.FETCH_TASK_TEMPLATE, action.payload);
    yield put({ type: FETCH_TASK_TEMPLATE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* taskTemplateFetch() {
  yield takeEvery(SG_FETCH_TASK_TEMPLATE, fetchTaskTemplate);
}

function* fetchTaskSchedule(action) {
  try {
    const json = yield call(api.GET_TASK_SCHEDULES, action.payload);
    yield put({ type: FETCH_TASK_SCHEDULE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* fetchTaskScheduleFetch() {
  yield takeEvery(SG_FETCH_TASK_SCHEDULE, fetchTaskSchedule);
}

function* generateTaskReport(action) {
  try {
    const json = yield call(api.GENERATE_TASK_REPORT, action.payload);
    yield put({ type: GENERATE_TASK_REPORT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* taskReportGenerate() {
  yield takeEvery(SG_GENERATE_TASK_REPORT, generateTaskReport);
}

function* getTaskReports(action) {
  try {
    const json = yield call(api.GET_TASK_REPORTS, action.payload);
    yield put({ type: GET_TASK_REPORTS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* taskReports() {
  yield takeEvery(SG_GET_TASK_REPORTS, getTaskReports);
}

function* addEmployeeReportAccess(action) {
  try {
    const json = yield call(api.ADD_EMPLOYEE_REPORT_ACCESS, action.payload);
    yield put({ type: ADD_EMPLOYEE_REPORT_ACCESS, payload: action.payload });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeReportAccess() {
  yield takeEvery(SG_ADD_EMPLOYEE_REPORT_ACCESS, addEmployeeReportAccess);
}

function* removeEmployeeReportAccess(action) {
  try {
    const json = yield call(api.REMOVE_EMPLOYEE_REPORT_ACCESS, action.payload);
    yield put({ type: REMOVE_EMPLOYEE_REPORT_ACCESS, payload: action.payload });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeReportAccessRemove() {
  yield takeEvery(SG_REMOVE_EMPLOYEE_REPORT_ACCESS, removeEmployeeReportAccess);
}

function* addEmailTemplate(action) {
  try {
    const json = yield call(api.ADD_EMAIL_TEMPLATE, action.payload);
    yield put({ type: ADD_EMAIL_TEMPLATE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* emailTemplateAdd() {
  yield takeEvery(SG_ADD_EMAIL_TEMPLATE, addEmailTemplate);
}

function* updateEmailTemplate(action) {
  try {
    const json = yield call(api.UPDATE_EMAIL_TEMPLATE, action.payload);
    yield put({ type: UPDATE_EMAIL_TEMPLATE, payload: action.payload });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* emailTemplateUpdate() {
  yield takeEvery(SG_UPDATE_EMAIL_TEMPLATE, updateEmailTemplate);
}

export default function* index() {
  yield all([
    taskTemplates(),
    taskTemplateCreate(),
    taskTemplateUpdate(),
    taskTemplateDelete(),
    userTaskAdd(),
    userTaskDelete(),
    taskTemplateSelect(),
    taskScheduleCreate(),
    taskSchedules(),
    taskTemplateFetch(),
    fetchTaskScheduleFetch(),
    taskReportGenerate(),
    taskReports(),
    employeeReportAccess(),
    employeeReportAccessRemove(),
    emailTemplateAdd(),
    emailTemplateUpdate(),
  ]);
}
