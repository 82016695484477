////////////////////////////////////////////////////////////
// 1. HELPER FUNCTIONS
////////////////////////////////////////////////////////////

function cleanName(name) {
    return String(name)
      .replace(/\s+/g, '_')
      .replace(/[()]/g, '')
      .replace(/&/g, 'and')
      .replace(/-/g, '_')
      .replace(/[^0-9a-zA-Z_]+/g, '_');
  }
  
  function calculateRelativeRiskAndCI(a, b, c, d, confidence = 0.95) {
    if ((a + b) === 0 || (c + d) === 0 || a === 0 || c === 0) {
      return { rr: NaN, rrLower: NaN, rrUpper: NaN };
    }
  
    const rr = (a / (a + b)) / (c / (c + d));
    const seLnRR = Math.sqrt(
      (1 / a) - (1 / (a + b)) +
      (1 / c) - (1 / (c + d))
    );
    const z = confidence === 0.95 ? 1.96 : 1.64;
    const lnRR = Math.log(rr);
    const lnRRLower = lnRR - z * seLnRR;
    const lnRRUpper = lnRR + z * seLnRR;
  
    return {
      rr,
      rrLower: Math.exp(lnRRLower),
      rrUpper: Math.exp(lnRRUpper)
    };
  }
  
  function runLikelihoodAnalysis(data, scoreColumn, categoryColumns, lowScoreThreshold) {
    const results = [];
  
    categoryColumns.forEach(catCol => {
      // Find unique values in catCol
      const uniqueValues = [...new Set(data.map(row => row[catCol]).filter(x => x != null))];
  
      // We need at least 2 distinct groups for RR
      if (uniqueValues.length < 2) {
        return;
      }
  
      const overallLowCount = data.filter(row => row[scoreColumn] <= lowScoreThreshold).length;
      const overallHighCount = data.length - overallLowCount;
  
      uniqueValues.forEach(groupVal => {
        const groupRows = data.filter(row => row[catCol] === groupVal);
        const totalCount = groupRows.length;
        const lowScoreCount = groupRows.filter(r => r[scoreColumn] <= lowScoreThreshold).length;
        const highScoreCount = totalCount - lowScoreCount;
  
        const likelihood = (totalCount > 0) ? (lowScoreCount / totalCount) : 0;
        const { rr, rrLower, rrUpper } = calculateRelativeRiskAndCI(
          lowScoreCount, highScoreCount, overallLowCount, overallHighCount, 0.95
        );
  
        results.push({
          categoryColumn: catCol,
          groupValue: groupVal,
          totalCount,
          lowScoreCount,
          highScoreCount,
          likelihood,
          relativeRisk: rr,
          rrCiLower: rrLower,
          rrCiUpper: rrUpper
        });
      });
    });
  
    return results;
  }
  
  ////////////////////////////////////////////////////////////
  // 2. BUILD CATEGORY MAP
  ////////////////////////////////////////////////////////////
  
  /**
   * Suppose you have `core_data.categories[0].categories` => array of category definitions.
   * We'll transform it into a map: categoryId -> {
   *   catName: "Age" (or "Location" etc.),
   *   options: { optionId -> optionName, ... }
   * }
   */
  function buildCategoryMap(categories) {
    const categoryMap = {};
  
    categories.forEach(catDef => {
      // For example, catDef = {
      //   id: "66b5f53e-92fe-420d-9a0a-e856b5dde279",
      //   name: "Age",
      //   options: [ {id:"xx", name:"Under 18"}, ... ],
      //   ...
      // }
      const catId = catDef.id;
      const catName = catDef.name; // e.g. "Age"
      const optionsMap = {};
  
      (catDef.options || []).forEach(opt => {
        // e.g. opt = { id: "aa05b98a-f705-4572-b9f1-9b278049ddd3", name: "Under 18" }
        optionsMap[opt.id] = opt.name;
      });
  
      categoryMap[catId] = {
        catName,
        options: optionsMap
      };
    });
  
    return categoryMap;
  }

  function computeCombinedScore(respondent, selectedFactor) {
    if (!selectedFactor) return 0; // fallback
  
    // 1) Culture approach
    if (selectedFactor.type === 'Culture') {
      const dimFactorMap = {};
  
      if (Array.isArray(respondent.questions)) {
        respondent.questions.forEach(q => {
          const dimension = q.dimension;
          const factor = q.factor;
          const val = Number(q.response);
          if (!isNaN(val)) {
            const dfKey = `dimension_${dimension}_factor_${factor}`;
            if (!dimFactorMap[dfKey]) {
              dimFactorMap[dfKey] = [];
            }
            dimFactorMap[dfKey].push(val);
          }
        });
      }
  
      // compute average per (dimension,factor)
      const scores = [];
      Object.keys(dimFactorMap).forEach(dfKey => {
        const arr = dimFactorMap[dfKey];
        const avg = arr.reduce((a, b) => a + b, 0) / arr.length;
        scores.push(avg);
      });
  
      // overall combined
      if (scores.length === 0) return 0;
      const sumScores = scores.reduce((a, b) => a + b, 0);
      return sumScores / scores.length;
    }
  
    // 2) Outcome approach
    else if (selectedFactor.type === 'outcome') {
      // We'll look at respondent.employee_outcomes.responses
      // and filter by (r.s === selectedFactor.id)
      if (!respondent.employee_outcomes || !Array.isArray(respondent.employee_outcomes.responses)) {
        return ;
      }
  
      const relevant = respondent.employee_outcomes.responses.filter(r => {
        return r.s === selectedFactor.id;
      });

      if (relevant.length === 0) return;
  
      const sum = relevant.filter(f=>f.response).reduce((acc, r) => acc + Number(r.response || 0), 0);
      return sum / relevant.length;
    }
  
    // fallback if no recognized type
    return 0;
  }
  function buildFactorAveragesForCulture(respondent) {
    // We want to aggregate scores by unique (dimension, factor) pairs
    const factorDimMap = {};
  
    (respondent.questions || []).forEach(q => {
      // Each question has: q.dimension, q.factor, and q.response
      const val = Number(q.response);
      if (!isNaN(val)) {
        // Create a unique key: dimension_factor
        const dfKey = `${q.dimension}_${q.factor}`;
        if (!factorDimMap[dfKey]) {
          factorDimMap[dfKey] = [];
        }
        factorDimMap[dfKey].push(val);
      }
    });
  
    // Now compute the average per (dimension, factor) pair
    const factorDimAverages = {};
    Object.keys(factorDimMap).forEach(dfKey => {
      const arr = factorDimMap[dfKey];
      const avg = arr.reduce((a, b) => a + b, 0) / arr.length;
      factorDimAverages[dfKey] = avg;
    });
  
    // Finally, compute the overall average across all (dimension, factor) pairs
    const factorDimValues = Object.values(factorDimAverages);
    const overall_average =
      factorDimValues.length > 0
        ? factorDimValues.reduce((a, b) => a + b, 0) / factorDimValues.length
        : 0;
  
    return {
      factorDimAverages, // object of the form { "Sales_Teamwork": 4.33, "Engineering_Communication": 3.9, ... }
      overall_average
    };
  }
  
  function buildOutcomeAverageAndFeedback(respondent, selectedFactorId) {
    // For outcomes, we rely on respondent.employee_outcomes.responses
    // to get numeric "response" and also filter relevant feedback from feedback_builder.
  
    // 1) Gather numeric responses for this outcome
    let numericResponses = [];
    if (
      respondent.employee_outcomes &&
      Array.isArray(respondent.employee_outcomes.responses)
    ) {
      numericResponses = respondent.employee_outcomes.responses
        .filter(r => r.s === selectedFactorId && !isNaN(Number(r.response)))
        .map(r => Number(r.response));
    }
  
    const outcomeAvg =
      numericResponses.length > 0
        ? numericResponses.reduce((a, b) => a + b, 0) / numericResponses.length
        : 0;
  
    // 2) Gather feedback from the "feedback_builder"
    //    The sample snippet you provided has `feedback_builder.responses`,
    //    each with an `s` that indicates which outcome it's referencing.
    //    We only want the feedback for s === selectedFactorId, and we only care
    //    if the `response` is not null/empty.
    let outcomeFeedback = [];
    if (
      respondent.feedback_builder &&
      Array.isArray(respondent.feedback_builder.responses)
    ) {
      outcomeFeedback = respondent.feedback_builder.responses
        .filter(r => parseInt(r.q) === parseInt(selectedFactorId) && r.response)
        .map(r => r.response);
    }
  
    return {
      overall_average: outcomeAvg,
      feedback: outcomeFeedback
    };
  }
  
  
  
  
  ////////////////////////////////////////////////////////////
  // 3. MAIN ANALYSIS
  ////////////////////////////////////////////////////////////
  
  /**
   * mainAnalysis
   *   - Takes in the "coreDataCategories" array (from `core_data.categories[0].categories`)
   *   - Takes in the "responses" array from raw_data[0].responses
   *   - Builds a data table where each row corresponds to one respondent
   *   - The row includes dimension-factor averages, combined_score
   *   - The row also includes columns for each category (using the category's .name 
   *     and the selected option's name)
   *   - Then runs likelihood analysis grouping on each of those category-name columns
   */
  export function mainAnalysis(coreDataCategories, responses, selectedFactor) {
    // 1) Build categoryMap once
    const categoryMap = buildCategoryMap(coreDataCategories);
  
    // 2) Collect all category names so we know which columns to run the likelihood analysis on
    const allCatNames = new Set();
  
    // 3) Build the "data table" for likelihood analysis (no change from your existing code)
    const dataTable = responses.map((respondent, idx) => {
      const combinedScore = computeCombinedScore(respondent, selectedFactor);
  
      const row = {
        respondentIndex: idx,
        combined_score: combinedScore
      };
  
      if (Array.isArray(respondent.categories)) {
        respondent.categories.forEach(catObj => {
          const catDef = categoryMap[catObj.id];
          if (catDef) {
            const columnName = catDef.catName; // e.g. "Age", "Location"
            let optionVal = null;
  
            if (catObj.response && catDef.options[catObj.response]) {
              optionVal = catDef.options[catObj.response];
            }
  
            row[columnName] = optionVal;
            allCatNames.add(columnName);
          }
        });
      }
  
      return row;
    });
  
    // Convert set to array
    const categoryColumns = Array.from(allCatNames);
  
    // 4) Run your likelihood analysis logic
    let min_score = 4;
    let less_than_3 = true;
    const cat_length = runLikelihoodAnalysis(dataTable, 'combined_score', categoryColumns, min_score).length;
  
    let results;
    while (less_than_3 && min_score <= 8) {
      results = runLikelihoodAnalysis(dataTable, 'combined_score', categoryColumns, min_score)
        .filter(f => f.rrCiLower > 1 && f.rrCiUpper > 1)
        .sort((a, b) => b.relativeRisk - a.relativeRisk)
        .filter(f => f.totalCount > 3);
      if (results.length > 3) {
        less_than_3 = false;
      } else {
        min_score += 1;
      }
    }
  
    // 5) Build the “analysisBreakdown” that includes category_values
    const analysisBreakdown = responses.map((respondent, idx) => {
      // A) Gather all the category values in an array
      const catValues = [];
      if (Array.isArray(respondent.categories)) {
        respondent.categories.forEach(catObj => {
          const catDef = categoryMap[catObj.id];
          if (catDef) {
            let optionVal = null;
            if (catObj.response && catDef.options[catObj.response]) {
              optionVal = catDef.options[catObj.response];
            }
            if (optionVal) catValues.push(optionVal);
          }
        });
      }
  
      // B) Build the row depending on whether it's Culture or Outcome
      if (selectedFactor.type === 'Culture') {
        // Factor-level breakdown (Dimension+Factor pairs)
        const { factorDimAverages, overall_average } = buildFactorAveragesForCulture(respondent);
  
        const cultureFeedback = Array.isArray(respondent.feedback)
          ? respondent.feedback
          : [];
  
        return {
          respondentIndex: idx,
          // Put the category values array here
          category_values: catValues,
          // Spread out each dimension-factor average
          ...factorDimAverages,
          overall_average,
          feedback: cultureFeedback
        };
      } else if (selectedFactor.type === 'outcome') {
        // Outcome-level breakdown
        const { overall_average, feedback } = buildOutcomeAverageAndFeedback(
          respondent,
          selectedFactor.id
        );
  
        return {
          respondentIndex: idx,
          category_values: catValues, // also store the array here
          overall_average,
          feedback
        };
      } else {
        // fallback
        return {
          respondentIndex: idx,
          category_values: catValues,
          overall_average: 0,
          feedback: []
        };
      }
    });
  
    // 6) Return whichever data you like.
    //    In this example, we return both your existing results from the
    //    likelihood analysis as well as the new “analysisBreakdown”.

    return {
      finalResults: results,
      low_score:min_score,
      cat_length,
      analysisBreakdown
    };
  }
  