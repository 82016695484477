import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Assets
import EmptyImage from "assets/images/empty_image.png";

// Components
import CenterPageTemplate from "../CenterPageTemplate";
import Text from "new_components/atoms/Text";
import AILogo from "new_components/molecules/AILogo";

// Constants
import colors from "styles/colors";
import typography from "styles/typography";

const EmptyStateImage = styled.img`
  max-width: 180px;
  height: auto;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 500px;
`;

const EmptyTemplate = ({
  heading,
  pageTitle,
  titleComponent,
  emptyText,
  actionRow,
}) => {
  return (
    <CenterPageTemplate>
      {/* <EmptyStateImage src={EmptyImage} /> */}
      <AILogo />
      {titleComponent}
      <DescriptionContainer>
        <Text
          typographyStyle={typography.body.regular}
          color={colors.text.gray}
        >
          {emptyText}
        </Text>
        {actionRow}
      </DescriptionContainer>
    </CenterPageTemplate>
  );
};

EmptyTemplate.propTypes = {
  heading: PropTypes.string, // Heading text for the base layout
  pageTitle: PropTypes.string, // Page title for the base layout
  children: PropTypes.node, // Content passed into the layout
};

export default EmptyTemplate;
