// LandingPageContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { SG_UPDATE_TASK_TEMPLATE } from "constants/actions";

// Components and Utilities
import Badge from "new_components/atoms/Badge";
import Text from "new_components/atoms/Text";
import Icon from "new_components/atoms/Icon";
import Tooltip from "new_components/molecules/Tooltip";
import { ICON_SET } from "new_components/atoms/Icon/IconSet";
import colors from "styles/colors";
import typography from "styles/typography";
import styled from "styled-components";
import { useNavigate } from "react-router";

// Styled Components
const StyledActionsContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: left;
`;

// Badge Colors
const BADGE_COLORS = {
  1: "badge.neutral", // Draft
  3: "secondary", // Sent
};

// Create the context
const LandingPageContext = createContext();

// Custom hook to use the context
export const usePulseSurveys = () => useContext(LandingPageContext);

// Provider component
export const PulseSurveysProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [surveyData, setSurveyData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const { scheduledPulseSurveys, allPulseSurveys, get_auth } = useSelector(
    (state) => ({
      scheduledPulseSurveys: state.task_templates.scheduled_tasks,
      allPulseSurveys: state.task_templates.task_templates,
      get_auth: state.auth,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (allPulseSurveys?.results?.length > 0) {
      setSurveyData(
        parseSurveyData(
          scheduledPulseSurveys?.results,
          allPulseSurveys?.results,
          Number(get_auth?.employee?.id)
        )
      );
    }
  }, [scheduledPulseSurveys, allPulseSurveys, get_auth]);

  // Modal State
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedSurveyId, setSelectedSurveyId] = useState(null);

  const handleSearch = (value) => setSearchValue(value);

  // Filter data by search
  const filteredData = searchValue
    ? surveyData.filter((row) =>
        Object.values(row).some((value) => {
          // Ignore React elements, just check string/numbers
          if (typeof value === "string") {
            return value.toLowerCase().includes(searchValue.toLowerCase());
          }
          return false;
        })
      )
    : surveyData;

  // Delete Logic
  const triggerDeleteModal = (id) => {
    setSelectedSurveyId(id);
    setDeleteModalOpen(true);
  };

  const handleDelete = () => {
    setSurveyData((prevData) =>
      prevData.filter((item) => item.id !== selectedSurveyId)
    );

    dispatch({
      type: SG_UPDATE_TASK_TEMPLATE,
      payload: {
        id: selectedSurveyId,
        status: 99,
      },
    });
    setDeleteModalOpen(false);
    setSelectedSurveyId(null);
  };

  const handleEdit = (id) => {
    // Navigate to edit page
    navigate(`/leader/audit/AI/pulse/edit/${id}`);
  };

  const handleSend = (id) => {
    // Navigate to edit page for now
    navigate(`/leader/audit/AI/pulse/edit/${id}`);
    // Implement real 'send' logic here
  };

  const handleView = (id) => {
    // Navigate to view page
    navigate(`/leader/audit/AI/pulse/view/${id}`);
  };

  // Helper Functions
  const formatUpdatedDate = (date) =>
    new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });

  const getActionsForRow = (row, template) => {
    const isDraft = row.status.props.text === "Draft";
    const isSent = row.status.props.text === "Sent";
    // Get the id of the row
    const id = template?.id;

    const actions = [];
    if (isDraft) {
      actions.push(
        <Tooltip key="editTooltip" tooltipText="Edit Survey">
          <Icon
            key="edit"
            name={ICON_SET.edit}
            size={20}
            onClick={() => handleEdit(id)}
          />
        </Tooltip>,
        <Tooltip key="deleteTooltip" tooltipText="Delete Survey">
          <Icon
            key="delete"
            name={ICON_SET.delete}
            size={20}
            onClick={() => triggerDeleteModal(id)}
          />
        </Tooltip>
      );
    }
    if (isSent) {
      actions.push(
        <Tooltip key="viewTooltip" tooltipText="View Survey Data">
          <Icon
            key="send"
            name={ICON_SET.chevronRight}
            size={20}
            onClick={() => handleView(id)}
          />
        </Tooltip>
      );
    }
    return <StyledActionsContainer>{actions}</StyledActionsContainer>;
  };

  // Renders the "filledOut" column
  // FilledOut Component
  const FilledOut = ({ filledOut, total }) => {
    return (
      <div>
        <Text
          typographyStyle={typography.caption.semibold}
          color={colors.text.gray}
        >
          {filledOut}
        </Text>{" "}
        <Text
          color={colors.text.gray}
          typographyStyle={typography.caption.regular}
        >
          / {total}
        </Text>
      </div>
    );
  };

  const mapDrafts = (templates, accessId) =>
    templates
      ?.filter((template) =>
        template?.user_tasks?.find(
          (task) =>
            task?.task_type === "created_by" && task?.user_id === accessId
        )
      )
      .map((template) => {
        const rowData = {
          // Display properties (unchanged):
          name: template?.name,
          updated: (
            <Text
              color={colors.text.gray}
              typographyStyle={typography.caption.semibold}
            >
              {formatUpdatedDate(template?.updated_at)}
            </Text>
          ),
          status: <Badge text="Draft" color={BADGE_COLORS[1]} />,
          // filledOut: (
          //   <Text
          //     color={colors.text.gray}
          //     typographyStyle={typography.caption.semibold}
          //   >
          //     N/A
          //   </Text>
          // ),

          // **New** properties for sorting:
          updatedValue: template?.updated_at || null, // raw date string/ISO
          statusValue: 1, // numeric value for Draft
          // filledOutValue: null, // "N/A"
          id: template?.id, // ensure row has 'id'
        };

        return {
          ...rowData,
          actions: getActionsForRow(rowData, template), // existing logic
        };
      });

  const mapSentTasks = (tasks, accessId) =>
    tasks
      ?.filter((task) => {
        return (
          task?.created_by === accessId ||
          task?.employee_access?.includes(accessId)
        );
      })
      .map((task) => {
        const assignedCount = task?.assigned_employees?.length || 0;
        const rowData = {
          // Display properties (unchanged):
          name: task?.name,
          updated: (
            <Text
              color={colors.text.gray}
              typographyStyle={typography.caption.semibold}
            >
              {formatUpdatedDate(task?.updated_at)}
            </Text>
          ),
          status: <Badge text="Sent" color={BADGE_COLORS[3]} />,
          // filledOut: <FilledOut filledOut={0} total={assignedCount} />,

          // **New** properties for sorting:
          updatedValue: task?.updated_at || null,
          statusValue: 3, // numeric value for Sent
          // filledOutValue: 0, // if we’re tracking how many have filled out
          id: task?.id,
        };

        return {
          ...rowData,
          actions: getActionsForRow(rowData, task),
        };
      });

  const parseSurveyData = (scheduled, templates, accessId) => {
    if (!templates) return [];

    // Task IDs that are scheduled & have assigned employees
    const scheduledTaskIds = scheduled
      ?.filter((task) => task?.assigned_employees?.length > 0)

      ?.map((task) => task?.task_template_user_tasks);

    // Filter out archived or scheduled ones from the drafts
    const filteredTemplates = templates
      ?.filter((template) => !scheduledTaskIds?.includes(template?.id))

      .filter((template) => template?.status !== 99);

    // Map drafts and sent tasks
    const drafts = mapDrafts(filteredTemplates, accessId);
    const sentTasks = scheduled ? mapSentTasks(scheduled, accessId) : [];

    // Combine them
    const combinedData = [...sentTasks, ...drafts];

    // (Optional) Sort by date descending by default
    combinedData.sort(
      (a, b) => new Date(b.updatedValue) - new Date(a.updatedValue)
    );

    return combinedData;
  };

  // Define our column configuration here, including sorting functions
  const columns = [
    {
      key: "name",
      label: "Name",
      isSortable: true,
      sortFunction: (a, b, direction) => {
        const comparison = a.name.localeCompare(b.name);
        return direction === "asc" ? comparison : -comparison;
      },
    },
    {
      key: "updated",
      label: "Updated",
      isSortable: true,
      sortFunction: (a, b, direction) => {
        const aDate = a.updatedValue ? new Date(a.updatedValue) : new Date(0);
        const bDate = b.updatedValue ? new Date(b.updatedValue) : new Date(0);
        if (aDate < bDate) return direction === "asc" ? -1 : 1;
        if (aDate > bDate) return direction === "asc" ? 1 : -1;
        return 0;
      },
    },
    {
      key: "status",
      label: "Status",
      isSortable: true,
      sortFunction: (a, b, direction) => {
        // Sort by numeric statusValue: 1 (Draft) < 3 (Sent)
        if (a.statusValue < b.statusValue) return direction === "asc" ? -1 : 1;
        if (a.statusValue > b.statusValue) return direction === "asc" ? 1 : -1;
        return 0;
      },
    },
    // {
    //   key: "filledOut",
    //   label: "Responses received",
    //   isSortable: true,
    //   sortFunction: (a, b, direction) => {
    //     // If either is null/-1, treat that as "N/A"
    //     const aVal = a.filledOutValue ?? -1;
    //     const bVal = b.filledOutValue ?? -1;
    //     if (aVal < bVal) return direction === "asc" ? -1 : 1;
    //     if (aVal > bVal) return direction === "asc" ? 1 : -1;
    //     return 0;
    //   },
    // },
    {
      key: "actions",
      label: "Actions",
      isSortable: false,
    },
  ];

  const filteredProperties = [
    "updatedValue",
    "statusValue",
    "filledOutValue",
    "id",
  ];

  return (
    <LandingPageContext.Provider
      value={{
        surveyData: filteredData, // table should receive the filteredData
        searchValue,
        handleSearch,
        columns,
        isDeleteModalOpen,
        handleDelete,
        setDeleteModalOpen,
        filteredProperties,
      }}
    >
      {children}
    </LandingPageContext.Provider>
  );
};