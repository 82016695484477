import React, { useState } from "react";
import styled from "styled-components";
import SelectBox from "new_components/molecules/SelectBox";
import { ICON_SET } from "new_components/atoms/Icon/IconSet";
import Tooltip from "new_components/molecules/Tooltip";
import IconWithBackground from "new_components/molecules/IconWithBackground";
import Button from "new_components/atoms/Button";

const DataSourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SourceRow = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
  width: 100%;

  .remove-button {
    display: none;
  }

  &:hover .remove-button {
    display: block;
  }
`;

const AddButtonContainer = styled.div`
  position: relative;

  .add-button {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  }

  &:hover .add-button {
    opacity: 1;
    visibility: visible;
  }
`;

function DataSource({ dataSourceConfig = [], setSourceType }) {
  /**
   * We'll maintain multiple data source "rows" in state.
   * Each entry is { type: <string|null>, subOption: <string|null> }.
   */
  const [dataSources, setDataSources] = useState([
    { type: null, subOption: null },
  ]);

  // Convert dataSourceConfig to something we can pass to the SelectBox
  const topLevelOptions = dataSourceConfig.map((ds) => ({
    label: ds.label,
    value: ds.value,
    callback: ds?.callback,
    options: ds?.options, // Keep a reference to pass sub-options later
    settings: ds?.settings, // Any other settings you want to pass
  }));
  // Local state to store which data source type the user selected (pulse, outcome, etc.)
  const [selectedDataSourceType, setSelectedDataSourceType] = useState(null);

  const [pendingDataSource, setPendingDataSource] = useState(null);

  const [showReset, setShowReset] = useState(false);

  /**
   * Returns sub-options for a given data source type.
   */
  const getSubOptions = (dataSourceType) => {
    if (!dataSourceType) return [];

    // Find the config object for this particular data source
    const foundConfig = dataSourceConfig.find(
      (ds) => ds.value === dataSourceType
    );
    if (!foundConfig) return [];

    switch (foundConfig.value) {
      case "pulse":
        // Example: map the `options` array to { label, value } for the SelectBox
        return (foundConfig.options || []).map((p) => ({
          label: p?.task_name || p?.name || "Unnamed Pulse",
          value: p.id,
        }));
      case "outcome":
        return foundConfig.options;
      case "culture":
        return foundConfig.options;
      default:
        return [];
    }
  };

  /**
   * Handle user selecting a top-level data source.
   */
  const handleDataSourceSelect = (rowIndex, newType) => {
    setDataSources((prev) => {
      const updated = [...prev];
      updated[rowIndex].type = newType;
      updated[rowIndex].subOption = null; // reset subOption on new top-level selection
      return updated;
    });

    // If you still want to call `setSourceType` for the "first" data source or something,
    // you can do that conditionally. For example:
    if (rowIndex === 0 && typeof setSourceType === "function") {
      setSourceType(newType);
      setSelectedDataSourceType(newType);
    }
  };

  // Create a handleAddSource that accepts the selected data source and adds it to the list of data sources.
  // This function should also reset the pending data source and hide the reset button.
  // This function should also call the callback function for the selected data source.
  const handleSelectAndAddDataSource = (selectedDataSource) => {
    if (selectedDataSource) {
      const foundConfig = dataSourceConfig.find((ds) => ds.value === "pulse");

      if (foundConfig && foundConfig?.callback) {
        foundConfig.callback(selectedDataSource);
      }
    }
    setShowReset(false);
    setDataSources([{ type: null, subOption: null }]);
    setPendingDataSource(null);
  };

  /**
   * Handle user selecting a sub-option (e.g. which Pulse).
   */
  const handleSubOptionSelect = (rowIndex, newValue, type) => {
    //   If the selected data is already selected, remove it.
    setDataSources((prev) => {
      const updated = [...prev];
      updated[rowIndex].subOption = newValue;
      return updated;
    });

    // If the type is pulse, automatically select the value and add it.
    // There's no need to show the reset or add button in this case
    if (type === "pulse") {
      handleSelectAndAddDataSource(newValue);
      return;
    }

    setShowReset(true);
    setPendingDataSource({ type, pendingValue: newValue });
  };

  /**
   * add the pending data source to its appropriate callback
   */
  const handleAddDataSource = () => {
    if (pendingDataSource) {
      const foundConfig = dataSourceConfig.find(
        (ds) => ds.value === pendingDataSource.type
      );
      if (foundConfig && foundConfig?.callback) {
        foundConfig.callback(pendingDataSource.pendingValue);
      }
    }
    setShowReset(false);
    setDataSources([{ type: null, subOption: null }]);
    setPendingDataSource(null);
  };

  const resetRows = (rowIndex) => {
    setDataSources([{ type: null, subOption: null }]);
    setShowReset(false);
  };

  return (
    <DataSourceContainer>
      {/* Render one row per data source */}
      {dataSources.map((ds, index) => {
        const subOptions = getSubOptions(ds.type);

        // If there are no sub options, don't show the sub-option SelectBox
        if (subOptions.length === 0) {
          ds.subOption = null;
        }

        // Find the currently selected top-level object (for controlled input)
        const selectedTopLevel =
          ds.type && topLevelOptions.find((o) => o.value === ds.type);
        // Find the currently selected sub-option
        const selectedSub =
          ds.subOption && subOptions.find((o) => o.value === ds.subOption);

        const subSettings =
          ds.type && topLevelOptions.find((o) => o.value === ds.type)?.settings;

        return (
          <SourceRow key={index}>
            {/* Top-level (pulse, outcome, etc.) */}
            <SelectBox
              width="230px"
              label="Select data source"
              options={topLevelOptions}
              onSelect={(val) => {
                handleDataSourceSelect(index, val);
              }}
              value={selectedTopLevel || null}
            />

            {/* If the chosen data source has sub-options, show a sub-option SelectBox */}
            {subOptions.length > 0 && (
              <SelectBox
                width="400px"
                settings={subSettings}
                label={`Select ${ds.type} option`}
                options={subOptions}
                onSelect={(val) => {
                  // Select the value automatically and add it if its a pulse type.
                  if (ds.type === "pulse") {
                    handleSubOptionSelect(index, val, ds.type);
                  }

                  handleSubOptionSelect(index, val, ds.type);
                }}
                value={selectedSub || null}
              />
            )}

            {/* Remove button (hidden if only one row and you don't want it removable) */}
            {selectedDataSourceType && showReset && (
              <Tooltip tooltipText="Clear selection">
                <Button
                  onClick={() => resetRows()}
                  variant="secondary"
                  style={{ height: 40 }}
                >
                  Reset
                </Button>
              </Tooltip>
            )}

            <Tooltip tooltipText="Add another data source">
              <IconWithBackground
                name={ICON_SET.add}
                onClick={
                  selectedDataSourceType &&
                  pendingDataSource &&
                  handleAddDataSource
                }
                disabled={!selectedDataSourceType || !pendingDataSource}
              />
            </Tooltip>
          </SourceRow>
        );
      })}
    </DataSourceContainer>
  );
}

export default DataSource;
