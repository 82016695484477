import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SG_GET_ORG_SLACK,
  GET_ORG_SLACK,
  SG_DELETE_ORG_SLACK,
  DELETE_ORG_SLACK,
  SG_FETCH_ORG_SLACK,
  FETCH_ORG_SLACK,
  SG_EDIT_ORG_SLACK,
  EDIT_ORG_SLACK,
  SG_ADD_ORG_SLACK,
  ADD_ORG_SLACK,
  PENDING_ORG_SLACK,
  UPDATE_SLACK_APP,
} from 'constants/actions';
import * as api from "constants/api";

function* getOrgSlack(action) {
  try {
    yield put({ type: PENDING_ORG_SLACK });
    const response = yield call(api.GET_ORG_SLACK, action.payload);
    yield put({ type: GET_ORG_SLACK, payload: response.data });
  } catch (e) {
    console.error(e);
  }
}

function* deleteOrgSlack(action) {
  try {
    yield put({ type: PENDING_ORG_SLACK });
    yield call(api.DELETE_ORG_SLACK, action.payload);
    yield put({ type: DELETE_ORG_SLACK, payload: action.payload });

    yield put({ type: UPDATE_SLACK_APP }); // to force update the slack data
  } catch (e) {
    console.error(e);
  }
}

function* editOrgSlack(action) {
  try {
    yield put({ type: PENDING_ORG_SLACK });
    const response = yield call(api.EDIT_ORG_SLACK, action.payload);
    yield put({ type: EDIT_ORG_SLACK, payload: response.data });
  } catch (e) {
    console.error(e);
  }
}


function* addOrgSlack(action) {
  try {
    yield put({ type: PENDING_ORG_SLACK });
    const response = yield call(api.ADD_ORG_SLACK, action.payload);
    yield put({ type: ADD_ORG_SLACK, payload: response.data });
  } catch (e) {
    console.error(e);
  }
}

function* fetchOrgSlackDetails(action) {
  try {
    yield put({ type: PENDING_ORG_SLACK });
    const response = yield call(api.FETCH_ORG_SLACK, action.payload);
    yield put({ type: FETCH_ORG_SLACK, payload: response.data });
  } catch (e) {
    console.error(e);
  }
}

export default function* orgSlackSaga() {
  yield takeLatest(SG_GET_ORG_SLACK, getOrgSlack);
  yield takeLatest(SG_DELETE_ORG_SLACK, deleteOrgSlack);
  yield takeLatest(SG_EDIT_ORG_SLACK, editOrgSlack);
  yield takeLatest(SG_ADD_ORG_SLACK, addOrgSlack);
  yield takeLatest(SG_FETCH_ORG_SLACK, fetchOrgSlackDetails);
}