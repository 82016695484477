import styled from "styled-components";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Icon } from "semantic-ui-react";
import Filters from "./Filters";
import QuestionDropdown from "./QuestionDropdown";
import RadioButtonGroup from "./components/RadioButton";
import HorizontalBarChart from "components/Charts/HorizontalBarChart";
import { convertFeedbackData } from "components/Charts/barChartUtils";

const chartOptions = [
  { name: "Very Negative", color: "#de2121" },
  { name: "Slightly Negative", color: "#F57373" },
  { name: "Neutral", color: "#F7B531" },
  { name: "Slightly Positive", color: "#80C7A0" },
  { name: "Very Positive", color: "#3D8B67" },
];

const sentiments = [
  { id: "Very Negative", value: "Very Negative", name: "Very Negative" },
  {
    id: "Slightly Negative",
    value: "Slightly Negative",
    name: "Slightly Negative",
  },
  { id: "Neutral", value: "Neutral", name: "Neutral" },
  {
    id: "Slightly Positive",
    value: "Slightly Positive",
    name: "Slightly Positive",
  },
  { id: "Very Positive", value: "Very Positive", name: "Very Positive" },
];

const FeedbackTag = ({
  data = [],
  outcomeQ,
  isOTP,
  selectedQuestion,
  setQuestion,
  showComments,
  feedbackType,
  setFeedbackType,

  // Multi-select sentiment handling:
  selectedSentiments = [],
  setSelectedSentiments,
  displayedFeedback,
  setSelectedOutcomes,
}) => {
  const [chartData, setChartData] = useState([]);
  const [immutableChartData, setImmutableChartData] = useState([]);
  const [actionablePercentages, setActionablePercentages] = useState([]);
  const [loading, setLoading] = useState(false);

  const { core_data } = useSelector(
    (state) => ({
      core_data: state.audit?.core_data,
    }),
    shallowEqual
  );

  // For comment questions if needed
  const [commentQuestions, setCommentQuestions] = useState([]);
  const originalDataRef = useRef();

  // Convert your data to chart data
  useEffect(() => {
    setLoading(true);

    // Summarize sentiments
    const sentimentData = {};
    data.forEach((item) => {
      const s = item?.nlp?.sentiment;
      const isAction = item?.nlp?.action_orientation === "action_oriented";
      if (s) {
        if (!sentimentData[s]) {
          sentimentData[s] = { total: 0, action_oriented: 0 };
        }
        sentimentData[s].total++;
        if (isAction) sentimentData[s].action_oriented++;
      }
    });

    // Keep consistent order
    const sentimentOrder = [
      "Very Negative",
      "Slightly Negative",
      "Neutral",
      "Slightly Positive",
      "Very Positive",
    ];

    // Build series
    const _series = sentimentOrder.map((s) => {
      const total = sentimentData[s]?.total || 0;
      return { name: s, data: [total] };
    });

    // Calculate action-oriented percentages
    const _actionable = sentimentOrder.map((s) => {
      const total = sentimentData[s]?.total || 0;
      const actionOriented = sentimentData[s]?.action_oriented || 0;
      return total > 0 ? ((actionOriented / total) * 100).toFixed(1) : "0.0";
    });

    // Convert to chart friendly
    const cData = convertFeedbackData(_series, chartOptions, _actionable);
    setChartData(cData);
    setImmutableChartData(cData);
    setActionablePercentages(_actionable);

    setLoading(false);
  }, [data]);

  // Example of your overall feedback percentage usage
  const getFeedbackPercentage = () => {
    let total = 0;
    let action = 0;
    data.forEach((item) => {
      if (item?.nlp?.action_orientation === "action_oriented") action++;
      total++;
    });
    return total === 0 ? 0 : (action / total) * 100;
  };

  // Filter config for your "Filters" component
  const feedbackFiltersConfig = [
    {
      id: "outcome",
      title: "Outcomes",
      data: outcomeQ?.questions,
      callback: setSelectedOutcomes,
    },
    {
      id: "sentiment",
      title: "Sentiment",
      data: sentiments,
      // Instead of setting one "sentiment",
      // we pass an array of sentiments back to the parent
      callback: (selectedArray) => {
        setSelectedSentiments(selectedArray);
      },
    },
  ];

  // Handle user toggling a bar from the chart
  const handleBarSelect = (name) => {
    // “name” is e.g. "Very Negative"
    // Check if it's already selected
    if (selectedSentiments.includes(name)) {
      // remove it
      const newArray = selectedSentiments.filter((s) => s !== name);
      setSelectedSentiments(newArray);
    } else {
      // add it
      setSelectedSentiments([...selectedSentiments, name]);
    }
  };

  return (
    <Container hasFeedback={displayedFeedback?.length > 0}>
      {showComments && (
        <RadioButtonGroup
          onChange={(val) => setFeedbackType(Number(val))}
          options={[
            { label: "Feedback", value: 0, name: "Feedback" },
            { label: "Comments", value: 1, name: "Comments" },
          ]}
          selected={feedbackType}
        />
      )}

      <BottomRow>
        <Filters
          hideFactors={false}
          filters={feedbackFiltersConfig}
          outcomes={outcomeQ?.questions}
          onSelectOutcomes={setSelectedOutcomes}
          barSentiments={selectedSentiments}
          onSentimentFilter={(selected) => setSelectedSentiments(selected)}
          // If your filter component can handle multi-check for sentiments,
          // it should call `callback([arrayOfSelected])`.
        />
      </BottomRow>

      {/* Example Bar Chart */}
      {!loading &&
        immutableChartData?.length > 0 &&
        ((displayedFeedback?.length > 0 && selectedSentiments?.length === 0) ||
          selectedSentiments?.length > 0) && (
          <ChartContainer>
            <HorizontalBarChart
              data={immutableChartData}
              actionablePercentages={actionablePercentages}
              selectedSentiments={selectedSentiments}
              onSelect={(barName) => handleBarSelect(barName)}
            />
          </ChartContainer>
        )}

      {/* If needed: question dropdown */}
      {(isOTP || feedbackType === 1) && (
        <DropdownSection>
          <QuestionDropdown
            label="Filter by question"
            selectedOption={selectedQuestion}
            setSelected={(val) => setQuestion(val)}
            options={commentQuestions /* or your contextQuestions */}
          />
        </DropdownSection>
      )}
    </Container>
  );
};

export default FeedbackTag;

/* --- Styles simplified --- */

const Container = styled.div`
  margin-bottom: ${({ hasFeedback }) => (hasFeedback ? "40px" : "0")};
  position: relative;
`;
const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  magin-bottom: 10px;
`;
const ChartContainer = styled.div`
  height: 150px;
  margin-bottom: 15px;
  width: 100%;
`;
const DropdownSection = styled.div`
  display: flex;
  margin-top: 20px;
`;
