import React, { useState, useEffect, useContext, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Icon } from 'semantic-ui-react';

import { CopilotContext, CopilotProvider } from '../CopilotContext';
import Thematics from '../Functions/Thematics';
import ThematicCard from '../Components/ThematicCard';
import ThematicsCards from '../Components/ThematicsCards';
import { useSelector, shallowEqual,useDispatch } from 'react-redux';
import { SET_NEW_FILTERS } from "constants/actions";


function RiskHomeComponent({
  goHome,
  initialSession,
  onSaveSession,
}) {
  const scrollRef = useRef(null);
  const endOfMessagesRef = useRef(null);
    const dispatch = useDispatch();
  

  const {
    messages,
    setMessages,
    phase,
    clearMessages,
    setPromptText,
    getBatchEmbeddings
  } = useContext(CopilotContext);

  /**
   * 1) If we have an initialSession with messages, we want to skip the intro
   *    even on the first render. We'll detect that synchronously.
   */
  const hasInitialMessages =
    initialSession?.messages && initialSession.messages.length > 0;

  /**
   * 2) Local states:
   *   - started: determines if we show the “intro” or the conversation.
   *   - prompt: stores {data} from your Thematics onClick
   */
  const [started, setStarted] = useState(hasInitialMessages);
  const [prompt, setPrompt] = useState('');
  const [selectedFactors, setSelectedFactors] = useState();
  const [followUp, setFollowUp] = useState(false);
  const [suggestionHistory, setSuggestionHistory] = useState([]);

  const selectedFilters = useSelector((state) => state.audit?.new_filters,shallowEqual);

  useEffect(()=>{
    return ()=>{
        dispatch({ type: SET_NEW_FILTERS, payload: [] })
        }
  },[])
  

  /** 
   * 3) On mount, if we do have an initialSession with messages, load them.
   *    Because we already used hasInitialMessages, if that was true, we set started=true.
   */
  useEffect(() => {
    if (initialSession) {
        console.log(initialSession)
      // If there are messages, put them into the context:
      if (initialSession.messages?.length) {
        setMessages(initialSession.messages);
      }
      // Also restore selectedFactors or suggestions if you saved them:
      if (initialSession.selectedFactors) {
        setSelectedFactors(initialSession.selectedFactors);
      }
      if (initialSession.suggestionHistory) {
        setSuggestionHistory(initialSession.suggestionHistory);
      }
      if(initialSession.filters){
        dispatch({ type: SET_NEW_FILTERS, payload: initialSession.filters })
      }
    }
  }, [initialSession, setMessages]);

  /**
   * 4) Original logic: if (phase === 'idle' && prompt) => setStarted + add function message
   */
  useEffect(() => {
    if (phase === 'idle' && prompt) {
      console.log('Prompt set => adding function message', prompt);
      setStarted(true);
      setMessages((prev) => [
        ...prev,
        { role: 'function', content: 'Running thematic analysis' }
      ]);
    }
  }, [phase, prompt, setMessages]);

  /**
   * 5) If followUp => add a follow_up message
   */
  useEffect(() => {
    if (followUp) {
      setMessages((prev) => [
        ...prev,
        { role: 'follow_up', content: 'Running thematic analysis on feedback' }
      ]);
    }
  }, [followUp, setMessages]);

  /**
   * 6) "registerHistory" => your original function to setPrompt({ data })
   */
  const registerHistory = (data) => {
    setPrompt({ data });
  };

  /**
   * 7) Scroll to bottom when messages change
   */
  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  /**
   * 8) goBack => reset
   */
  const goBack = () => {
    clearMessages();
    setPromptText('');
    setStarted(false);
  };

  /**
   * 9) If not started + no messages => show the "intro"
   *    Because we used hasInitialMessages in `useState`, if we loaded a session 
   *    with messages, `started` is already true => we skip this.
   */
  if (!started && messages.length === 0) {
    return (
      <Container>
        {/* <BackButton onClick={goHome}>
          <Icon style={{ marginRight: 10 }} name="arrow left" />
          Back
        </BackButton> */}
        <Thematics
          intro={true}
          selectedFactors={selectedFactors}
          setSelectedFactors={setSelectedFactors}
          onClick={({ data }) => registerHistory(data)}
        />
      </Container>
    );
  }

  return (
    <Container ref={scrollRef}>
      {/* <BackButton onClick={goBack}>
        <Icon style={{ marginRight: 10 }} name="arrow left" />
        Back
      </BackButton>
       */}

        <Title>Advanced Thematic Analysis</Title>

      <Content>
        <MessageContainer>
          {messages.map((msg, i) => {
            // If function => ThematicCard
            if (msg.role === 'function') {
              const hasAssistantAfter = messages
                .slice(i + 1)
                .some((m) => m.role === 'assistant');

              return (
                <ThematicCard
                  key={i}
                  data={prompt.data} // same as original
                  getBatchEmbeddings={getBatchEmbeddings}
                  alreadyRun={hasAssistantAfter}
                  onDone={(finalMessages) => {
                    onSaveSession?.({
                      type: 'thematic',
                      messages: finalMessages,
                      selectedFactors,
                      suggestionHistory,
                      filters:selectedFilters
                    });
                  }}
                />
              );
            }

            // Otherwise => ThematicsCards
            return (
              <ThematicsCards
                key={i}
                data={msg.content}
                factor={selectedFactors}
                filters={selectedFilters}
              />
            );
          })}
          <div ref={endOfMessagesRef} />
        </MessageContainer>
      </Content>
    </Container>
  );
}

export default function RiskHome(props) {
  return (
    <CopilotProvider>
      <RiskHomeComponent {...props} />
    </CopilotProvider>
  );
}

/* ====================== STYLES ====================== */

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  font-family: 'Raleway', sans-serif;
  padding: 20px;
  padding-top:0px;
  width: 100%;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color:#2A3039;

`;

const Content = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: center;
  margin-top:20px;
  width:100%;
`;

const MessageContainer = styled.div`
  width:100%;
  max-width:700px;
  padding-bottom:150px;
`;

const BackButton = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
  cursor: pointer;
  color: grey;
  margin-right: 20px;
  opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
`;
