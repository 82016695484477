import React, { useRef } from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import html2canvas from 'html2canvas';
import { Icon } from 'semantic-ui-react';

// Include Raleway via createGlobalStyle
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;600;700&display=swap');

  body {
    margin: 0;
    padding: 0;
    font-family: 'Raleway', sans-serif;
    background-color: #f9fafc;
    color: #333;
  }
`;

export default function ThematicCards({ data, factor, filters }) {
  const cardRefs = useRef([]); // Create a ref array for all cards

  const captureCardImage = async (index, themeName) => {
    const cardRef = cardRefs.current[index];
    if (!cardRef) return;

    const canvas = await html2canvas(cardRef, {
      scale: 2, // Increase for higher resolution
      backgroundColor: null,
    });

    const dataUrl = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = `${themeName}-card.png`;
    link.click();
  };

  // Sort by descending count
  const sortedData = [...data].sort((a, b) => b.count - a.count);

  // Compute total so we can turn `count` into a percentage
  const totalCount = sortedData.reduce((acc, item) => acc + item.count, 0);

  const factorName =
    factor.type === 'Overview'
      ? 'Overview'
      : factor.type === 'culture'
      ? factor.title
      : factor.name;

  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <TopSection>
          <TopTitle>{factorName} Themes</TopTitle>
        </TopSection>
        <FilterNames>
          {filters.map((filter, idx) => (
            <FilterName key={idx}>{filter.name}</FilterName>
          ))}
        </FilterNames>
        <CardsContainer>
          {sortedData.map((item, idx) => {
            const percentage =
              totalCount > 0
                ? ((item.count / totalCount) * 100).toFixed(1)
                : 0;

            return (
              <CardWrapper
                key={idx}
                ref={(el) => (cardRefs.current[idx] = el)} // Assign each card ref
              >
                <Card delay={idx * 0.15}>
                  <DownloadIcon
                    onClick={() => captureCardImage(idx, item.theme)}
                  >
                    <Icon name="download" />
                  </DownloadIcon>
                  <EmojiRow>{item.emoji}</EmojiRow>
                  <Title>{item.theme}</Title>

                  <ProgressSection>
                    <CountText>
                      {item.count} responses out of {totalCount}&nbsp;
                      <strong>({percentage}%)</strong>
                    </CountText>
                    <ProgressBarContainer>
                      <ProgressBarFill style={{ width: `${percentage}%` }} />
                    </ProgressBarContainer>
                  </ProgressSection>

                  <SectionTitle>Overview</SectionTitle>
                  <Description>{item.description}</Description>

                  <SectionTitle>Feedback Quotes</SectionTitle>
                  <QuotesBox>
                    {item.quotes.map((quote, qIdx) => (
                      <Quote key={qIdx}>{quote}</Quote>
                    ))}
                  </QuotesBox>
                </Card>
              </CardWrapper>
            );
          })}
        </CardsContainer>
      </Wrapper>
    </>
  );
}

/* =========================== Styled Components ============================ */

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
/* ========================= Styled Components ======================== */

const Wrapper = styled.div`
  width: 100%;
  padding: 2rem 1rem;
  box-sizing: border-box;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const TopTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  color: #2e2e2e;
  margin: 0;
`;

const FilterNames = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const FilterName = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #2D70E2;
  background-color: #F8FAFF;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const CardsContainer = styled.div`
 
`;

const DownloadIcon = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
  color: #2D70E2;

  &:hover {
    transform: scale(1.1);
  }

  }
`;

const CardWrapper = styled.div`
  position: relative;

  &:hover ${DownloadIcon} {
    display: flex;
  }
`;

const Card = styled.div`
  background: #fff;
  border-radius: 12px;
  margin: 0 auto 1.5rem auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  max-width: 900px;
  padding: 2rem;
  animation: ${fadeInUp} 0.6s ease forwards;
  opacity: 0; 
  transform: translateY(15px);
  animation-delay: ${props => props.delay}s;
`;



const EmojiRow = styled.div`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  margin: 0 0 1rem 0;
  font-size: 1.4rem;
  font-weight: 600;
  color: #2e2e2e;
`;

const ProgressSection = styled.div`
  margin-bottom: 1.5rem;
`;

const CountText = styled.div`
  font-size: 0.95rem;
  margin-bottom: 0.4rem;
  color: #333;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 10px;
  background: #eef0f5;
  border-radius: 5px;
  position: relative;
`;

const ProgressBarFill = styled.div`
  height: 10px;
  background: #3a86ff;
  border-radius: 5px;
  transition: width 0.4s ease;
`;

const SectionTitle = styled.h3`
  margin: 1.2rem 0 0.5rem 0;
  font-size: 1rem;
  font-weight: 600;
  color: #2e2e2e;
`;

const Description = styled.p`
  font-size: 1rem;
  line-height: 1.45;
  color: #555;
  margin-bottom: 1rem;
`;

const QuotesBox = styled.div`
  border-left: 4px solid #eef;
  padding-left: 1rem;
  padding-right: 0.5rem;
  margin-bottom: 1rem;
`;

const Quote = styled.blockquote`
  font-style: italic;
  color: #666;
  margin: 0 0 1rem 0;
  line-height: 1.3;
`;

