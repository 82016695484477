import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import Button from "new_components/atoms/Button";
import ImageButton from "new_components/molecules/ImageButton";
import ActionMenuLayout from "new_components/templates/SubContentTemplate";
import { SurveyProvider, useSurveyContext } from "./Data/SurveyFormContext";
import SectionLoader from "new_components/molecules/SectionLoader";

// Constants
import { ICON_SET } from "new_components/atoms/Icon/IconSet";
import colors from "styles/colors";

// Sections
import NameAndDescription from "./SurveyFormCategories/NameAndDescription";
import Questions from "./SurveyFormCategories/Questions";
import AssignSection from "./SurveyFormCategories/Assign";
import EmailSection from "./SurveyFormCategories/EmailTemplate";

// Constants
import { CLEAR_TASK_TEMPLATE } from "constants/actions";

// Styles
const ActionsContainer = styled.div`
  display: flex;
  gap: 40px;
  justify-content: right;
  flex-direction: row;
`;

const SurveyFormPage = ({ mode, surveyId, initialSurveyData }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    formData,
    updateFormData,
    handleSubmit,
    saveDraft,
    loading,
    loadingMessage,
    error,
  } = useSurveyContext();

  useEffect(() => {
    if (initialSurveyData) {
      updateFormData(initialSurveyData); // Initialize with passed data
    } else if (mode === "edit" && surveyId) {
      updateFormData(surveyId);
    }

    // Remove updateFormData from dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSurveyData, mode, surveyId]);

  const menuItems = [
    {
      id: "section1",
      categoryTitle: "Name and Description",
      categoryIcon: ICON_SET.edit,
      categoryContent: (
        <NameAndDescription
          data={{
            name: formData.name,
            description: formData.description,
            survey_is_anonymous: formData.user_tasks?.find(
              (task) => task.task_type === "survey_is_anonymous"
            )?.value,
          }}
          errors={error}
          draftData={formData.draftData}
          onUpdate={(updatedData) => updateFormData(updatedData)}
          toggleAnonymity={(value) => {
            // Update the survey_is_anonymous task
            const updatedTasks = formData.user_tasks.map((task) => {
              if (task.task_type === "survey_is_anonymous") {
                return { ...task, value: value };
              }
              return task;
            });

            // if the task doesn't exist, create it
            if (
              !updatedTasks.find(
                (task) => task.task_type === "survey_is_anonymous"
              )
            ) {
              updatedTasks.push({
                task_type: "survey_is_anonymous",
                value: value,
              });
            }

            updateFormData({
              user_tasks: updatedTasks,
            });
          }}
        />
      ),
    },
    {
      id: "section2",
      categoryTitle: "Questions",
      categoryIcon: ICON_SET.question,
      categoryContent: (
        <Questions
          questions={formData.questions}
          onUpdate={(updatedQuestions) =>
            updateFormData({ questions: updatedQuestions })
          }
          errors={error}
        />
      ),
    },
    {
      id: "section3",
      categoryTitle: "Assign Survey to Employees",
      categoryIcon: ICON_SET.assign,
      categoryContent: (
        <AssignSection
          formData={{
            assignees: formData.assignedUsersList,
            draft: formData?.assignedUsersDraft,
          }}
          onUpdate={(updatedData) =>
            updateFormData({ assignedUsersList: updatedData })
          }
        />
      ),
    },
    {
      id: "section4",
      categoryTitle: "Set Email Subject and Body",
      categoryIcon: ICON_SET.mail,
      categoryContent: (
        <EmailSection
          emailTemplate={{
            email_subject: formData.emailSubject,
            email_content: formData.emailBody,
          }}
          onUpdate={(updatedData) =>
            updateFormData({
              emailSubject: updatedData.email_subject,
              emailBody: updatedData.email_content,
            })
          }
          errors={error}
        />
      ),
    },
  ];

  const clearState = () => {
    dispatch({
      type: CLEAR_TASK_TEMPLATE,
    });
  };

  return (
    <ActionMenuLayout
      heading="AI Toolkit"
      pageTitle={
        mode === "edit" ? "Editing Pulse AI Survey" : "Pulse AI Surveys"
      }
      type="multi"
      backAction={() => {
        clearState();
        if (mode === "edit") {
          navigate("/leader/audit/AI/pulse");
        } else {
          navigate(-1);
        }
      }}
      menuItems={menuItems}
      loading={loading}
      loadingComponent={
        <SectionLoader infinite currentMessage={loadingMessage} />
      }
      actionRowComponent={
        <ActionsContainer>
          <ImageButton
            textStyle={true}
            text={mode === "edit" ? "Save Changes" : "Save draft"}
            onClick={() => saveDraft()}
            icon={ICON_SET.save}
            color={colors.text.dark}
            iconSize={22}
          />
          <Button onClick={handleSubmit}>Send Survey</Button>
        </ActionsContainer>
      }
    />
  );
};

SurveyFormPage.propTypes = {
  mode: PropTypes.oneOf(["create", "edit"]).isRequired, // "create" or "edit"
  surveyId: PropTypes.string, // Required only for "edit"
};

const SurveyFormPageWrapper = (props) => {
  const location = useLocation();
  const initialSurveyData = location.state?.initialSurveyData || null;

  return (
    <SurveyProvider
      initialFormData={initialSurveyData}
      surveyId={props.surveyId}
      mode={props.mode}
    >
      <SurveyFormPage {...props} initialSurveyData={initialSurveyData} />
    </SurveyProvider>
  );
};

export default SurveyFormPageWrapper;
