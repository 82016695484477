import React, { useState } from "react";
import styled from "styled-components";

// Components
import Text from "new_components/atoms/Text";
import Modal from "new_components/molecules/Modal";
import SubMenuTemplate from "new_components/templates/SubMenuTemplate";

// Data
import prompts from "./Data/prompts.json";
import typography from "styles/typography";
import colors from "styles/colors";
import Button from "new_components/atoms/Button";

const ContentSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Content = styled.div`
  max-height: 80vh;
`;

const ActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const ContentSection = ({ prompt }) => {
  return (
    <ContentSectionContainer>
      <Text typographyStyle={typography.subhead.semibold}>{prompt.title}</Text>
      <Text typographyStyle={typography.subhead.regular}>Overview</Text>
      <Text typographyStyle={typography.body.regular} color={colors.text.gray}>
        {prompt.ques}
      </Text>
    </ContentSectionContainer>
  );
};

const PromptModal = ({ isOpen, onClose, onConfirm }) => {
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const transformedPrompts = prompts.map((promptSection) => {
    const listItems = promptSection.prompts.map((prompt) => ({
      id: prompt.id,
      categoryTitle: prompt.title,
      categoryContent: <ContentSection key={prompt.id} prompt={prompt} />,
    }));
    return {
      groupTitle: promptSection.sectionTitle,
      items: listItems,
    };
  });

  const onConfirmClose = () => {
    onConfirm(selectedPrompt);
    onClose();
  };

  return (
    <Modal
      modalWidth="800px"
      modalHeight="700px"
      isOpen={isOpen}
      onClose={onClose}
      modalHeading="Prompt Library"
      modalBody={
        <Content>
          <ActionRow>
            <Button onClick={onConfirmClose}>Select</Button>
          </ActionRow>
          <SubMenuTemplate
            menuItems={transformedPrompts}
            selectedItem={selectedPrompt}
            onItemSelect={(p) => {
              setSelectedPrompt(p?.categoryContent?.props?.prompt?.prompt);
            }}
            type="single"
            searchable
          />
        </Content>
      }
    />
  );
};

export default PromptModal;
