import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Iceberg from 'assets/images/iceberg.png';
import Top from './Top';

// Simple fade/slide-in
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Overall container
const IcebergContainer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 2rem 0;
  text-align: center;
  width: 1000px;
  font-family: 'Raleway', sans-serif;
`;

// The iceberg image
const IcebergImage = styled.img`
  width: 35%;
  height: auto;
  display: block;
  margin: 0 auto;
`;

// (Optional) dotted water line
const WaterLine = styled.div`
  position: absolute;
  top: 41%; /* Where your "water line" meets the iceberg */
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  border-top: 2px dotted #307fe2;
  z-index: 1;
`;

// Outcomes at the top
const TopOutcomes = styled.div`
  margin-bottom: 1rem;
  font-size: 14px;
  font-weight: bold;
  color: #2A3039;
  position: absolute;
  top: 37%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const OutcomeItem = styled.span`
  margin: 0 1rem;
`;

/*
  BELOW THE SURFACE
  Absolutely position this container so it overlaps the iceberg image,
  just below the water line (top: ~36-40%).
*/
const BelowSurface = styled.div`
  position: absolute;
  top: 43%;
  left: 0;
  width: 100%;
  z-index: 2; /* Above the iceberg image */
  display: flex;
  justify-content: space-between;
  padding: 0 80px;
  padding-top: 30px;
`;

// Left & right columns
const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

// Each factor row
const FactorRow = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
  opacity: 0;
  animation: ${fadeInUp} 0.6s forwards ease-out;
  animation-delay: ${({ delay }) => delay || '0s'};
  cursor: pointer; /* Make it clear it's clickable */
`;

/* Horizontal arrow line with a dot in the middle */
const FactorArrowLine = styled.div`
  position: relative;
  height: 1px;
  width: ${({ width }) => width}px;
  background: linear-gradient(271.12deg, #27CDA7 -7.7%, #2D70E2 110.74%);

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: ${({ side }) => (side === 'left' ? '100%' : '0')};
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: linear-gradient(271.12deg, #27CDA7 -7.7%, #2D70E2 110.74%);
    transform: translate(-50%, -50%);
    border: 2px solid white;
  }
`;

// Label
const FactorLabel = styled.div`
  font-size: 12px;
  font-weight: 700;
  background: #fff;
  color: #2A3039;
  display: flex;
  margin-right: ${({ side }) => (side === 'left' ? '10px' : '0')};
  margin-left: ${({ side }) => (side === 'right' ? '10px' : '0')};
  justify-content: ${({ side }) => (side === 'left' ? 'flex-start' : 'flex-end')};
`;

/*
  Drawer that slides in from the right
*/
const Drawer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  height: 100vh;
  background: #fdfdfd;
  box-shadow: -2px 0 5px rgba(0,0,0,0.3);
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  z-index: 999; /* Ensure it appears on top */

  /* When open, slide into view */
  &.open {
    transform: translateX(0);
  }

  /* Simple padding and overflow if content grows */
  padding: 1rem;
  overflow-y: auto;
`;

// Dummy close button styling
const CloseButton = styled.button`
  background: #eee;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-bottom: 1rem;
  font-weight: bold;
`;

/*
  Left column factors
*/
const leftFactors = [
  { name: 'Clarity',              width: 300, delay: '0.1s' },
  { name: 'Accountability',       width: 320, delay: '0.2s' },
  { name: 'Resilience',           width: 340, delay: '0.3s' },
  { name: 'Vision',               width: 360, delay: '0.4s' },
  { name: 'Impact',               width: 370, delay: '0.5s' },
];

/*
  Right column factors
*/
const rightFactors = [
  { name: 'Psychological Safety', width: 300, delay: '0.6s' },
  { name: 'Empathy',             width: 320, delay: '0.7s' },
  { name: 'Belonging',           width: 340, delay: '0.8s' },
  { name: 'Learning',            width: 360, delay: '0.9s' },
  { name: 'Values',              width: 380, delay: '1.0s' },
];

export default function IcebergPage() {
  // Control whether the drawer is open and which factor is selected
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedFactor, setSelectedFactor] = useState('');

  const handleFactorClick = (factorName) => {
    setSelectedFactor(factorName);
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    setSelectedFactor('');
  };

  return (
    <>
      <Top/>

      <IcebergContainer>
        {/* Top outcomes */}
        <TopOutcomes>
          <OutcomeItem>ENGAGEMENT</OutcomeItem>
          <OutcomeItem>RETENTION</OutcomeItem>
          <OutcomeItem>PRODUCTIVITY</OutcomeItem>
        </TopOutcomes>

        {/* Main iceberg image */}
        <IcebergImage src={Iceberg} alt="Iceberg diagram" />

        {/* (Optional) dotted water line */}
        <WaterLine />

        {/* Overlaid container for below-surface factors */}
        <BelowSurface>
          {/* Left Column */}
          <LeftColumn>
            {leftFactors.map((factor, i) => (
              <FactorRow
                key={i}
                delay={factor.delay}
                onClick={() => handleFactorClick(factor.name)}
              >
                <FactorLabel side="left">{factor.name}</FactorLabel>
                <FactorArrowLine
                  width={factor.width - factor.name.length * 5}
                  side="left"
                />
              </FactorRow>
            ))}
          </LeftColumn>

          {/* Right Column */}
          <RightColumn>
            {rightFactors.map((factor, i) => (
              <FactorRow
                key={i}
                delay={factor.delay}
                onClick={() => handleFactorClick(factor.name)}
              >
                <FactorArrowLine
                  width={factor.width - factor.name.length * 5}
                  side="right"
                />
                <FactorLabel side="right">{factor.name}</FactorLabel>
              </FactorRow>
            ))}
          </RightColumn>
        </BelowSurface>

        {/* The sliding Drawer */}
        <Drawer className={drawerOpen ? 'open' : ''}>
          <CloseButton onClick={closeDrawer}>Close</CloseButton>
          <h2>{selectedFactor || 'Factor'} Details</h2>
          <p>
            This is some dummy content for the <strong>{selectedFactor}</strong> factor.
            You can replace this text with any relevant data, forms, or other
            interactive elements you need.
          </p>
        </Drawer>
      </IcebergContainer>
    </>
  );
}
