import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Icon from "new_components/atoms/Icon";
import Text from "new_components/atoms/Text";
import { ICON_SET } from "new_components/atoms/Icon/IconSet";
import { createPortal } from "react-dom";
import typography from "styles/typography";
import colors from "styles/colors";

// Wrapper for the chat history list
const ChatHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  margin-top: 50px;
  overflow-x: hidden;
`;

const ChatAction = styled.div`
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;

  /* Also, when the action itself is hovered, keep it visible. */
  &:hover {
    opacity: 1;
  }
`;

const ChatHistoryRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  cursor: pointer;
  margin-bottom: 15px;
  color: #2a3039;
  font-family: "Raleway", sans-serif;

  &:hover ${ChatAction}, ${ChatAction}:hover {
    opacity: 1;
  }

  /* DEBUG border if needed */
  /* border: 1px solid red; */
`;

const ActionIcon = styled.div`
  width: 24px;
  height: 24px;
  background-color: #ddd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #bbb;
  }
`;

// Chat name and last updated info
const CardInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
`;

const T2 = styled.div`
  font-size: 12px;
  color: #2a3039;
  margin-bottom: 10px;
  font-weight: 600;
`;

/* Context Menu */
const ContextMenu = styled.div`
  position: absolute;
  top: ${(props) => props.y}px;
  left: ${(props) => props.x}px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 9999; /* Keep above everything else */
  padding: 8px;
`;

const MenuItem = styled.div`
  padding: 6px 10px;
  cursor: pointer;
  &:hover {
    background: #eee;
  }
`;

function formatHistory(history) {
  return history?.map((item) => {
    const name =
      item?.name
        ?.split("_")
        ?.map((word, index) => {
          if (index === 0) {
            return word.charAt(0).toUpperCase() + word.slice(1);
          }
          return word;
        })
        ?.join(" ") + "..." || "Creating new chat...";

    return {
      name: name,
      id: item.id,
      token: item.token,
      employeeId: item.employee_id,
    };
  });
}

const ChatHistory = ({
  onChatSelect,
  activeChatId,
  chatTopics,
  setPendingDelete,
}) => {
  const [chatHistory, setChatHistory] = useState([]);
  // State for context menu
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    chatId: null,
  });

  const menuRef = useRef();

  useEffect(() => {
    // Fetch chat topics from the API
    if (chatTopics?.length > 0) {
      const formattedHistory = formatHistory(chatTopics);
      setChatHistory(formattedHistory);
    }
  }, [chatTopics]);

  // Close menu if user clicks anywhere outside the menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setContextMenu({ ...contextMenu, visible: false });
      }
    };
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [contextMenu]);

  // Handler: open custom context menu on right-click
  const handleContextMenu = (e, chatId) => {
    e.preventDefault(); // stop the native menu
    setContextMenu({
      visible: true,
      x: e.pageX,
      y: e.pageY,
      chatId,
    });
  };

  // Action: delete chat
  const handleDeleteChat = () => {
    const { chatId } = contextMenu;
    // ... perform your delete logic here
    // e.g. call an API or dispatch a Redux action
    setPendingDelete(chatId);
    // Close the menu after delete
    setContextMenu({ ...contextMenu, visible: false });
  };

  return (
    <ChatHistoryWrapper>
      <Text typographyStyle={typography?.caption?.semibold}>Latest</Text>
      <div style={{ height: 10 }} />
      {chatHistory.map((chat) => {
        const isActive = chat.id === activeChatId;

        return (
          <ChatHistoryRow
            key={chat.id}
            isActive={isActive}
            onClick={() => onChatSelect(chat?.id)}
            // onContextMenu={(e) => handleContextMenu(e, chat.id)}
          >
            <CardInfo>
              <Text
                typographyStyle={typography.caption.regular}
                color={isActive ? colors.primary[500] : null}
              >
                {chat.name}
              </Text>
              <ChatAction>
                <ActionIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setPendingDelete(chat?.id);
                  }}
                >
                  <Icon
                    size={20}
                    name={ICON_SET.delete}
                    style={{ cursor: "pointer" }}
                  />
                </ActionIcon>
              </ChatAction>
            </CardInfo>
          </ChatHistoryRow>
        );
      })}
      {contextMenu.visible &&
        createPortal(
          <ContextMenu ref={menuRef} x={contextMenu.x} y={contextMenu.y}>
            <MenuItem onClick={handleDeleteChat}>
              <Icon name={ICON_SET.delete} style={{ marginRight: 8 }} />
              Delete Chat
            </MenuItem>
            {/* Add more actions as you wish */}
          </ContextMenu>,
          document.body
        )}
    </ChatHistoryWrapper>
  );
};

ChatHistory.propTypes = {
  onChatSelect: PropTypes.func.isRequired, // Function to handle chat selection
  activeChatId: PropTypes.number, // ID of the currently active chat
};

ChatHistory.defaultProps = {
  activeChatId: null, // No chat selected by default
};

export default ChatHistory;
