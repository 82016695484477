export const ALL_ERRORS = "ALL_ERRORS";
export const CLEAR_ALL = "CLEAR_ALL";
export const VALIDATION_ERRORS = "VALIDATION_ERRORS";
export const AUTH_ERRORS = "AUTH_ERRORS";
export const SHOW_ERRORS = "SHOW_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SG_CLEAR_ERRORS = "SG_CLEAR_ERRORS";
export const SG_CLEAR_ALL = "SG_CLEAR_ALL";
export const SET_ERROR_STATE = "SET_ERROR_STATE";
//
export const GET_ERRORS = "GET_ERRORS";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
//
export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOAD_REQUEST = "LOAD_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_AMBASSADOR = "REGISTER_AMBASSADOR";
export const REGISTER_USER_TOKEN = "REGISTER_USER_TOKEN";
export const SG_REGISTER_USER_TOKEN = "SG_REGISTER_USER_TOKEN";
// 
export const LOG_ERRORS = "LOG_ERRORS";
export const SG_LOG_ERRORS = "SG_LOG_ERRORS";
//
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const SG_FORGOT_PASSWORD = "SG_FORGOT_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const SG_CHANGE_PASSWORD = "SG_CHANGE_PASSWORD";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const SG_UPDATE_PASSWORD = "SG_UPDATE_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const SG_RESET_PASSWORD = "SG_RESET_PASSWORD";
export const CLEAR_PASSWORD_DATA = "CLEAR_PASSWORD_DATA";
//
//
//
export const SET_ANSWERS = "SET_ANSWERS";
export const GET_ANSWERS = "GET_ANSWERS";
export const LOAD_ANSWERS = "LOAD_ANSWERS"; //saga use this
export const DELETE_ANSWER = "DELETE_ANSWER";
export const PULL_ANSWER = "PULL_ANSWER"; //saga use this
export const FETCH_ANSWER = "FETCH_ANSWER";
export const FETCH_CULTURE_ANSWER = "FETCH_CULTURE_ANSWER";
export const FETCH_MOOD_ANSWER = "FETCH_MOOD_ANSWER";
export const FETCH_LEADERSHIP_ANSWER = "FETCH_LEADERSHIP_ANSWER";
export const FETCH_PERSONALITY_ANSWER = "FETCH_PERSONALITY_ANSWER";
export const UPDATE_ANSWER = "UPDATE_ANSWER"; //saga use this
export const EDIT_ANSWER = "EDIT_ANSWER";
export const ADD_ANSWER = "ADD_ANSWER";
export const ADD_CULTURE_ANSWER = "ADD_CULTURE_ANSWER";
export const ADD_MOOD_ANSWER = "ADD_MOOD_ANSWER";
export const ADD_LEADERSHIP_ANSWER = "ADD_LEADERSHIP_ANSWER";
export const ADD_PERSONALITY_ANSWER = "ADD_PERSONALITY_ANSWER";
export const SAVE_ANSWER = "SAVE_ANSWER"; //saga use this
export const POST_ANSWER = "POST_ANSWER";
export const SUBMIT_ANSWER = "SUBMIT_ANSWER"; //saga use this
export const CLEAR_ANSWER = "CLEAR_ANSWER";

//
export const GET_SURVEY_QUESTIONS = "GET_SURVEY_QUESTIONS";
export const SG_GET_SURVEY_QUESTIONS = "SG_GET_SURVEY_QUESTIONS";
export const FETCH_SURVEY_QUESTION = "FETCH_SURVEY_QUESTION"; //saga use this
export const SG_FETCH_SURVEY_QUESTION = "SG_FETCH_SURVEY_QUESTION"; //saga use this
export const EDIT_SURVEY_QUESTION = "EDIT_SURVEY_QUESTION";
export const SG_EDIT_SURVEY_QUESTION = "SG_EDIT_SURVEY_QUESTION";
export const ADD_SURVEY_QUESTION = "ADD_SURVEY_QUESTION";
export const SG_ADD_SURVEY_QUESTION = "SG_ADD_SURVEY_QUESTION";
export const DELETE_SURVEY_QUESTION = "DELETE_SURVEY_QUESTION";
export const SG_DELETE_SURVEY_QUESTION = "SG_DELETE_SURVEY_QUESTION";
export const CLEAR_SURVEY_QUESTION = "CLEAR_SURVEY_QUESTION";
//
export const LOAD_EMOTION_DEFINITIONS = "LOAD_EMOTION_DEFINITIONS"; //saga use this
export const GET_EMOTION_DEFINITIONS = "GET_EMOTION_DEFINITIONS";
//
export const LOAD_EMOTIONS = "LOAD_EMOTIONS"; //saga use this
export const GET_EMOTIONS = "GET_EMOTIONS";
//
export const GET_MOODS = "GET_MOODS"; //saga use this
export const UPDATE_POSITION = "UPDATE_POSITION"; // only reducer
export const SG_GET_MOODS = "SG_GET_MOODS";
export const FETCH_MOOD = "FETCH_MOOD";
export const SG_FETCH_MOOD = "SG_FETCH_MOOD";
export const GET_MOOD_TEAMS = "GET_MOOD_TEAMS";
export const SG_GET_MOOD_TEAMS = "SG_GET_MOOD_TEAMS";
export const GET_MOOD_ORG = "GET_MOOD_ORG";
export const SG_GET_MOOD_ORG = "SG_GET_MOOD_ORG";
export const GET_MOOD_PER = "GET_MOOD_PER";
export const SG_GET_MOOD_PER = "SG_GET_MOOD_PER";
export const GET_MOOD_PER_CLEAR = "GET_MOOD_PER_CLEAR";
//
export const LOAD_PERSONALITY = "LOAD_PERSONALITY"; //saga use this
export const GET_PERSONALITY = "GET_PERSONALITY";
export const SG_GET_PERSONALITY = "SG_GET_PERSONALITY";
//
export const LOAD_TRAIT_DATA = "LOAD_TRAIT_DATA"; //saga use this
export const GET_TRAIT_DATA = "GET_TRAIT_DATA";
//
export const GET_CULTURE_DATA = "GET_CULTURE_DATA";
export const SG_GET_CULTURE_DATA = "SG_GET_CULTURE_DATA";
export const FETCH_CULTURE_DATA = "FETCH_CULTURE_DATA";
export const SG_FETCH_CULTURE_DATA = "SG_FETCH_CULTURE_DATA";
export const UPDATE_CULTURE_COMMENTS = "UPDATE_CULTURE_COMMENTS";
export const SG_UPDATE_CULTURE_COMMENTS = "SG_UPDATE_CULTURE_COMMENTS";
export const CLEAR_UPDATE_CULTURE_COMMENTS = "CLEAR_UPDATE_CULTURE_COMMENTS";
export const GET_CULTURE_DATA_TEAMS = "GET_CULTURE_DATA_TEAMS";
export const SG_GET_CULTURE_DATA_TEAMS = "SG_GET_CULTURE_DATA_TEAMS";
export const GET_CULTURE_DATA_ORG = "GET_CULTURE_DATA_ORG";
export const SG_GET_CULTURE_DATA_ORG = "SG_GET_CULTURE_DATA_ORG";
//
export const GET_CULTURE_ORG_REPORT = "GET_CULTURE_ORG_REPORT";
export const SG_GET_CULTURE_ORG_REPORT = "SG_GET_CULTURE_ORG_REPORT";
export const FETCH_CULTURE_ORG_REPORT = "FETCH_CULTURE_ORG_REPORT";
export const SG_FETCH_CULTURE_ORG_REPORT = "SG_FETCH_CULTURE_ORG_REPORT";
//
export const GET_MOOD_ORG_REPORT = "GET_MOOD_ORG_REPORT";
export const SG_GET_MOOD_ORG_REPORT = "SG_GET_MOOD_ORG_REPORT";
export const FETCH_MOOD_ORG_REPORT = "FETCH_MOOD_ORG_REPORT";
export const SG_FETCH_MOOD_ORG_REPORT = "SG_FETCH_MOOD_ORG_REPORT";
//
export const GET_LEADERSHIP_DATA = "GET_LEADERSHIP_DATA";
export const SG_GET_LEADERSHIP_DATA = "SG_GET_LEADERSHIP_DATA";
export const FETCH_LEADERSHIP_DATA = "FETCH_LEADERSHIP_DATA";
export const SG_FETCH_LEADERSHIP_DATA = "SG_FETCH_LEADERSHIP_DATA";
export const GET_LEADERSHIP_DATA_TEAMS = "GET_LEADERSHIP_DATA_TEAMS";
export const SG_GET_LEADERSHIP_DATA_TEAMS = "SG_GET_LEADERSHIP_DATA_TEAMS";
export const GET_LEADERSHIP_DATA_ORG = "GET_LEADERSHIP_DATA_ORG";
export const SG_GET_LEADERSHIP_DATA_ORG = "SG_GET_LEADERSHIP_DATA_ORG";
//
export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const LOAD_DEPARTMENTS = "LOAD_DEPARTMENTS"; //saga use this
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const REMOVE_DEPARTMENT = "REMOVE_DEPARTMENT"; //saga use this
export const PULL_DEPARTMENT = "PULL_DEPARTMENT"; //saga use this
export const FETCH_DEPARTMENT = "FETCH_DEPARTMENT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT"; //saga use this
export const EDIT_DEPARTMENT = "EDIT_DEPARTMENT";
export const SAVE_DEPARTMENT = "SAVE_DEPARTMENT";
export const ADD_DEPARTMENT = "ADD_DEPARTMENT"; //saga use this
export const CLEAR_DEPARTMENT = "CLEAR_DEPARTMENT";
//
export const GET_ORGANIZATIONS = "GET_ORGANIZATIONS"; // reducer
export const LOAD_ORGANIZATIONS = "LOAD_ORGANIZATIONS"; //saga use this
export const DELETE_ORGANIZATION = "DELETE_ORGANIZATION";
export const REMOVE_ORGANIZATION = "REMOVE_ORGANIZATION"; //saga use this
export const PULL_ORGANIZATION = "PULL_ORGANIZATION"; //saga use this
export const FETCH_ORGANIZATION = "FETCH_ORGANIZATION";
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION"; //saga use this
export const EDIT_ORGANIZATION = "EDIT_ORGANIZATION";
export const UPDATE_ORGANIZATION_LOGO = "UPDATE_ORGANIZATION_LOGO"; //saga use this
export const EDIT_ORGANIZATION_LOGO = "EDIT_ORGANIZATION_LOGO";
export const SAVE_ORGANIZATION = "SAVE_ORGANIZATION"; //saga use this
export const ADD_ORGANIZATION = "ADD_ORGANIZATION";
export const CLEAR_ORGANIZATION = "CLEAR_ORGANIZATION";
export const VALIDATE_ORGANIZATION = "VALIDATE_ORGANIZATION";
export const SG_VALIDATE_ORGANIZATION = "SG_VALIDATE_ORGANIZATION";
export const CLEAR_VALIDATE_ORGANIZATION = "CLEAR_VALIDATE_ORGANIZATION";

export const GET_INDUSTRY_BENCHMARK = "GET_INDUSTRY_BENCHMARK";
export const SG_GET_INDUSTRY_BENCHMARK = "SG_GET_INDUSTRY_BENCHMARK";
export const CLEAR_INDUSTRY_BENCHMARK = "CLEAR_INDUSTRY_BENCHMARK";
//
export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const LOAD_EMPLOYEES = "LOAD_EMPLOYEES"; //saga use this
export const SEARCH_EMPLOYEES = "SEARCH_EMPLOYEES";
export const SG_SEARCH_EMPLOYEES = "SG_SEARCH_EMPLOYEES"; //saga use this
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const REMOVE_EMPLOYEE = "REMOVE_EMPLOYEE"; //saga use this
export const PULL_EMPLOYEE = "PULL_EMPLOYEE";
export const FETCH_EMPLOYEE = "FETCH_EMPLOYEE"; //saga use this
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE"; //saga use this
export const FETCH_USER_EMPLOYEE = "FETCH_USER_EMPLOYEE";
export const SG_FETCH_USER_EMPLOYEE = "SG_FETCH_USER_EMPLOYEE";
export const SG_UPDATE_EMPLOYEE_AUDIT = "SG_UPDATE_EMPLOYEE_AUDIT"; //saga use this
export const EDIT_EMPLOYEE = "EDIT_EMPLOYEE";
export const UPDATE_EMPLOYEE_PIC = "UPDATE_EMPLOYEE_PIC"; //saga use this
export const EDIT_EMPLOYEE_PIC = "EDIT_EMPLOYEE_PIC";
export const SAVE_EMPLOYEE = "SAVE_EMPLOYEE"; //saga use this
export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const RESET_EMPLOYEE_UPDATED = "RESET_EMPLOYEE_UPDATED";
export const PENDING_EMPLOYEE = "PENDING_EMPLOYEE";
export const REGISTER_EMPLOYEE = "REGISTER_EMPLOYEE"; //saga use this to register
export const UPDATE_EMPLOYEE_STATUS = "UPDATE_EMPLOYEE_STATUS";
export const SG_UPDATE_EMPLOYEE_STATUS = "SG_UPDATE_EMPLOYEE_STATUS"; //saga use this

export const INACTIVE_EMPLOYEES = "INACTIVE_EMPLOYEES";
export const SG_INACTIVE_EMPLOYEES = "SG_INACTIVE_EMPLOYEES"; //saga use this to register

export const EMPLOYEES_LIST = "EMPLOYEES_LIST";
export const SG_EMPLOYEES_LIST = "SG_EMPLOYEES_LIST"; //saga use this to register

export const SG_REGISTER_EMPLOYEE_CULTURE = "SG_REGISTER_EMPLOYEE_CULTURE"; //saga use this to register
export const CLEAR_EMPLOYEE = "CLEAR_EMPLOYEE";

export const VALIDATE_EMPLOYEE = "VALIDATE_EMPLOYEE";
export const SG_VALIDATE_EMPLOYEE = "SG_VALIDATE_EMPLOYEE";
export const CLEAR_VALIDATE_EMPLOYEE = "CLEAR_VALIDATE_EMPLOYEE";

export const VALIDATE_ORG_EMP_EMAIL = "VALIDATE_ORG_EMP_EMAIL";
export const SG_VALIDATE_ORG_EMP_EMAIL = "SG_VALIDATE_ORG_EMP_EMAIL";

export const GET_EMPLOYEE_REPORTS = "GET_EMPLOYEE_REPORTS";
export const SG_GET_EMPLOYEE_REPORTS = "SG_GET_EMPLOYEE_REPORTS"; //saga use this
export const CLEAR_EMPLOYEE_REPORTS = "CLEAR_EMPLOYEE_REPORTS";
//
export const PENDING_EMPLOYEE_PERMISSION = "PENDING_EMPLOYEE_PERMISSION";
export const GET_EMPLOYEE_PERMISSIONS = "GET_EMPLOYEE_PERMISSIONS";
export const SG_GET_EMPLOYEE_PERMISSIONS = "SG_GET_EMPLOYEE_PERMISSIONS"; //saga use this
export const GET_EMPLOYEE_ORG_PERMISSIONS = "GET_EMPLOYEE_ORG_PERMISSIONS";
export const SG_GET_EMPLOYEE_ORG_PERMISSIONS =
  "SG_GET_EMPLOYEE_ORG_PERMISSIONS"; //saga use this
export const DELETE_EMPLOYEE_PERMISSION = "DELETE_EMPLOYEE_PERMISSION";
export const SG_DELETE_EMPLOYEE_PERMISSION = "SG_DELETE_EMPLOYEE_PERMISSION"; //saga use this
export const FETCH_EMPLOYEE_PERMISSION = "FETCH_EMPLOYEE_PERMISSION";
export const SG_FETCH_EMPLOYEE_PERMISSION = "SG_FETCH_EMPLOYEE_PERMISSION"; //saga use this
export const EDIT_EMPLOYEE_PERMISSION = "EDIT_EMPLOYEE_PERMISSION";
export const SG_EDIT_EMPLOYEE_PERMISSION = "SG_EDIT_EMPLOYEE_PERMISSION"; //saga use this
export const ADD_EMPLOYEE_PERMISSION = "ADD_EMPLOYEE_PERMISSION";
export const SG_ADD_EMPLOYEE_PERMISSION = "SG_ADD_EMPLOYEE_PERMISSION"; //saga use this
export const CLEAR_EMPLOYEE_PERMISSION = "CLEAR_EMPLOYEE_PERMISSION";
//

export const GET_TEAMS = "GET_TEAMS";
export const LOAD_TEAMS = "LOAD_TEAMS"; //saga use this
export const DELETE_TEAM = "DELETE_TEAM";
export const REMOVE_TEAM = "REMOVE_TEAM"; //saga use this
export const PULL_TEAM = "PULL_TEAM"; //saga use this
export const FETCH_TEAM = "FETCH_TEAM";
export const UPDATE_TEAM = "UPDATE_TEAM"; //saga use this
export const EDIT_TEAM = "EDIT_TEAM";
export const SAVE_TEAM = "SAVE_TEAM";
export const ADD_TEAM = "ADD_TEAM"; //saga use this
export const CLEAR_TEAM = "CLEAR_TEAM";
export const RESET_CREATED_TEAM = "RESET_CREATED_TEAM";
//
export const GET_TEAM_TYPES = "GET_TEAM_TYPES";
export const SG_GET_TEAM_TYPES = "SG_GET_TEAM_TYPES"; //saga use this
export const DELETE_TEAM_TYPE = "DELETE_TEAM_TYPE";
export const SG_DELETE_TEAM_TYPE = "SG_DELETE_TEAM_TYPE"; //saga use this
export const FETCH_TEAM_TYPE = "FETCH_TEAM_TYPE";
export const SG_FETCH_TEAM_TYPE = "SG_FETCH_TEAM_TYPE"; //saga use this
export const EDIT_TEAM_TYPE = "EDIT_TEAM_TYPE";
export const SG_EDIT_TEAM_TYPE = "SG_EDIT_TEAM_TYPE"; //saga use this
export const ADD_TEAM_TYPE = "ADD_TEAM_TYPE";
export const SG_ADD_TEAM_TYPE = "SG_ADD_TEAM_TYPE"; //saga use this
export const CLEAR_TEAM_TYPE = "CLEAR_TEAM_TYPE";
//
export const GET_EMPLOYEE_ROLES = "GET_EMPLOYEE_ROLES";
export const SG_GET_EMPLOYEE_ROLES = "SG_GET_EMPLOYEE_ROLES"; //saga use this
export const DELETE_EMPLOYEE_ROLE = "DELETE_EMPLOYEE_ROLE";
export const SG_DELETE_EMPLOYEE_ROLE = "SG_DELETE_EMPLOYEE_ROLE"; //saga use this
export const FETCH_EMPLOYEE_ROLE = "FETCH_EMPLOYEE_ROLE";
export const SG_FETCH_EMPLOYEE_ROLE = "SG_FETCH_EMPLOYEE_ROLE"; //saga use this
export const EDIT_EMPLOYEE_ROLE = "EDIT_EMPLOYEE_ROLE";
export const SG_EDIT_EMPLOYEE_ROLE = "SG_EDIT_EMPLOYEE_ROLE"; //saga use this
export const ADD_EMPLOYEE_ROLE = "ADD_EMPLOYEE_ROLE";
export const SG_ADD_EMPLOYEE_ROLE = "SG_ADD_EMPLOYEE_ROLE"; //saga use this
export const CLEAR_EMPLOYEE_ROLE = "CLEAR_EMPLOYEE_ROLE";
export const GET_PRICES = "GET_PRICES";
export const SG_GET_PRICES = "SG_GET_PRICES";
export const FETCH_PRICE = "FETCH_PRICE";
export const SG_FETCH_PRICE = "SG_FETCH_PRICE";
export const CLEAR_PRICE = "CLEAR_PRICE";
//
export const GET_PAYMENTS = "GET_PAYMENTS";
export const LOAD_PAYMENTS = "LOAD_PAYMENTS"; //saga use this
export const DELETE_PAYMENT = "DELETE_PAYMENT";
export const REMOVE_PAYMENT = "REMOVE_PAYMENT"; //saga use this

export const PULL_PAYMENT = "PULL_PAYMENT"; //saga use this
export const FETCH_PAYMENT = "FETCH_PAYMENT";

export const NEXT_INVOICE = "NEXT_INVOICE";
export const SG_NEXT_INVOICE = "SG_NEXT_INVOICE"; //saga use this

export const GET_STRIPE_TAX_RATES = "GET_STRIPE_TAX_RATES";
export const SG_GET_STRIPE_TAX_RATES = "SG_GET_STRIPE_TAX_RATES"; //saga use this

export const GET_STRIPE_PAYMENT_METHODS = "GET_STRIPE_PAYMENT_METHODS";
export const SG_GET_STRIPE_PAYMENT_METHODS = "SG_GET_STRIPE_PAYMENT_METHODS"; //saga use this

export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS";
export const SG_GET_PAYMENT_METHODS = "SG_GET_PAYMENT_METHODS"; //saga use this
// export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
// export const SG_UPDATE_PAYMENT_METHOD = "SG_UPDATE_PAYMENT_METHOD";//saga use this
export const DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD";
export const SG_DELETE_PAYMENT_METHOD = "SG_GET_DELETE_PAYMENT_METHOD"; //saga use this
export const CREATE_PAYMENT_METHOD = "CREATE_PAYMENT_METHOD";
export const SG_CREATE_PAYMENT_METHOD = "SG_CREATE_PAYMENT_METHOD"; //saga use this
//

export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const SG_CANCEL_SUBSCRIPTION = "SG_CANCEL_SUBSCRIPTION"; //saga use this

export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const SG_UPDATE_SUBSCRIPTION = "SG_UPDATE_SUBSCRIPTION"; //saga use this

export const UPGRADE_PLAN = "UPGRADE_PLAN";
export const SG_UPGRADE_PLAN = "SG_UPGRADE_PLAN"; //saga use this

export const DOWNGRADE_PLAN = "DOWNGRADE_PLAN";
export const SG_DOWNGRADE_PLAN = "SG_DOWNGRADE_PLAN"; //saga use this

export const UPDATE_PAYMENT = "UPDATE_PAYMENT"; //saga use this
export const EDIT_PAYMENT = "EDIT_PAYMENT";

export const SAVE_PAYMENT = "SAVE_PAYMENT"; //saga use this
export const ADD_PAYMENT = "ADD_PAYMENT";

export const CREATE_PAYMENT_INTENT = "CREATE_PAYMENT_INTENT";
export const SG_CREATE_PAYMENT_INTENT = "SG_CREATE_PAYMENT_INTENT"; //saga use this

export const CLEAR_PAYMENT = "CLEAR_PAYMENT";
//
export const PENDING_GLOBAL_SCHEDULES = "PENDING_GLOBAL_SCHEDULES";
export const UPDATED_GLOBAL_SCHEDULES = "UPDATED_GLOBAL_SCHEDULES";
export const REFRESHED_GLOBAL_SCHEDULES = "REFRESHED_GLOBAL_SCHEDULES";

export const GET_GLOBAL_SCHEDULES = "GET_GLOBAL_SCHEDULES";
export const SG_GET_GLOBAL_SCHEDULES = "SG_GET_GLOBAL_SCHEDULES"; //saga use this

export const DELETE_GLOBAL_SCHEDULE = "DELETE_GLOBAL_SCHEDULE";
export const SG_DELETE_GLOBAL_SCHEDULE = "SG_DELETE_GLOBAL_SCHEDULE"; //saga use this

export const SG_FETCH_GLOBAL_SCHEDULE = "SG_FETCH_GLOBAL_SCHEDULE"; //saga use this
export const FETCH_GLOBAL_SCHEDULE = "FETCH_GLOBAL_SCHEDULE";

export const SG_EDIT_GLOBAL_SCHEDULE = "SG_EDIT_GLOBAL_SCHEDULE"; //saga use this
export const EDIT_GLOBAL_SCHEDULE = "EDIT_GLOBAL_SCHEDULE";

export const SG_ADD_GLOBAL_SCHEDULE = "SG_ADD_GLOBAL_SCHEDULE"; //saga use this
export const ADD_GLOBAL_SCHEDULE = "ADD_GLOBAL_SCHEDULE";

export const CLEAR_GLOBAL_SCHEDULE = "CLEAR_GLOBAL_SCHEDULE";
export const SG_CLEAR_GLOBAL_SCHEDULE = "SG_CLEAR_GLOBAL_SCHEDULE";
//
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";
export const SG_GET_SUBSCRIPTIONS = "SG_GET_SUBSCRIPTIONS"; //saga use this

export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
export const SG_DELETE_SUBSCRIPTION = "SG_DELETE_SUBSCRIPTION"; //saga use this

export const SG_FETCH_SUBSCRIPTION = "SG_FETCH_SUBSCRIPTION"; //saga use this
export const FETCH_SUBSCRIPTION = "FETCH_SUBSCRIPTION";

export const SG_EDIT_SUBSCRIPTION = "SG_EDIT_SUBSCRIPTION"; //saga use this
export const EDIT_SUBSCRIPTION = "EDIT_SUBSCRIPTION";

export const SG_ADD_SUBSCRIPTION = "SG_ADD_SUBSCRIPTION"; //saga use this
export const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION";

export const CLEAR_SUBSCRIPTION = "CLEAR_SUBSCRIPTION";
export const SG_CLEAR_SUBSCRIPTION = "SG_CLEAR_SUBSCRIPTION";
//
export const GET_GLOBAL_SCHEDULE_FREQUENCIES =
  "GET_GLOBAL_SCHEDULE_FREQUENCIES";
export const SG_GET_GLOBAL_SCHEDULE_FREQUENCIES =
  "SG_GET_GLOBAL_SCHEDULE_FREQUENCIES"; //saga use this

export const DELETE_GLOBAL_SCHEDULE_FREQUENCY =
  "DELETE_GLOBAL_SCHEDULE_FREQUENCY";
export const SG_DELETE_GLOBAL_SCHEDULE_FREQUENCY =
  "SG_DELETE_GLOBAL_SCHEDULE_FREQUENCY"; //saga use this

export const SG_FETCH_GLOBAL_SCHEDULE_FREQUENCY =
  "SG_FETCH_GLOBAL_SCHEDULE_FREQUENCY"; //saga use this
export const FETCH_GLOBAL_SCHEDULE_FREQUENCY =
  "FETCH_GLOBAL_SCHEDULE_FREQUENCY";

export const SG_EDIT_GLOBAL_SCHEDULE_FREQUENCY =
  "SG_EDIT_GLOBAL_SCHEDULE_FREQUENCY"; //saga use this
export const EDIT_GLOBAL_SCHEDULE_FREQUENCY = "EDIT_GLOBAL_SCHEDULE_FREQUENCY";

export const SG_ADD_GLOBAL_SCHEDULE_FREQUENCY =
  "SG_ADD_GLOBAL_SCHEDULE_FREQUENCY"; //saga use this
export const ADD_GLOBAL_SCHEDULE_FREQUENCY = "ADD_GLOBAL_SCHEDULE_FREQUENCY";

export const CLEAR_GLOBAL_SCHEDULE_FREQUENCY =
  "CLEAR_GLOBAL_SCHEDULE_FREQUENCY";
export const SG_CLEAR_GLOBAL_SCHEDULE_FREQUENCY =
  "SG_CLEAR_GLOBAL_SCHEDULE_FREQUENCY";
//
export const GET_SURVEY_COMPLIANCES = "GET_SURVEY_COMPLIANCES";
export const SG_GET_SURVEY_COMPLIANCES = "SG_GET_SURVEY_COMPLIANCES"; //saga use this

export const DELETE_SURVEY_COMPLIANCE = "DELETE_SURVEY_COMPLIANCE";
export const SG_DELETE_SURVEY_COMPLIANCE = "SG_DELETE_SURVEY_COMPLIANCE"; //saga use this

export const SG_FETCH_SURVEY_COMPLIANCE = "SG_FETCH_SURVEY_COMPLIANCE"; //saga use this
export const FETCH_SURVEY_COMPLIANCE = "FETCH_SURVEY_COMPLIANCE";

export const SG_EDIT_SURVEY_COMPLIANCE = "SG_EDIT_SURVEY_COMPLIANCE"; //saga use this
export const EDIT_SURVEY_COMPLIANCE = "EDIT_SURVEY_COMPLIANCE";

export const SG_ADD_SURVEY_COMPLIANCE = "SG_ADD_SURVEY_COMPLIANCE"; //saga use this
export const ADD_SURVEY_COMPLIANCE = "ADD_SURVEY_COMPLIANCE";

export const CLEAR_SURVEY_COMPLIANCE = "CLEAR_SURVEY_COMPLIANCE";
export const SG_CLEAR_SURVEY_COMPLIANCE = "SG_CLEAR_SURVEY_COMPLIANCE";
//
export const GET_TEAMS_IN_SCHEDULES = "GET_TEAMS_IN_SCHEDULES";
export const SG_GET_TEAMS_IN_SCHEDULES = "SG_GET_TEAMS_IN_SCHEDULES"; //saga use this

export const DELETE_TEAMS_IN_SCHEDULE = "DELETE_TEAMS_IN_SCHEDULE";
export const SG_DELETE_TEAMS_IN_SCHEDULE = "SG_DELETE_TEAMS_IN_SCHEDULE"; //saga use this

export const SG_FETCH_TEAMS_IN_SCHEDULE = "SG_FETCH_TEAMS_IN_SCHEDULE"; //saga use this
export const FETCH_TEAMS_IN_SCHEDULE = "FETCH_TEAMS_IN_SCHEDULE";

export const SG_EDIT_TEAMS_IN_SCHEDULE = "SG_EDIT_TEAMS_IN_SCHEDULE"; //saga use this
export const EDIT_TEAMS_IN_SCHEDULE = "EDIT_TEAMS_IN_SCHEDULE";

export const SG_ADD_TEAMS_IN_SCHEDULE = "SG_ADD_TEAMS_IN_SCHEDULE"; //saga use this
export const ADD_TEAMS_IN_SCHEDULE = "ADD_TEAMS_IN_SCHEDULE";

export const CLEAR_TEAMS_IN_SCHEDULE = "CLEAR_TEAMS_IN_SCHEDULE";
export const SG_CLEAR_TEAMS_IN_SCHEDULE = "SG_CLEAR_TEAMS_IN_SCHEDULE";
//
export const GET_LEADERS_IN_SCHEDULES = "GET_LEADERS_IN_SCHEDULES";
export const SG_GET_LEADERS_IN_SCHEDULES = "SG_GET_LEADERS_IN_SCHEDULES"; //saga use this

export const DELETE_LEADERS_IN_SCHEDULE = "DELETE_LEADERS_IN_SCHEDULE";
export const SG_DELETE_LEADERS_IN_SCHEDULE = "SG_DELETE_LEADERS_IN_SCHEDULE"; //saga use this

export const SG_FETCH_LEADERS_IN_SCHEDULE = "SG_FETCH_LEADERS_IN_SCHEDULE"; //saga use this
export const FETCH_LEADERS_IN_SCHEDULE = "FETCH_LEADERS_IN_SCHEDULE";

export const SG_EDIT_LEADERS_IN_SCHEDULE = "SG_EDIT_LEADERS_IN_SCHEDULE"; //saga use this
export const EDIT_LEADERS_IN_SCHEDULE = "EDIT_LEADERS_IN_SCHEDULE";

export const SG_ADD_LEADERS_IN_SCHEDULE = "SG_ADD_LEADERS_IN_SCHEDULE"; //saga use this
export const ADD_LEADERS_IN_SCHEDULE = "ADD_LEADERS_IN_SCHEDULE";

export const CLEAR_LEADERS_IN_SCHEDULE = "CLEAR_LEADERS_IN_SCHEDULE";
export const SG_CLEAR_LEADERS_IN_SCHEDULE = "SG_CLEAR_LEADERS_IN_SCHEDULE";
//
export const GET_LEADER_SURVEY_PARTICIPANTS = "GET_LEADER_SURVEY_PARTICIPANTS";
export const SG_GET_LEADER_SURVEY_PARTICIPANTS =
  "SG_GET_LEADER_SURVEY_PARTICIPANTS"; //saga use this

export const DELETE_LEADER_SURVEY_PARTICIPANT =
  "DELETE_LEADER_SURVEY_PARTICIPANT";
export const SG_DELETE_LEADER_SURVEY_PARTICIPANT =
  "SG_DELETE_LEADER_SURVEY_PARTICIPANT"; //saga use this

export const SG_FETCH_LEADER_SURVEY_PARTICIPANT =
  "SG_FETCH_LEADER_SURVEY_PARTICIPANT"; //saga use this
export const FETCH_LEADER_SURVEY_PARTICIPANT =
  "FETCH_LEADER_SURVEY_PARTICIPANT";

export const SG_EDIT_LEADER_SURVEY_PARTICIPANT =
  "SG_EDIT_LEADER_SURVEY_PARTICIPANT"; //saga use this
export const EDIT_LEADER_SURVEY_PARTICIPANT = "EDIT_LEADER_SURVEY_PARTICIPANT";

export const SG_ADD_LEADER_SURVEY_PARTICIPANT =
  "SG_ADD_LEADER_SURVEY_PARTICIPANT"; //saga use this
export const ADD_LEADER_SURVEY_PARTICIPANT = "ADD_LEADER_SURVEY_PARTICIPANT";

export const CLEAR_LEADER_SURVEY_PARTICIPANT =
  "CLEAR_LEADER_SURVEY_PARTICIPANT";
export const SG_CLEAR_LEADER_SURVEY_PARTICIPANT =
  "SG_CLEAR_LEADER_SURVEY_PARTICIPANT";
//
export const GET_PERSONALITY_TEAMS = "GET_PERSONALITY_TEAMS";
export const SG_GET_PERSONALITY_TEAMS = "SG_GET_PERSONALITY_TEAMS"; //saga use this

export const DELETE_PERSONALITY_TEAM = "DELETE_PERSONALITY_TEAM";
export const SG_DELETE_PERSONALITY_TEAM = "SG_DELETE_PERSONALITY_TEAM"; //saga use this

export const SG_FETCH_PERSONALITY_TEAM = "SG_FETCH_PERSONALITY_TEAM"; //saga use this
export const FETCH_PERSONALITY_TEAM = "FETCH_PERSONALITY_TEAM";

export const SG_EDIT_PERSONALITY_TEAM = "SG_EDIT_PERSONALITY_TEAM"; //saga use this
export const EDIT_PERSONALITY_TEAM = "EDIT_PERSONALITY_TEAM";

export const SG_ADD_PERSONALITY_TEAM = "SG_ADD_PERSONALITY_TEAM"; //saga use this
export const ADD_PERSONALITY_TEAM = "ADD_PERSONALITY_TEAM";

export const CLEAR_PERSONALITY_TEAM = "CLEAR_PERSONALITY_TEAM";
export const SG_CLEAR_PERSONALITY_TEAM = "SG_CLEAR_PERSONALITY_TEAM";
//
export const GET_PERSONALITY_PARTICIPANTS = "GET_PERSONALITY_PARTICIPANTS";
export const SG_GET_PERSONALITY_PARTICIPANTS =
  "SG_GET_PERSONALITY_PARTICIPANTS"; //saga use this

export const DELETE_PERSONALITY_PARTICIPANT = "DELETE_PERSONALITY_PARTICIPANT";
export const SG_DELETE_PERSONALITY_PARTICIPANT =
  "SG_DELETE_PERSONALITY_PARTICIPANT"; //saga use this

export const SG_FETCH_PERSONALITY_PARTICIPANT =
  "SG_FETCH_PERSONALITY_PARTICIPANT"; //saga use this
export const FETCH_PERSONALITY_PARTICIPANT = "FETCH_PERSONALITY_PARTICIPANT";

export const SG_EDIT_PERSONALITY_PARTICIPANT =
  "SG_EDIT_PERSONALITY_PARTICIPANT"; //saga use this
export const EDIT_PERSONALITY_PARTICIPANT = "EDIT_PERSONALITY_PARTICIPANT";

export const SG_ADD_PERSONALITY_PARTICIPANT = "SG_ADD_PERSONALITY_PARTICIPANT"; //saga use this
export const ADD_PERSONALITY_PARTICIPANT = "ADD_PERSONALITY_PARTICIPANT";

export const CLEAR_PERSONALITY_PARTICIPANT = "CLEAR_PERSONALITY_PARTICIPANT";
export const SG_CLEAR_PERSONALITY_PARTICIPANT =
  "SG_CLEAR_PERSONALITY_PARTICIPANT";
//
export const GET_SIGNUP_TOKENS = "GET_SIGNUP_TOKENS";
export const SG_GET_SIGNUP_TOKENS = "SG_GET_SIGNUP_TOKENS"; //saga use this

export const DELETE_SIGNUP_TOKEN = "DELETE_SIGNUP_TOKEN";
export const SG_DELETE_SIGNUP_TOKEN = "SG_DELETE_SIGNUP_TOKEN"; //saga use this

export const SG_ADD_SIGNUP_TOKEN = "SG_ADD_SIGNUP_TOKEN"; //saga use this
export const ADD_SIGNUP_TOKEN = "ADD_SIGNUP_TOKEN";

export const CLEAR_SIGNUP_TOKEN = "CLEAR_SIGNUP_TOKEN";
export const SG_CLEAR_SIGNUP_TOKEN = "SG_CLEAR_SIGNUP_TOKEN";
//
export const GET_SURVEY_TOKENS = "GET_SURVEY_TOKENS";
export const SG_GET_SURVEY_TOKENS = "SG_GET_SURVEY_TOKENS"; //saga use this

export const DELETE_SURVEY_TOKEN = "DELETE_SURVEY_TOKEN";
export const SG_DELETE_SURVEY_TOKEN = "SG_DELETE_SURVEY_TOKEN"; //saga use this

export const SG_ADD_SURVEY_TOKEN = "SG_ADD_SURVEY_TOKEN"; //saga use this
export const ADD_SURVEY_TOKEN = "ADD_SURVEY_TOKEN";

export const CLEAR_SURVEY_TOKEN = "CLEAR_SURVEY_TOKEN";
export const SG_CLEAR_SURVEY_TOKEN = "SG_CLEAR_SURVEY_TOKEN";
//
export const GET_SURVEY_RESPONSES = "GET_SURVEY_RESPONSES";
export const SG_GET_SURVEY_RESPONSES = "SG_GET_SURVEY_RESPONSES"; //saga use this

export const DELETE_SURVEY_RESPONSE = "DELETE_SURVEY_RESPONSE";
export const SG_DELETE_SURVEY_RESPONSE = "SG_DELETE_SURVEY_RESPONSE"; //saga use this

export const SG_ADD_SURVEY_RESPONSE = "SG_ADD_SURVEY_RESPONSE"; //saga use this
export const ADD_SURVEY_RESPONSE = "ADD_SURVEY_RESPONSE";

export const CLEAR_SURVEY_RESPONSE = "CLEAR_SURVEY_RESPONSE";
export const SG_CLEAR_SURVEY_RESPONSE = "SG_CLEAR_SURVEY_RESPONSE";
//
export const SIGNUP_HOME = "SIGNUP_HOME";
export const SIGNUP_DEPARTMENT = "SIGNUP_DEPARTMENT";
export const SIGNUP_TEAM = "SIGNUP_TEAM";
export const SIGNUP_EMPLOYEE = "SIGNUP_EMPLOYEE";
//
export const VALIDATE_EMAIL_PENDING = "VALIDATE_EMAIL_PENDING";
export const SG_VALIDATE_EMAIL = "SG_VALIDATE_EMAIL"; //saga use this
export const VALIDATE_EMAIL = "VALIDATE_EMAIL";
export const SG_VALIDATE_EMAIL_LIST = "SG_VALIDATE_EMAIL_LIST"; //saga use this
export const VALIDATE_EMAIL_LIST = "VALIDATE_EMAIL_LIST";
export const CLEAR_VALIDATE_EMAIL = "CLEAR_VALIDATE_EMAIL";
export const SG_CLEAR_VALIDATE_EMAIL = "SG_CLEAR_VALIDATE_EMAIL";
export const SG_VALIDATE_TOKEN = "SG_VALIDATE_TOKEN"; //saga use this
export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
export const CLEAR_VALIDATE_TOKEN = "CLEAR_VALIDATE_TOKEN";
export const SG_CLEAR_VALIDATE_TOKEN = "SG_CLEAR_VALIDATE_TOKEN";
export const SG_PRE_VALIDATE_EMAIL = "SG_PRE_VALIDATE_EMAIL"; //saga use this
export const PRE_VALIDATE_EMAIL = "PRE_VALIDATE_EMAIL";
export const CLEAR_PRE_VALIDATE_EMAIL = "CLEAR_PRE_VALIDATE_EMAIL";
export const SG_CLEAR_PRE_VALIDATE_EMAIL = "SG_CLEAR_PRE_VALIDATE_EMAIL";
//
export const GET_ONBOARDINGS = "GET_ONBOARDINGS";
export const SG_GET_ONBOARDINGS = "SG_GET_ONBOARDINGS"; //saga use this

export const DELETE_ONBOARDING = "DELETE_ONBOARDING";
export const SG_DELETE_ONBOARDING = "SG_DELETE_ONBOARDING"; //saga use this

export const SG_ADD_ONBOARDING = "SG_ADD_ONBOARDING"; //saga use this
export const ADD_ONBOARDING = "ADD_ONBOARDING";

export const SG_FETCH_ONBOARDING = "SG_FETCH_ONBOARDING"; //saga use this
export const FETCH_ONBOARDING = "FETCH_ONBOARDING";

export const SG_EDIT_ONBOARDING = "SG_EDIT_ONBOARDING"; //saga use this
export const EDIT_ONBOARDING = "EDIT_ONBOARDING";

export const CLEAR_ONBOARDING = "CLEAR_ONBOARDING";
export const SG_CLEAR_ONBOARDING = "SG_CLEAR_ONBOARDING";
//
export const SG_EMAIL_DELIVERY_STATUS = "SG_EMAIL_DELIVERY_STATUS"; //saga use this
export const EMAIL_DELIVERY_STATUS = "EMAIL_DELIVERY_STATUS";

export const SG_FETCH_EMAIL_DELIVERY_STATUS = "SG_FETCH_EMAIL_DELIVERY_STATUS"; //saga use this
export const FETCH_EMAIL_DELIVERY_STATUS = "FETCH_EMAIL_DELIVERY_STATUS";

export const CLEAR_EMAIL_DELIVERY_STATUS = "CLEAR_EMAIL_DELIVERY_STATUS";
export const SG_CLEAR_EMAIL_DELIVERY_STATUS = "SG_CLEAR_EMAIL_DELIVERY_STATUS";
//
export const GET_AMBASSADORS = "GET_AMBASSADORS";
export const SG_GET_AMBASSADORS = "SG_GET_AMBASSADORS"; //saga use this
export const DELETE_AMBASSADOR = "DELETE_AMBASSADOR";
export const SG_DELETE_AMBASSADOR = "SG_DELETE_AMBASSADOR"; //saga use this
export const FETCH_AMBASSADOR = "FETCH_AMBASSADOR";
export const SG_FETCH_AMBASSADOR = "SG_FETCH_AMBASSADOR"; //saga use this
export const EDIT_AMBASSADOR = "EDIT_AMBASSADOR";
export const SG_EDIT_AMBASSADOR = "SG_EDIT_AMBASSADOR"; //saga use this
export const ADD_AMBASSADOR = "ADD_AMBASSADOR";
export const SG_ADD_AMBASSADOR = "SG_ADD_AMBASSADOR"; //saga use this
export const UPDATE_AMBASSADOR_PIC = "UPDATE_AMBASSADOR_PIC";
export const SG_UPDATE_AMBASSADOR_PIC = "SG_UPDATE_AMBASSADOR_PIC";
export const CLEAR_AMBASSADOR = "CLEAR_AMBASSADOR";
export const VALIDATE_AMBASSADOR = "VALIDATE_AMBASSADOR";
export const SG_VALIDATE_AMBASSADOR = "SG_VALIDATE_AMBASSADOR";
//
export const GET_AM_ORGANIZATION = "GET_AM_ORGANIZATION";
export const SET_AUTH_ORGANIZATION = "SET_AUTH_ORGANIZATION";
export const SET_AM_MENU = "SET_AM_MENU";
export const GET_AM_TEAM = "GET_AM_TEAM";
export const GET_AM_EMPLOYEE = "GET_AM_EMPLOYEE";
export const ROUTE_PATH = "ROUTE_PATH";
// export const GET_AM_ACCOUNT = "GET_AM_ACCOUNT";
export const CLEAR_AM = "CLEAR_AM";
//
export const CLEAR_SURVEY_SETTING_LIST = "CLEAR_SURVEY_SETTING_LIST";
export const SG_CLEAR_SURVEY_SETTING_LIST = "SG_CLEAR_ONBOARDING";
export const SG_GET_SURVEY_SETTING_LIST = "SG_GET_SURVEY_SETTING_LIST"; //saga use this
export const GET_SURVEY_SETTING_LIST = "GET_SURVEY_SETTING_LIST";
export const SG_UPDATE_SURVEY_SETTING_LIST = "SG_UPDATE_SURVEY_SETTING_LIST"; //saga use this
export const UPDATE_SURVEY_SETTING_LIST = "UPDATE_SURVEY_SETTING_LIST";
export const SG_DELETE_SURVEY_SETTING_LIST = "SG_DELETE_SURVEY_SETTING_LIST"; //saga use this
export const DELETE_SURVEY_SETTING_LIST = "DELETE_SURVEY_SETTING_LIST";
//
//
export const GET_TEAM_ACCESSS = "GET_TEAM_ACCESSS";
export const SG_GET_TEAM_ACCESSS = "SG_GET_TEAM_ACCESSS"; //saga use this
export const DELETE_TEAM_ACCESS = "DELETE_TEAM_ACCESS";
export const SG_DELETE_TEAM_ACCESS = "SG_DELETE_TEAM_ACCESS"; //saga use this
export const FETCH_TEAM_ACCESS = "FETCH_TEAM_ACCESS";
export const SG_FETCH_TEAM_ACCESS = "SG_FETCH_TEAM_ACCESS"; //saga use this
export const EDIT_TEAM_ACCESS = "EDIT_TEAM_ACCESS";
export const SG_EDIT_TEAM_ACCESS = "SG_EDIT_TEAM_ACCESS"; //saga use this
export const ADD_TEAM_ACCESS = "ADD_TEAM_ACCESS";
export const SG_ADD_TEAM_ACCESS = "SG_ADD_TEAM_ACCESS"; //saga use this
export const CLEAR_TEAM_ACCESS = "CLEAR_TEAM_ACCESS";
//

export const DEBRIEF_SCHEDULES_PENDING = "DEBRIEF_SCHEDULES_PENDING";
export const GET_DEBRIEF_SCHEDULES = "GET_DEBRIEF_SCHEDULES";
export const SG_GET_DEBRIEF_SCHEDULES = "SG_GET_DEBRIEF_SCHEDULES"; //saga use this
export const FIND_DEBRIEF_SCHEDULE = "FIND_DEBRIEF_SCHEDULE";
export const SG_FIND_DEBRIEF_SCHEDULE = "SG_FIND_DEBRIEF_SCHEDULE"; //saga use this

export const DELETE_DEBRIEF_SCHEDULE = "DELETE_DEBRIEF_SCHEDULE";
export const SG_DELETE_DEBRIEF_SCHEDULE = "SG_DELETE_DEBRIEF_SCHEDULE"; //saga use this
export const SELECTED_DEBRIEF_SCHEDULE = "SELECTED_DEBRIEF_SCHEDULE";
export const RE_SELECTED_DEBRIEF_SCHEDULE = "RE_SELECTED_DEBRIEF_SCHEDULE";
export const FETCH_DEBRIEF_SCHEDULE = "FETCH_DEBRIEF_SCHEDULE";
export const SG_FETCH_DEBRIEF_SCHEDULE = "SG_FETCH_DEBRIEF_SCHEDULE"; //saga use this
export const EDIT_DEBRIEF_SCHEDULE = "EDIT_DEBRIEF_SCHEDULE";
export const SG_EDIT_DEBRIEF_SCHEDULE = "SG_EDIT_DEBRIEF_SCHEDULE"; //saga use this
export const ADD_NEW_DEBRIEF_SCHEDULE = "ADD_NEW_DEBRIEF_SCHEDULE";
export const SG_ADD_DEBRIEF_SCHEDULE = "SG_ADD_DEBRIEF_SCHEDULE"; //saga use this
export const GET_ACTIVE_PAGE_DEBRIEF_SCHEDULE =
  "GET_ACTIVE_PAGE_DEBRIEF_SCHEDULE";
export const ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE =
  "ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE";
export const RESET_ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE =
  "RESET_ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE";
export const CLEAR_DEBRIEF_SCHEDULE = "CLEAR_DEBRIEF_SCHEDULE";
// new
export const CULTURE_AUDIT_REPORT_PENDING = "CULTURE_AUDIT_REPORT_PENDING";
export const GET_CULTURE_AUDIT_REPORTS = "GET_CULTURE_AUDIT_REPORTS";
export const SG_GET_CULTURE_AUDIT_REPORTS = "SG_GET_CULTURE_AUDIT_REPORTS"; //saga use this
export const CLEAR_CULTURE_AUDIT_REPORT = "CLEAR_CULTURE_AUDIT_REPORT";
//
export const GET_AUDIT_ELIGIBLE = "GET_AUDIT_ELIGIBLE";
export const SG_GET_AUDIT_ELIGIBLE = "SG_GET_AUDIT_ELIGIBLE"; //saga use this
export const ADD_USER_TO_SURVEY = "ADD_USER_TO_SURVEY";
export const SG_ADD_USER_TO_SURVEY = "SG_ADD_USER_TO_SURVEY"; //saga use this
export const EXTEND_SURVEY_TIME = "EXTEND_SURVEY_TIME";
export const SG_EXTEND_SURVEY_TIME = "SG_EXTEND_SURVEY_TIME"; //saga use this

//
export const GET_DEBRIEF_RESPONSES = "GET_DEBRIEF_RESPONSES";
export const SG_GET_DEBRIEF_RESPONSES = "SG_GET_DEBRIEF_RESPONSES"; //saga use this
export const DELETE_DEBRIEF_RESPONSE = "DELETE_DEBRIEF_RESPONSE";
export const SG_DELETE_DEBRIEF_RESPONSE = "SG_DELETE_DEBRIEF_RESPONSE"; //saga use this
export const FETCH_DEBRIEF_RESPONSE = "FETCH_DEBRIEF_RESPONSE";
export const SG_FETCH_DEBRIEF_RESPONSE = "SG_FETCH_DEBRIEF_RESPONSE"; //saga use this
export const EDIT_DEBRIEF_RESPONSE = "EDIT_DEBRIEF_RESPONSE";
export const SG_EDIT_DEBRIEF_RESPONSE = "SG_EDIT_DEBRIEF_RESPONSE"; //saga use this
export const ADD_DEBRIEF_RESPONSE = "ADD_DEBRIEF_RESPONSE";
export const SG_ADD_DEBRIEF_RESPONSE = "SG_ADD_DEBRIEF_RESPONSE"; //saga use this
export const CLEAR_DEBRIEF_RESPONSE = "CLEAR_DEBRIEF_RESPONSE";
//
export const GET_DEBRIEF_TOKENS = "GET_DEBRIEF_TOKENS";
export const SG_GET_DEBRIEF_TOKENS = "SG_GET_DEBRIEF_TOKENS"; //saga use this
export const DELETE_DEBRIEF_TOKEN = "DELETE_DEBRIEF_TOKEN";
export const SG_DELETE_DEBRIEF_TOKEN = "SG_DELETE_DEBRIEF_TOKEN"; //saga use this
export const FETCH_DEBRIEF_TOKEN = "FETCH_DEBRIEF_TOKEN";
export const SG_FETCH_DEBRIEF_TOKEN = "SG_FETCH_DEBRIEF_TOKEN"; //saga use this
export const EDIT_DEBRIEF_TOKEN = "EDIT_DEBRIEF_TOKEN";
export const SG_EDIT_DEBRIEF_TOKEN = "SG_EDIT_DEBRIEF_TOKEN"; //saga use this
export const ADD_DEBRIEF_TOKEN = "ADD_DEBRIEF_TOKEN";
export const SG_ADD_DEBRIEF_TOKEN = "SG_ADD_DEBRIEF_TOKEN"; //saga use this
export const CLEAR_DEBRIEF_TOKEN = "CLEAR_DEBRIEF_TOKEN";
//
export const SIGNUP_TOKEN = "SIGNUP_TOKEN";
export const SG_SIGNUP_TOKEN = "SG_SIGNUP_TOKEN"; //saga use this
//
export const GET_SURVEY_STRUCTURES = "GET_SURVEY_STRUCTURES";
export const SG_GET_SURVEY_STRUCTURES = "SG_GET_SURVEY_STRUCTURES"; //saga use this
export const SURVEY_STRUCTURE_SELECT = "SURVEY_STRUCTURE_SELECT";
export const SG_SURVEY_STRUCTURE_SELECT = "SG_SURVEY_STRUCTURE_SELECT"; //saga use this
export const COPY_SURVEY_STRUCTURE = "COPY_SURVEY_STRUCTURE";
export const SG_COPY_SURVEY_STRUCTURE = "SG_COPY_SURVEY_STRUCTURE"; //saga use this
export const DELETE_SURVEY_STRUCTURE = "DELETE_SURVEY_STRUCTURE";
export const SG_DELETE_SURVEY_STRUCTURE = "SG_DELETE_SURVEY_STRUCTURE"; //saga use this
export const FETCH_SURVEY_STRUCTURE = "FETCH_SURVEY_STRUCTURE";
export const SG_FETCH_SURVEY_STRUCTURE = "SG_FETCH_SURVEY_STRUCTURE"; //saga use this
export const EDIT_SURVEY_STRUCTURE = "EDIT_SURVEY_STRUCTURE";
export const SG_EDIT_SURVEY_STRUCTURE = "SG_EDIT_SURVEY_STRUCTURE"; //saga use this
export const ADD_SURVEY_STRUCTURE = "ADD_SURVEY_STRUCTURE";
export const SG_ADD_SURVEY_STRUCTURE = "SG_ADD_SURVEY_STRUCTURE"; //saga use this
export const CLEAR_SURVEY_STRUCTURE = "CLEAR_SURVEY_STRUCTURE";
//
//
export const START_FILE_UPLOAD = "START_FILE_UPLOAD";
export const GET_FILE_UPLOADS = "GET_FILE_UPLOADS";
export const SG_GET_FILE_UPLOADS = "SG_GET_FILE_UPLOADS"; //saga use this
export const DELETE_FILE_UPLOAD = "DELETE_FILE_UPLOAD";
export const SG_DELETE_FILE_UPLOAD = "SG_DELETE_FILE_UPLOAD"; //saga use this
export const FETCH_FILE_UPLOAD = "FETCH_FILE_UPLOAD";
export const SG_FETCH_FILE_UPLOAD = "SG_FETCH_FILE_UPLOAD"; //saga use this
export const CLOSE_FILE_UPLOAD = "CLOSE_FILE_UPLOAD";
export const EDIT_FILE_UPLOAD = "EDIT_FILE_UPLOAD";
export const SG_EDIT_FILE_UPLOAD = "SG_EDIT_FILE_UPLOAD"; //saga use this
export const ADD_FILE_UPLOAD = "ADD_FILE_UPLOAD";
export const SG_ADD_FILE_UPLOAD = "SG_ADD_FILE_UPLOAD"; //saga use this
export const CLEAR_FILE_UPLOAD = "CLEAR_FILE_UPLOAD";
export const CLEAR_CREATED_FILE_UPLOAD = "CLEAR_CREATED_FILE_UPLOAD";
//
//
export const GET_EMPLOYEE_CATEGORIES = "GET_EMPLOYEE_CATEGORIES";
export const SG_GET_EMPLOYEE_CATEGORIES = "SG_GET_EMPLOYEE_CATEGORIES"; //saga use this
export const DELETE_EMPLOYEE_CATEGORY = "DELETE_EMPLOYEE_CATEGORY";
export const SG_DELETE_EMPLOYEE_CATEGORY = "SG_DELETE_EMPLOYEE_CATEGORY"; //saga use this
export const FETCH_EMPLOYEE_CATEGORY = "FETCH_EMPLOYEE_CATEGORY";
export const SG_FETCH_EMPLOYEE_CATEGORY = "SG_FETCH_EMPLOYEE_CATEGORY"; //saga use this
export const EDIT_EMPLOYEE_CATEGORY = "EDIT_EMPLOYEE_CATEGORY";
export const SG_EDIT_EMPLOYEE_CATEGORY = "SG_EDIT_EMPLOYEE_CATEGORY"; //saga use this
export const ADD_EMPLOYEE_CATEGORY = "ADD_EMPLOYEE_CATEGORY";
export const SG_ADD_EMPLOYEE_CATEGORY = "SG_ADD_EMPLOYEE_CATEGORY"; //saga use this
export const CLEAR_EMPLOYEE_CATEGORY = "CLEAR_EMPLOYEE_CATEGORY";
//
//
export const GET_WHITE_LABEL = "GET_WHITE_LABEL";
export const SET_WHITE_LABEL = "SET_WHITE_LABEL";
//
//
export const GET_FILTERED_DATA = "GET_FILTERED_DATA";
export const SET_FILTERED_DATA = "SET_FILTERED_DATA";
export const SET_FILTERED_LAST_DATA = "SET_FILTERED_LAST_DATA";
export const SET_SELECTED_QUESTION = "SET_SELECTED_QUESTION";
export const SET_DATA_DATES = "SET_DATA_DATES";
export const SET_CATEGORY_DATA = "SET_CATEGORY_DATA";
export const SET_RAW_DATA = "SET_RAW_DATA";
export const SET_QUESTIONS = "SET_QUESTIONS";
export const SET_SUB_NAV = "SET_SUB_NAV";
export const SET_NAVIGATION = "SET_NAVIGATION";
export const SET_SELECTED_FILTERS = "SET_SELECTED_FILTERS";
export const SET_DATA_ACNHOR = "SET_DATA_ACNHOR";

// PDF Template Actions
export const GET_PDF_TEMPLATES = "GET_PDF_TEMPLATES";
export const SG_GET_PDF_TEMPLATES = "SG_GET_PDF_TEMPLATES";
export const FETCH_PDF_TEMPLATE = "FETCH_PDF_TEMPLATE";
export const SG_FETCH_PDF_TEMPLATE = "SG_FETCH_PDF_TEMPLATES";
export const SG_CREATE_PDF_TEMPLATE = "SG_CREATE_PDF_TEMPLATE";
export const CREATE_PDF_TEMPLATE = "CREATE_PDF_TEMPLATE";

// Action Plan Actions
export const GET_ALL_ACTION_PLANS = "GET_ALL_ACTION_PLANS";
export const SG_GET_ALL_ACTION_PLANS = "SG_GET_ALL_ACTION_PLANS";
export const FETCH_ACTION_PLAN = "FETCH_ACTION_PLAN";
export const SG_FETCH_ACTION_PLAN = "SG_FETCH_ACTION_PLAN";
export const CREATE_ACTION_PLAN = "CREATE_ACTION_PLAN";
export const SG_CREATE_ACTION_PLAN = "SG_CREATE_ACTION_PLAN";
export const UPDATE_ACTION_PLAN = "UPDATE_ACTION_PLAN";
export const SG_UPDATE_ACTION_PLAN = "SG_UPDATE_ACTION_PLAN";
export const DELETE_ACTION_PLAN = "DELETE_ACTION_PLAN";
export const SG_DELETE_ACTION_PLAN = "SG_DELETE_ACTION_PLAN";
// Action Plan Activities
export const GET_ACTION_PLAN_ACTIVITY = "GET_ACTION_PLAN_ACTIVITY";
export const SG_GET_ACTION_PLAN_ACTIVITY = "SG_GET_ACTION_PLAN_ACTIVITY";
export const ADD_ACTIVITY_ITEM = "ADD_ACTIVITY_ITEM";
export const SG_ADD_ACTIVITY_ITEM = "SG_ADD_ACTIVITY_ITEM";
export const DELETE_ACTIVITY_ITEM = "DELETE_ACTIVITY_ITEM";
export const SG_UPDATE_ACTIVITY_ITEM = "SG_UPDATE_ACTIVITY_ITEM";
export const UPDATE_ACTIVITY_ITEM = "UPDATE_ACTIVITY_ITEM";
export const SG_DELETE_ACTIVITY_ITEM = "SG_DELETE_ACTIVITY_ITEM";
export const GET_ACTION_PLAN_ACTIVITIES = "GET_ACTION_PLAN_ACTIVITIES";
export const SG_GET_ACTION_PLAN_ACTIVITIES = "SG_GET_ACTION_PLAN_ACTIVITIES";
// Get employees (non admin)
export const GET_SAFE_EMPLOYEE_LIST = "GET_SAFE_EMPLOYEE_LIST";
export const SG_GET_SAFE_EMPLOYEE_LIST = "SG_GET_SAFE_EMPLOYEE_LIST";
// EMPLOYEE_LOG Activities
export const GET_EMPLOYEE_LOG = "GET_EMPLOYEE_LOG";
export const SG_GET_EMPLOYEE_LOG = "SG_GET_EMPLOYEE_LOG";
export const ADD_EMPLOYEE_LOG = "ADD_EMPLOYEE_LOG";
export const SG_ADD_EMPLOYEE_LOG = "SG_ADD_EMPLOYEE_LOG";
export const DELETE_EMPLOYEE_LOG = "DELETE_EMPLOYEE_LOG";
export const SG_DELETE_EMPLOYEE_LOG = "SG_DELETE_EMPLOYEE_LOG";
export const UPDATE_EMPLOYEE_LOG = "UPDATE_EMPLOYEE_LOG";
export const SG_UPDATE_EMPLOYEE_LOG = "SG_UPDATE_EMPLOYEE_LOG";
export const FETCH_EMPLOYEE_LOG = "FETCH_EMPLOYEE_LOG";
export const SG_FETCH_EMPLOYEE_LOG = "SG_FETCH_EMPLOYEE_LOG";
export const CLEAR_EMPLOYEE_LOG = "CLEAR_EMPLOYEE_LOG";
// EMPLOYEE_RECORD Activities
export const GET_EMPLOYEE_RECORD = "GET_EMPLOYEE_RECORD";
export const SG_GET_EMPLOYEE_RECORD = "SG_GET_EMPLOYEE_RECORD";
export const ADD_EMPLOYEE_RECORD = "ADD_EMPLOYEE_RECORD";
export const SG_ADD_EMPLOYEE_RECORD = "SG_ADD_EMPLOYEE_RECORD";
export const DELETE_EMPLOYEE_RECORD = "DELETE_EMPLOYEE_RECORD";
export const SG_DELETE_EMPLOYEE_RECORD = "SG_DELETE_EMPLOYEE_RECORD";
export const UPDATE_EMPLOYEE_RECORD = "UPDATE_EMPLOYEE_RECORD";
export const SG_UPDATE_EMPLOYEE_RECORD = "SG_UPDATE_EMPLOYEE_RECORD";
export const FETCH_EMPLOYEE_RECORD = "FETCH_EMPLOYEE_RECORD";
export const SG_FETCH_EMPLOYEE_RECORD = "SG_FETCH_EMPLOYEE_RECORD";
export const CLEAR_EMPLOYEE_RECORD = "CLEAR_EMPLOYEE_RECORD";
// EMPLOYEE_RECORD_LIST Activities
export const GET_EMPLOYEE_RECORD_LIST = "GET_EMPLOYEE_RECORD_LIST";
export const SG_GET_EMPLOYEE_RECORD_LIST = "SG_GET_EMPLOYEE_RECORD_LIST";
export const ADD_EMPLOYEE_RECORD_LIST = "ADD_EMPLOYEE_RECORD_LIST";
export const SG_ADD_EMPLOYEE_RECORD_LIST = "SG_ADD_EMPLOYEE_RECORD_LIST";
export const DELETE_EMPLOYEE_RECORD_LIST = "DELETE_EMPLOYEE_RECORD_LIST";
export const SG_DELETE_EMPLOYEE_RECORD_LIST = "SG_DELETE_EMPLOYEE_RECORD_LIST";
export const UPDATE_EMPLOYEE_RECORD_LIST = "UPDATE_EMPLOYEE_RECORD_LIST";
export const SG_UPDATE_EMPLOYEE_RECORD_LIST = "SG_UPDATE_EMPLOYEE_RECORD_LIST";
export const FETCH_EMPLOYEE_RECORD_LIST = "FETCH_EMPLOYEE_RECORD_LIST";
export const SG_FETCH_EMPLOYEE_RECORD_LIST = "SG_FETCH_EMPLOYEE_RECORD_LIST";
export const CLEAR_EMPLOYEE_RECORD_LIST = "CLEAR_EMPLOYEE_RECORD_LIST";
// EMPLOYEE_RECORD_VALUE Activities
export const GET_EMPLOYEE_RECORD_VALUE = "GET_EMPLOYEE_RECORD_VALUE";
export const SG_GET_EMPLOYEE_RECORD_VALUE = "SG_GET_EMPLOYEE_RECORD_VALUE";
export const ADD_EMPLOYEE_RECORD_VALUE = "ADD_EMPLOYEE_RECORD_VALUE";
export const SG_ADD_EMPLOYEE_RECORD_VALUE = "SG_ADD_EMPLOYEE_RECORD_VALUE";
export const DELETE_EMPLOYEE_RECORD_VALUE = "DELETE_EMPLOYEE_RECORD_VALUE";
export const SG_DELETE_EMPLOYEE_RECORD_VALUE =
  "SG_DELETE_EMPLOYEE_RECORD_VALUE";
export const UPDATE_EMPLOYEE_RECORD_VALUE = "UPDATE_EMPLOYEE_RECORD_VALUE";
export const SG_UPDATE_EMPLOYEE_RECORD_VALUE =
  "SG_UPDATE_EMPLOYEE_RECORD_VALUE";
export const FETCH_EMPLOYEE_RECORD_VALUE = "FETCH_EMPLOYEE_RECORD_VALUE";
export const SG_FETCH_EMPLOYEE_RECORD_VALUE = "SG_FETCH_EMPLOYEE_RECORD_VALUE";
export const CLEAR_EMPLOYEE_RECORD_VALUE = "CLEAR_EMPLOYEE_RECORD_VALUE";
//
// SURVEY STANDARD Activities
export const SURVEY_STANDARD_PENDING = "SURVEY_STANDARD_PENDING";
export const GET_SURVEY_STANDARD = "GET_SURVEY_STANDARD";
export const SG_GET_SURVEY_STANDARD = "SG_GET_SURVEY_STANDARD";
export const ADD_SURVEY_STANDARD = "ADD_SURVEY_STANDARD";
export const SG_ADD_SURVEY_STANDARD = "SG_ADD_SURVEY_STANDARD";
export const DELETE_SURVEY_STANDARD = "DELETE_SURVEY_STANDARD";
export const SG_DELETE_SURVEY_STANDARD = "SG_DELETE_SURVEY_STANDARD";
export const UPDATE_SURVEY_STANDARD = "UPDATE_SURVEY_STANDARD";
export const SG_UPDATE_SURVEY_STANDARD = "SG_UPDATE_SURVEY_STANDARD";
export const FETCH_SURVEY_STANDARD = "FETCH_SURVEY_STANDARD";
export const SG_FETCH_SURVEY_STANDARD = "SG_FETCH_SURVEY_STANDARD";
export const CLEAR_SURVEY_STANDARD = "CLEAR_SURVEY_STANDARD";

// Onboarding checklist
export const SET_TRACKED_STATE = "SET_TRACKED_STATE";
export const COMPLETE_ANALYTICS = "COMPLETE_ANALYTICS";

// 
// 
//
// BRIEF CUSTOM EMAIL
export const DEBRIEF_EMAIL_PENDING = "DEBRIEF_EMAIL_PENDING";
export const GET_DEBRIEF_EMAIL = "GET_DEBRIEF_EMAIL";
export const SG_GET_DEBRIEF_EMAIL = "SG_GET_DEBRIEF_EMAIL";
export const ADD_DEBRIEF_EMAIL = "ADD_DEBRIEF_EMAIL";
export const SG_ADD_DEBRIEF_EMAIL = "SG_ADD_DEBRIEF_EMAIL";
export const DELETE_DEBRIEF_EMAIL = "DELETE_DEBRIEF_EMAIL";
export const SG_DELETE_DEBRIEF_EMAIL = "SG_DELETE_DEBRIEF_EMAIL";
export const UPDATE_DEBRIEF_EMAIL = "UPDATE_DEBRIEF_EMAIL";
export const SG_UPDATE_DEBRIEF_EMAIL = "SG_UPDATE_DEBRIEF_EMAIL";
export const FETCH_DEBRIEF_EMAIL = "FETCH_DEBRIEF_EMAIL";
export const SG_FETCH_DEBRIEF_EMAIL = "SG_FETCH_DEBRIEF_EMAIL";
export const CLEAR_DEBRIEF_EMAIL = "CLEAR_DEBRIEF_EMAIL";
//
export const GOOGLE_AUTH_PENDING = "GOOGLE_AUTH_PENDING";
export const LOGIN_GOOGLE_AUTH = "LOGIN_GOOGLE_AUTH";
export const SG_LOGIN_GOOGLE_AUTH = "SG_LOGIN_GOOGLE_AUTH";
export const REGISTER_GOOGLE_AUTH = "REGISTER_GOOGLE_AUTH";
export const SG_REGISTER_GOOGLE_AUTH = "SG_REGISTER_GOOGLE_AUTH";
export const REGISTER_GOOGLE_AUTH_TOKEN = "REGISTER_GOOGLE_AUTH_TOKEN";
export const SG_REGISTER_GOOGLE_AUTH_TOKEN = "SG_REGISTER_GOOGLE_AUTH_TOKEN";
export const CLEAR_GOOGLE_AUTH = "CLEAR_GOOGLE_AUTH";
//
//
export const UPDATE_ACCOUNT_DEFAULT_PENDING = "UPDATE_ACCOUNT_DEFAULT_PENDING";
export const LOGIN_UPDATE_ACCOUNT_DEFAULT = "LOGIN_UPDATE_ACCOUNT_DEFAULT";
export const SG_LOGIN_UPDATE_ACCOUNT_DEFAULT =
  "SG_LOGIN_UPDATE_ACCOUNT_DEFAULT";
export const CLEAR_UPDATE_ACCOUNT_DEFAULT = "CLEAR_UPDATE_ACCOUNT_DEFAULT";
//
export const LOGIN_REDIRECT = "LOGIN_REDIRECT";
//
// ENGAGEMENT DATA TRACKING
export const ENGAGEMENT_DATA_PENDING = "ENGAGEMENT_DATA_PENDING";
export const GET_ENGAGEMENT_DATA = "GET_ENGAGEMENT_DATA";
export const SG_GET_ENGAGEMENT_DATA = "SG_GET_ENGAGEMENT_DATA";
export const CLEAR_ENGAGEMENT_DATA = "CLEAR_ENGAGEMENT_DATA";
//
// CHAT GPT
export const CHAT_GPT_PENDING = "CHAT_GPT_PENDING";
export const POST_CHAT_GPT = "POST_CHAT_GPT";
export const SG_POST_CHAT_GPT = "SG_POST_CHAT_GPT";
export const GET_CHAT_GPT = "GET_CHAT_GPT";
export const SG_GET_CHAT_GPT = "SG_GET_CHAT_GPT";
export const CLEAR_CHAT_GPT = "CLEAR_CHAT_GPT";

// LOGIN TO ML SERVER
export const AUTO_LOGIN_TO_ML = "AUTO_LOGIN_TO_ML"
export const SG_AUTO_LOGIN_TO_ML = "SG_AUTO_LOGIN_TO_ML"
export const AUTO_LOGIN_TO_ML_ERROR = "AUTO_LOGIN_TO_ML_ERROR"
export const CLEAR_AUTO_LOGIN_TO_ML = "CLEAR_AUTO_LOGIN_TO_ML"

// ML AI SERVER
export const POST_ML_SERVER_V1 = "POST_ML_SERVER_V1"
export const SG_POST_ML_SERVER_V1 = "SG_POST_ML_SERVER_V1"
export const POST_ML_SERVER_V1_XL = "POST_ML_SERVER_V1_XL"
export const SG_POST_ML_SERVER_V1_XL = "SG_POST_ML_SERVER_V1_XL"
export const SG_POST_ML_SERVER_V1_4 = "SG_POST_ML_SERVER_V1_4";
export const POST_ML_SERVER_V1_4 = "POST_ML_SERVER_V1_4";
// 
// PDF COMMENTS
export const GET_PDF_SAVED_COMMENTS = "GET_PDF_SAVED_COMMENTS";
export const SG_GET_PDF_SAVED_COMMENTS = "SG_GET_PDF_SAVED_COMMENTS";
export const CREATE_PDF_SAVED_COMMENTS = "CREATE_PDF_SAVED_COMMENTS";
export const SG_CREATE_PDF_SAVED_COMMENTS = "SG_CREATE_PDF_SAVED_COMMENTS";
export const UPDATE_PDF_SAVED_COMMENTS = "UPDATE_PDF_SAVED_COMMENTS";
export const SG_UPDATE_PDF_SAVED_COMMENTS = "SG_UPDATE_PDF_SAVED_COMMENTS";
export const DELETE_PDF_SAVED_COMMENTS = "DELETE_PDF_SAVED_COMMENTS";
export const SG_DELETE_PDF_SAVED_COMMENTS = "SG_DELETE_PDF_SAVED_COMMENTS";
//
// ORG CATEGORIES
export const GET_EMPLOYEE_ORG_CATEGORIES = "GET_EMPLOYEE_ORG_CATEGORIES";
export const SG_GET_EMPLOYEE_ORG_CATEGORIES = "SG_GET_EMPLOYEE_ORG_CATEGORIES"; //saga use this
//
// USER CATEGORIES
export const GET_EMPLOYEE_USER_CATEGORIES = "GET_EMPLOYEE_USER_CATEGORIES";
export const SG_GET_EMPLOYEE_USER_CATEGORIES =
  "SG_GET_EMPLOYEE_USER_CATEGORIES"; //saga use this
// 
// OUTCOME QUESTIONS
export const GET_OUTCOME_QUESTIONS = "GET_OUTCOME_QUESTIONS";
export const SG_GET_OUTCOME_QUESTIONS = "SG_GET_OUTCOME_QUESTIONS";
//
// MEMBER FILTERS
export const GET_MEMBER_FILTERS = "GET_MEMBER_FILTERS";
export const SG_GET_MEMBER_FILTERS = "SG_GET_MEMBER_FILTERS";
export const SET_MEMBER_FILTERS = "SET_MEMBER_FILTERS";
export const SG_SET_MEMBER_FILTERS = "SG_SET_MEMBER_FILTERS";
export const SG_SET_MEMBER_SEARCH_FILTERS = "SG_SET_MEMBER_SEARCH_FILTERS";
export const SET_MEMBER_SEARCH_FILTERS = "SET_MEMBER_SEARCH_FILTERS";
// 
// AI WEBSOCKETS
export const ERROR = "ERROR"
export const FETCH_FAILURE = "FETCH_FAILURE"
export const ROOM_LOADING = "ROOM_LOADING"
export const SG_FETCH_CHAT_ROOM = "SG_FETCH_CHAT_ROOM"
export const FETCH_CHAT_ROOM = "FETCH_CHAT_ROOM"
export const SG_CREATE_CHAT_ROOM = "SG_CREATE_CHAT_ROOM"
export const CREATE_CHAT_ROOM = "CREATE_CHAT_ROOM"
export const FETCH_SUCCESS = "FETCH_SUCCESS"
export const ERROR_WEBSOCKET = "ERROR_WEBSOCKET"
export const PENDING_WEBSOCKET = "PENDING_WEBSOCKET"
export const CONNECT_WEBSOCKET = "CONNECT_WEBSOCKET"
export const SAGA_CONNECT_WEBSOCKET = "SAGA_CONNECT_WEBSOCKET"
export const MESSAGE_WEBSOCKET = "MESSAGE_WEBSOCKET"
export const SAGA_MESSAGE_WEBSOCKET = "SAGA_MESSAGE_WEBSOCKET"
export const SAGA_START_AND_MESSAGE_WEBSOCKET = "SAGA_START_AND_MESSAGE_WEBSOCKET"
export const DISCONNECT_WEBSOCKET = "DISCONNECT_WEBSOCKET"
export const SAGA_DISCONNECT_WEBSOCKET = "SAGA_DISCONNECT_WEBSOCKET"
export const CLEAR_ERROR = "CLEAR_ERROR"
export const DISPLAY_RETRY_MESSAGE = "DISPLAY_RETRY_MESSAGE";
export const CLEAR_CHAT_MESSAGES = "CLEAR_CHAT_MESSAGES";

export const SG_GET_CHAT_ROOM = "SG_GET_CHAT_ROOM"
export const GET_CHAT_ROOM = "GET_CHAT_ROOM"
export const SET_WEB_CHAT_ID = "SET_WEB_CHAT_ID"
export const SET_WEB_CHAT_MESSAGES = "SET_WEB_CHAT_MESSAGES"
export const DELETE_CHAT_ROOM = "DELETE_CHAT_ROOM";
export const SG_DELETE_CHAT_ROOM = "SG_DELETE_CHAT_ROOM";
export const CLEAR_DELETE_MESSAGE = "CLEAR_DELETE_MESSAGE";
export const ADD_PENDING_MESSAGE = "ADD_PENDING_MESSAGE";
export const SG_ADD_PENDING_MESSAGE = "SG_ADD_PENDING_MESSAGE";

export const SET_SIMPLE_PROMPT = "SET_SIMPLE_PROMPT";
export const CLEAR_SIMPLE_PROMPT = "CLEAR_SIMPLE_PROMPT";

//SURVEY VERSIONS
export const SET_SURVEY_VERSION = "SET_SURVEY_VERSION";
export const SET_NEW_FILTERS = "SET_NEW_FILTERS";

// CLEAR DISPATCH FOR ORG CATEGORIES
export const CLEAR_EMPLOYEE_ORG_CATEGORY = "CLEAR_EMPLOYEE_ORG_CATEGORY";

// RECOMMENDATIONS
export const GET_CHAT_RECOMMENDATION = "GET_CHAT_RECOMMENDATION";
export const SG_GET_CHAT_RECOMMENDATION = "SG_GET_CHAT_RECOMMENDATION";
export const FETCH_CHAT_RECOMMENDATION = "FETCH_CHAT_RECOMMENDATION";
export const SG_FETCH_CHAT_RECOMMENDATION = "SG_FETCH_CHAT_RECOMMENDATION";
export const ADD_CHAT_RECOMMENDATION = "ADD_CHAT_RECOMMENDATION";
export const SG_ADD_CHAT_RECOMMENDATION = "SG_ADD_CHAT_RECOMMENDATION";
export const DELETE_CHAT_RECOMMENDATION = "DELETE_CHAT_RECOMMENDATION";
export const SG_DELETE_CHAT_RECOMMENDATION = "SG_DELETE_CHAT_RECOMMENDATION";
export const EDIT_CHAT_RECOMMENDATION = "EDIT_CHAT_RECOMMENDATION";
export const SG_EDIT_CHAT_RECOMMENDATION = "SG_EDIT_CHAT_RECOMMENDATION";
export const CHAT_RECOMMENDATION_ERROR = "CHAT_RECOMMENDATION_ERROR";
export const CLEAR_CHAT_RECOMMENDATION = "CLEAR_CHAT_RECOMMENDATION";
export const SET_ACTIVE_RECOMMENDATION = "SET_ACTIVE_RECOMMENDATION";
export const SET_ACTIVE_JOURNEY = "SET_ACTIVE_JOURNEY";
export const GET_DATA_STORIES = "GET_DATA_STORIES";
export const SG_GET_DATA_STORIES = "SG_GET_DATA_STORIES";
export const CLEAR_DATA_STORY = "CLEAR_DATA_STORY";
export const GET_DATA_JOURNEYS = "GET_DATA_JOURNEYS";
export const SG_GET_DATA_JOURNEYS = "SG_GET_DATA_JOURNEYS";
export const CLEAR_DATA_JOURNEY = "CLEAR_DATA_JOURNEY";
export const SG_GET_STORY_TEMPLATES = "SG_GET_STORY_TEMPLATES";
export const GET_STORY_TEMPLATES = "GET_STORY_TEMPLATES";

// // DATA STORIES
// export const GET_DATA_STORIES = "GET_DATA_STORIES";
// export const SG_GET_DATA_STORIES = "SG_GET_DATA_STORIES";
// export const FETCH_DATA_STORY = "FETCH_DATA_STORY";
// export const SG_FETCH_DATA_STORY = "SG_FETCH_DATA_STORY";
// export const ADD_DATA_STORY = "ADD_DATA_STORY";
// export const SG_ADD_DATA_STORY = "SG_ADD_DATA_STORY";
// export const DELETE_DATA_STORY = "DELETE_DATA_STORY";
// export const SG_DELETE_DATA_STORY = "SG_DELETE_DATA_STORY";
// export const EDIT_DATA_STORY = "EDIT_DATA_STORY";
// export const SG_EDIT_DATA_STORY = "SG_EDIT_DATA_STORY";

export const CLEAR_REPORT_TEMPLATE = "CLEAR_REPORT_TEMPLATE";
export const GET_REPORT_TEMPLATE = "GET_REPORT_TEMPLATE";
export const GET_REPORT_TEMPLATE_PENDING = "GET_REPORT_TEMPLATE_PENDING";
export const SG_GET_REPORT_TEMPLATE = "SG_GET_REPORT_TEMPLATE";
export const SET_CORE_DATA = "SET_CORE_DATA";
export const SET_NEW_NAVIGATION = "SET_NEW_NAVIGATION";

// this is not fully intergrated yet
export const NEW_EVENT_NOTIFICATION = "NEW_EVENT_NOTIFICATION";
export const SG_NEW_EVENT_NOTIFICATION = "SG_NEW_EVENT_NOTIFICATION";
export const CLEAR_EVENT_NOTIFICATION = "CLEAR_EVENT_NOTIFICATION";
export const CLEAR_ALL_EVENT_NOTIFICATIONS = "CLEAR_ALL_EVENT_NOTIFICATIONS";

// REACT PDF  PDF_REPORT PDF REPORT TEMPLATES
export const GET_DEBRIEF_PDF_REPORT_TEMPLATES = "GET_DEBRIEF_PDF_REPORT_TEMPLATES";
export const SG_GET_DEBRIEF_PDF_REPORT_TEMPLATES = "SG_GET_DEBRIEF_PDF_REPORT_TEMPLATES";
export const GET_DEBRIEF_PDF_REPORT = "GET_DEBRIEF_PDF_REPORT";
export const SG_GET_DEBRIEF_PDF_REPORT = "SG_GET_DEBRIEF_PDF_REPORT";
export const FETCH_DEBRIEF_PDF_REPORT = "FETCH_DEBRIEF_PDF_REPORT";
export const SG_FETCH_DEBRIEF_PDF_REPORT = "SG_FETCH_DEBRIEF_PDF_REPORT";
export const CREATE_DEBRIEF_PDF_REPORT = "CREATE_DEBRIEF_PDF_REPORT";
export const SG_CREATE_DEBRIEF_PDF_REPORT = "SG_CREATE_DEBRIEF_PDF_REPORT";
export const DELETE_DEBRIEF_PDF_REPORT = "DELETE_DEBRIEF_PDF_REPORT";
export const SG_DELETE_DEBRIEF_PDF_REPORT = "SG_DELETE_DEBRIEF_PDF_REPORT";
export const DELETE_DEBRIEF_PDF_REPORT_ITEM = "DELETE_DEBRIEF_PDF_REPORT_ITEM";
export const SG_DELETE_DEBRIEF_PDF_REPORT_ITEM =
  "SG_DELETE_DEBRIEF_PDF_REPORT_ITEM";
export const UPDATE_DEBRIEF_PDF_REPORT = "UPDATE_DEBRIEF_PDF_REPORT";
export const SG_UPDATE_DEBRIEF_PDF_REPORT = "SG_UPDATE_DEBRIEF_PDF_REPORT";
export const GET_DEBRIEF_PDF_REPORT_URL = "GET_DEBRIEF_PDF_REPORT_URL";
export const SG_GET_DEBRIEF_PDF_REPORT_URL = "SG_GET_DEBRIEF_PDF_REPORT_URL";
export const CLEAR_DEBRIEF_PDF_REPORT = "CLEAR_DEBRIEF_PDF_REPORT";
export const CLEAR_DEBRIEF_PDF_REPORT_URL = "CLEAR_DEBRIEF_PDF_REPORT_URL";
//

export const ADD_SCHEDULE_TEMPLATE = "ADD_SCHEDULE_TEMPLATE";
export const SG_ADD_SCHEDULE_TEMPLATE = "SG_ADD_SCHEDULE_TEMPLATE";
export const GET_SCHEDULE_TEMPLATES = "GET_SCHEDULE_TEMPLATES";
export const SG_GET_SCHEDULE_TEMPLATES = "SG_GET_SCHEDULE_TEMPLATES";
export const DELETE_SCHEDULE_TEMPLATE = "DELETE_SCHEDULE_TEMPLATE";
export const SG_DELETE_SCHEDULE_TEMPLATE = "SG_DELETE_SCHEDULE_TEMPLATE";
export const UPDATE_SCHEDULE_TEMPLATE = "UPDATE_SCHEDULE_TEMPLATE";
export const SG_UPDATE_SCHEDULE_TEMPLATE = "SG_UPDATE_SCHEDULE_TEMPLATE";
export const SG_GET_PENDING_SHEDULE_REPORTS = "SG_GET_PENDING_SHEDULE_REPORTS";
export const GET_PENDING_SHEDULE_REPORTS = "GET_PENDING_SHEDULE_REPORTS";
export const CLEAR_SCHEDULE_TEMPLATE = "CLEAR_SCHEDULE_TEMPLATE";
export const SG_ADD_MANAGER_USER = "SG_ADD_MANAGER_USER";
export const SG_DEACTIVATE_MANAGER_USER = "SG_DEACTIVATE_MANAGER_USER";
export const DEACTIVATE_MANAGER_USER = "DEACTIVATE_MANAGER_USER";
export const ADD_MANAGER_USER = "ADD_MANAGER_USER";
export const ADD_EDIT_MANAGER_USER = "ADD_EDIT_MANAGER_USER";
export const GET_MANAGER_USERS = "GET_MANAGER_USERS";
export const SG_GET_MANAGER_USERS = "SG_GET_MANAGER_USERS";
export const DELETE_STORY_RECOMMENDATION = "DELETE_STORY_RECOMMENDATION";
export const SG_DELETE_STORY_RECOMMENDATION = "SG_DELETE_STORY_RECOMMENDATION";
export const CLEAR_MANAGER_ERRORS = "CLEAR_MANAGER_ERRORS";

// TASK TEMPLATES
export const GET_TASK_TEMPLATES = "GET_TASK_TEMPLATES";
export const SG_GET_TASK_TEMPLATES = "SG_GET_TASK_TEMPLATES";
export const ADD_TASK_TEMPLATE = "ADD_TASK_TEMPLATE";
export const SG_ADD_TASK_TEMPLATE = "SG_ADD_TASK_TEMPLATE";
export const DELETE_TASK_TEMPLATE = "DELETE_TASK_TEMPLATE";
export const SG_DELETE_TASK_TEMPLATE = "SG_DELETE_TASK_TEMPLATE";
export const UPDATE_TASK_TEMPLATE = "UPDATE_TASK_TEMPLATE";
export const SG_UPDATE_TASK_TEMPLATE = "SG_UPDATE_TASK_TEMPLATE";
export const CLEAR_TASK_TEMPLATE = "CLEAR_TASK_TEMPLATE";
export const ADD_EMAIL_TEMPLATE = "ADD_EMAIL_TEMPLATE";
export const SG_ADD_EMAIL_TEMPLATE = "SG_ADD_EMAIL_TEMPLATE";
export const UPDATE_EMAIL_TEMPLATE = "UPDATE_EMAIL_TEMPLATE";
export const SG_UPDATE_EMAIL_TEMPLATE = "SG_UPDATE_EMAIL_TEMPLATE";

// ASSIGNING TASKS
export const GET_USER_TASKS = "GET_USER_TASKS";
export const SG_GET_USER_TASKS = "SG_GET_USER_TASKS";
export const ADD_USER_TASK = "ADD_USER_TASK";
export const SG_ADD_USER_TASK = "SG_ADD_USER_TASK";
export const DELETE_USER_TASK = "DELETE_USER_TASK";
export const SG_DELETE_USER_TASK = "SG_DELETE_USER_TASK";
export const SG_GENERATE_TASK_REPORT = "SG_GENERATE_TASK_REPORT";
export const GENERATE_TASK_REPORT = "GENERATE_TASK_REPORT";
export const FETCH_TASK_REPORT = "FETCH_TASK_REPORT";
export const SG_FETCH_TASK_REPORT = "SG_FETCH_TASK_REPORT";
export const GET_TASK_REPORTS = "GET_TASK_REPORTS";
export const SG_GET_TASK_REPORTS = "SG_GET_TASK_REPORTS";
export const ADD_EMPLOYEE_REPORT_ACCESS = "ADD_EMPLOYEE_REPORT_ACCESS";
export const SG_ADD_EMPLOYEE_REPORT_ACCESS = "SG_ADD_EMPLOYEE_REPORT_ACCESS";
export const SG_REMOVE_EMPLOYEE_REPORT_ACCESS =
  "SG_REMOVE_EMPLOYEE_REPORT_ACCESS";
export const REMOVE_EMPLOYEE_REPORT_ACCESS = "REMOVE_EMPLOYEE_REPORT_ACCESS";
export const UPDATE_USER_TASK = "UPDATE_USER_TASK";
export const SG_UPDATE_USER_TASK = "SG_UPDATE_USER_TASK";
export const CLEAR_USER_TASK = "CLEAR_USER_TASK";
export const GET_TASK_TEMPLATE_SELECT = "GET_TASK_TEMPLATE_SELECT";
export const SG_GET_TASK_TEMPLATE_SELECT = "SG_GET_TASK_TEMPLATE_SELECT";
export const CREATE_TASK_SCHEDULE = "CREATE_TASK_SCHEDULE";
export const SG_CREATE_TASK_SCHEDULE = "SG_CREATE_TASK_SCHEDULE";
export const GET_TASK_SCHEDULES = "GET_TASK_SCHEDULES";
export const SG_GET_TASK_SCHEDULES = "SG_GET_TASK_SCHEDULES";
export const SG_FETCH_TASK_SCHEDULE = "SG_FETCH_TASK_SCHEDULE";
export const FETCH_TASK_SCHEDULE = "FETCH_TASK_SCHEDULE";
export const CLEAR_FETCHED_SCHEDULED_TASK = "CLEAR_FETCHED_SCHEDULED_TASK";
export const GET_USER_TASK_RESPONSE = "GET_USER_TASK_RESPONSE";
export const SG_GET_USER_TASK_RESPONSE = "SG_GET_USER_TASK_RESPONSE";
export const ADD_USER_TASK_RESPONSE = "ADD_USER_TASK_RESPONSE";
export const SG_ADD_USER_TASK_RESPONSE = "SG_ADD_USER_TASK_RESPONSE";
export const UPDATE_USER_TASK_RESPONSE = "UPDATE_USER_TASK_RESPONSE";
export const SG_UPDATE_USER_TASK_RESPONSE = "SG_UPDATE_USER_TASK_RESPONSE";
export const SG_FETCH_TASK_TEMPLATE = "SG_FETCH_TASK_TEMPLATE";
export const FETCH_TASK_TEMPLATE = "FETCH_TASK_TEMPLATE";
// DATA STORY LOGGING
export const GET_DATA_STORY_LOGS = "GET_DATA_STORY_LOGS";
export const SG_GET_DATA_STORY_LOGS = "SG_GET_DATA_STORY_LOGS";
export const ADD_DATA_STORY_LOG = "ADD_DATA_STORY_LOG";
export const SG_ADD_DATA_STORY_LOG = "SG_ADD_DATA_STORY_LOG";
export const DELETE_DATA_STORY_LOG = "DELETE_DATA_STORY_LOG";
export const SG_DELETE_DATA_STORY_LOG = "SG_DELETE_DATA_STORY_LOG";
export const FETCH_DATA_STORY_LOG = "FETCH_DATA_STORY_LOG";
export const SG_FETCH_DATA_STORY_LOG = "SG_FETCH_DATA_STORY_LOG";
export const CLEAR_DATA_STORY_LOG = "CLEAR_DATA_STORY_LOG";
// AWS DOWNLOAD URLS
export const SG_GENERATE_AWS_DOWNLOAD_URL = "SG_GENERATE_AWS_DOWNLOAD_URL";
export const GENERATE_AWS_DOWNLOAD_URL = "GENERATE_AWS_DOWNLOAD_URL";
export const CLEAR_AWS_DOWNLOAD_URL = "CLEAR_AWS_DOWNLOAD_URL";

// PRODUCT ORG CONTRACT
export const GET_PROD_ORG_CONTRACT_PENDING = "GET_PROD_ORG_CONTRACT_PENDING";
export const GET_PROD_ORG_CONTRACT = "GET_PROD_ORG_CONTRACT";
export const SG_GET_PROD_ORG_CONTRACT = "SG_GET_PROD_ORG_CONTRACT";

export const SET_DARK_MODE = "SET_DARK_MODE";
// PRESENTATIONS
export const GET_POWERPOINT_PRESENTATION = "GET_POWERPOINT_PRESENTATION";
export const DELETE_POWERPOINT_PRESENTATION = "DELETE_POWERPOINT_PRESENTATION";
export const CREATE_POWERPOINT_PRESENTATION = "CREATE_POWERPOINT_PRESENTATION";
export const GET_POWERPOINT_PRESENTATION_STATUS = "GET_POWERPOINT_PRESENTATION_STATUS";
export const SG_GET_POWERPOINT_PRESENTATION = "SG_GET_POWERPOINT_PRESENTATION";
export const SG_GET_POWERPOINT_PRESENTATION_DOWNLOAD =
  "SG_GET_POWERPOINT_PRESENTATION_DOWNLOAD";
export const GET_POWERPOINT_PRESENTATION_DOWNLOAD =
  "GET_POWERPOINT_PRESENTATION_DOWNLOAD";
export const SG_DELETE_POWERPOINT_PRESENTATION = "SG_DELETE_POWERPOINT_PRESENTATION";
export const SG_CREATE_POWERPOINT_PRESENTATION = "SG_CREATE_POWERPOINT_PRESENTATION";
export const SG_GET_POWERPOINT_PRESENTATION_STATUS = "SG_GET_POWERPOINT_PRESENTATION_STATUS";
export const CLEAR_POWERPOINT_PRESENTATION = "CLEAR_POWERPOINT_PRESENTATION";
export const POWERPOINT_PRESENTATIONS_PENDING = "POWERPOINT_PRESENTATIONS_PENDING";
// GET_EMPLOYEE_FILE_UPLOAD
export const GET_EMPLOYEE_FILE_UPLOAD = "GET_EMPLOYEE_FILE_UPLOAD";
export const SG_GET_EMPLOYEE_FILE_UPLOAD = "SG_GET_EMPLOYEE_FILE_UPLOAD";
export const DELETE_EMPLOYEE_FILE_UPLOAD = "DELETE_EMPLOYEE_FILE_UPLOAD";
export const SG_DELETE_EMPLOYEE_FILE_UPLOAD = "SG_DELETE_EMPLOYEE_FILE_UPLOAD";
export const FETCH_EMPLOYEE_FILE_UPLOAD = "FETCH_EMPLOYEE_FILE_UPLOAD";
export const SG_FETCH_EMPLOYEE_FILE_UPLOAD = "SG_FETCH_EMPLOYEE_FILE_UPLOAD";
export const EDIT_EMPLOYEE_FILE_UPLOAD = "EDIT_EMPLOYEE_FILE_UPLOAD";
export const SG_EDIT_EMPLOYEE_FILE_UPLOAD = "SG_EDIT_EMPLOYEE_FILE_UPLOAD";
export const ADD_EMPLOYEE_FILE_UPLOAD = "ADD_EMPLOYEE_FILE_UPLOAD";
export const SG_ADD_EMPLOYEE_FILE_UPLOAD = "SG_ADD_EMPLOYEE_FILE_UPLOAD";
export const PENDING_EMPLOYEE_FILE_UPLOAD = "PENDING_EMPLOYEE_FILE_UPLOAD";
export const CLEAR_EMPLOYEE_FILE_UPLOAD = "CLEAR_EMPLOYEE_FILE_UPLOAD";
export const CLEAR_ADDED_FILE = "CLEAR_ADDED_FILE";
export const CLEAR_SELECTED_EMPLOYEE_FILE_UPLOAD = "CLEAR_SELECTED_EMPLOYEE_FILE_UPLOAD";
//
// 
export const SET_SELECTED_GROUP = "SET_SELECTED_GROUP";
//
// GET_EMPLOYEE_SLACK
export const EMPLOYEE_SLACK_PAGE = "EMPLOYEE_SLACK_PAGE";
export const GET_EMPLOYEE_SLACK = "GET_EMPLOYEE_SLACK";
export const SG_GET_EMPLOYEE_SLACK = "SG_GET_EMPLOYEE_SLACK";
export const DELETE_EMPLOYEE_SLACK = "DELETE_EMPLOYEE_SLACK";
export const SG_DELETE_EMPLOYEE_SLACK = "SG_DELETE_EMPLOYEE_SLACK";
export const FETCH_EMPLOYEE_SLACK = "FETCH_EMPLOYEE_SLACK";
export const SG_FETCH_EMPLOYEE_SLACK = "SG_FETCH_EMPLOYEE_SLACK";
export const EDIT_EMPLOYEE_SLACK = "EDIT_EMPLOYEE_SLACK";
export const SG_EDIT_EMPLOYEE_SLACK = "SG_EDIT_EMPLOYEE_SLACK";
export const UPDATE_EMPLOYEE_SLACK = "UPDATE_EMPLOYEE_SLACK";
export const SG_UPDATE_EMPLOYEE_SLACK = "SG_UPDATE_EMPLOYEE_SLACK";
export const ADD_EMPLOYEE_SLACK = "ADD_EMPLOYEE_SLACK";
export const SG_ADD_EMPLOYEE_SLACK = "SG_ADD_EMPLOYEE_SLACK";
export const PENDING_EMPLOYEE_SLACK = "PENDING_EMPLOYEE_SLACK";
export const CLEAR_EMPLOYEE_SLACK = "CLEAR_EMPLOYEE_SLACK";
export const CLEAR_SELECTED_EMPLOYEE_SLACK = "CLEAR_SELECTED_EMPLOYEE_SLACK";
//
//
// GET_ORG_SLACK
export const ORG_SLACK_PAGE = "ORG_SLACK_PAGE";
export const GET_ORG_SLACK = "GET_ORG_SLACK";
export const SG_GET_ORG_SLACK = "SG_GET_ORG_SLACK";
export const DELETE_ORG_SLACK = "DELETE_ORG_SLACK";
export const SG_DELETE_ORG_SLACK = "SG_DELETE_ORG_SLACK";
export const FETCH_ORG_SLACK = "FETCH_ORG_SLACK";
export const SG_FETCH_ORG_SLACK = "SG_FETCH_ORG_SLACK";
export const EDIT_ORG_SLACK = "EDIT_ORG_SLACK";
export const SG_EDIT_ORG_SLACK = "SG_EDIT_ORG_SLACK";
export const UPDATE_ORG_SLACK = "UPDATE_ORG_SLACK";
export const SG_UPDATE_ORG_SLACK = "SG_UPDATE_ORG_SLACK";
export const ADD_ORG_SLACK = "ADD_ORG_SLACK";
export const SG_ADD_ORG_SLACK = "SG_ADD_ORG_SLACK";
export const PENDING_ORG_SLACK = "PENDING_ORG_SLACK";
export const CLEAR_ORG_SLACK = "CLEAR_ORG_SLACK";
//
//
// SLACK CALLS
export const UPDATE_SLACK_APP = "UPDATE_SLACK_APP";
export const GET_SLACK_USER_LIST = "GET_SLACK_USER_LIST";
export const SG_GET_SLACK_USER_LIST = "SG_GET_SLACK_USER_LIST";
export const GET_SLACK_CHANNEL_LIST = "GET_SLACK_CHANNEL_LIST";
export const SG_GET_SLACK_CHANNEL_LIST = "SG_GET_SLACK_CHANNEL_LIST";
export const UPDATE_SLACK_CHANNEL_LIST = "UPDATE_SLACK_CHANNEL_LIST";
export const SG_UPDATE_SLACK_CHANNEL_LIST = "SG_UPDATE_SLACK_CHANNEL_LIST";
export const PENDING_SLACK_CALL = "PENDING_SLACK_CALL";
export const CLEAR_SLACK_CALL = "CLEAR_SLACK_CALL";
export const CLEAR_SELECTED_SLACK_CALL = "CLEAR_SELECTED_SLACK_CALL";
export const LINK_SLACK_ID_TO_EMPLOYEE_ID = "LINK_SLACK_ID_TO_EMPLOYEE_ID";
export const SG_LINK_SLACK_ID_TO_EMPLOYEE_ID = "SG_LINK_SLACK_ID_TO_EMPLOYEE_ID";
export const DELETE_SLACK_ID_TO_EMPLOYEE_ID = "DELETE_SLACK_ID_TO_EMPLOYEE_ID";
export const SG_DELETE_SLACK_ID_TO_EMPLOYEE_ID = "SG_DELETE_SLACK_ID_TO_EMPLOYEE_ID";
//
//
export const DEBRIEF_RESPONSE_VECTOR_DB_PENDING = "DEBRIEF_RESPONSE_VECTOR_DB_PENDING";
export const GET_DEBRIEF_RESPONSE_VECTOR_DB = "GET_DEBRIEF_RESPONSE_VECTOR_DB";
export const SG_GET_DEBRIEF_RESPONSE_VECTOR_DB = "SG_GET_DEBRIEF_RESPONSE_VECTOR_DB";
export const FETCH_DEBRIEF_RESPONSE_VECTOR_DB = "FETCH_DEBRIEF_RESPONSE_VECTOR_DB";
export const SG_FETCH_DEBRIEF_RESPONSE_VECTOR_DB = "SG_FETCH_DEBRIEF_RESPONSE_VECTOR_DB";
export const CLEAR_DEBRIEF_RESPONSE_VECTOR_DB = "CLEAR_DEBRIEF_RESPONSE_VECTOR_DB";
//
// GET_CHAT_WORKFLOW_HISTORY_
export const CHAT_WORKFLOW_HISTORY_PENDING = "CHAT_WORKFLOW_HISTORY_PENDING";
export const GET_CHAT_WORKFLOW_HISTORY = "GET_CHAT_WORKFLOW_HISTORY";
export const SG_GET_CHAT_WORKFLOW_HISTORY = "SG_GET_CHAT_WORKFLOW_HISTORY";
export const ADD_CHAT_WORKFLOW_HISTORY = "ADD_CHAT_WORKFLOW_HISTORY";
export const SG_ADD_CHAT_WORKFLOW_HISTORY = "SG_ADD_CHAT_WORKFLOW_HISTORY";
export const DELETE_CHAT_WORKFLOW_HISTORY = "DELETE_CHAT_WORKFLOW_HISTORY";
export const SG_DELETE_CHAT_WORKFLOW_HISTORY = "SG_DELETE_CHAT_WORKFLOW_HISTORY";
export const UPDATE_CHAT_WORKFLOW_HISTORY = "UPDATE_CHAT_WORKFLOW_HISTORY";
export const SG_UPDATE_CHAT_WORKFLOW_HISTORY = "SG_UPDATE_CHAT_WORKFLOW_HISTORY";
export const FETCH_CHAT_WORKFLOW_HISTORY = "FETCH_CHAT_WORKFLOW_HISTORY";
export const SG_FETCH_CHAT_WORKFLOW_HISTORY = "SG_FETCH_CHAT_WORKFLOW_HISTORY";
export const CLEAR_CHAT_WORKFLOW_HISTORY = "CLEAR_CHAT_WORKFLOW_HISTORY";
//