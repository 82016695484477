import React, { createContext, useState, useEffect } from 'react';
import styled,{keyframes} from 'styled-components'
import { Loader } from 'semantic-ui-react';



const Suggestions = ({data,selectFollowUp}) =>{

    return (
        <Container>
            <Title>
                Suggested Next Steps
            </Title>
            <SuggestionArea>
                <ul>
                    {data.data.slice(0,3).map((d,i)=>{

                        return (
                            <Bubble key={i} onClick={()=>selectFollowUp(i)}>{`Explore themes for ${d.name}`}</Bubble>
                        )   
                    })
                    }
                </ul>

            </SuggestionArea>
        </Container>
    )
}

export default Suggestions;


const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
    display:flex;
    flex-direction: column;
    width:100%;
    font-family: 'Raleway', sans-serif;
    opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
`

const Title = styled.div`
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top:20px;

`
const SuggestionArea = styled.div`
    display:flex;
    width:100%;
    flex-direction: column;
`

const Bubble = styled.li`
    margin-right: 10px;
    cursor: pointer;
    font-size: 12px;
    color:#2D70E2;
    font-weight: 600;
    margin-bottom:5px;
`