import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  SAGA_START_AND_MESSAGE_WEBSOCKET,
  SAGA_MESSAGE_WEBSOCKET,
  CLEAR_CHAT_MESSAGES,
  SG_GET_CHAT_ROOM,
  SG_GET_EMPLOYEE_CATEGORIES,
  SG_GET_SURVEY_QUESTIONS,
  ADD_PENDING_MESSAGE,
  SET_SELECTED_QUESTION,
  SET_NEW_FILTERS,
  SAGA_CONNECT_WEBSOCKET,
  SG_FETCH_CHAT_ROOM,
  SET_WEB_CHAT_ID,
  SG_DELETE_CHAT_ROOM,
  DISCONNECT_WEBSOCKET,
} from "constants/actions";

import {
  buildParams,
  generateTopicName,
  addPromptToHistoryIfNeeded,
} from "../chatHelpers";

// Create the context
const CopilotDataContext = createContext();

// Custom Hook for using the context
export const useCopilotDataContext = () => useContext(CopilotDataContext);

// Provider Component
export const CopilotDataProvider = ({ children }) => {
  const dispatch = useDispatch();

  // State Variables
  const [promptText, setPromptText] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [selectedChatRoom, setSelectedChatRoom] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [chatStarted, setChatStarted] = useState(false);

  // -------------------------------------------------------------------
  // States from ChatWindow that we want to move
  // -------------------------------------------------------------------
  const [messagesByRoom, setMessagesByRoom] = useState({});

  const [historyLoading, setHistoryLoading] = useState(false);
  const [hideLastQuery, setHideLastQuery] = useState(false);
  const [isConnected, setIsConnected] = useState(null);
  const [timeoutReached, setTimeoutReached] = useState(false);
  const [unknownError, setUnknownError] = useState(false);
  const [messagePending, setMessagePending] = useState(false);
  const [pendingId, setPendingId] = useState(null);
  const [selectedParams, setSelectedParams] = useState(null);

  const webSocketConnection = useSelector((state) => state.ai_websocket);
  const chatToken = useSelector((state) => state.auth?.chat_token);
  const chatTopics = useSelector((state) => state.ai_chat?.chat_topics);
  const fetchedRoom = useSelector(
    (state) => state.ai_chat?.[selectedChatRoom?.id]
  );

  // A ref for message scrolling if needed
  const messagesEndRef = useRef(null);

  // Side Effects: Example for fetching initial data
  useEffect(() => {
    dispatch({ type: SG_GET_CHAT_ROOM });
    dispatch({ type: SG_GET_EMPLOYEE_CATEGORIES });
  }, [dispatch]);

  // -------------------------------------------------------------------
  // Effects & functions from ChatWindow
  // -------------------------------------------------------------------

  // watch for chatHistory changes (like in ChatWindow)
  useEffect(() => {
    if (
      webSocketConnection?.channel?.id &&
      chatHistory &&
      (!selectedChatRoom?.id || selectedChatRoom?.id === "temp_room")
    ) {
      setSelectedChatRoom({
        id: webSocketConnection?.data?.web_chat_id || "temp_room",
        // Should be promptQuery.
        name:
          webSocketConnection?.channel?.name || generateTopicName(promptText),
        uuid: webSocketConnection?.channel?.id,
      });
    }
  }, [webSocketConnection, chatHistory]);

  useEffect(() => {
    // If the fetchedRoom has data, parse it and store in messagesByRoom
    if (
      fetchedRoom &&
      fetchedRoom.id &&
      fetchedRoom?.interaction_history?.length
    ) {
      handleFetchedRoomData(fetchedRoom);
    }
  }, [fetchedRoom]);

  const getCardDataFromParams = (params) => {
    const fileCardData =
      params?.dataSources
        ?.filter((source) => source.type === "file")
        ?.map((source) => {
          return {
            type: "file",
            id: source.id,
            title: source.name,
            status: 22,
          };
        }) || [];

    const factorCardData =
      params?.tags?.map((source) => {
        return {
          type: "culture",
          id: source,
          title: source,
        };
      }) || [];

    const outcomeCardData =
      params?.outcomes?.map((source) => {
        return {
          type: "outcome",
          id: source,
          title: source,
        };
      }) || [];

    const selectedPulses =
      params?.dataSources
        ?.filter((source) => source.type === "pulse")
        ?.map((source) => {
          return {
            type: "pulse",
            id: source.id,
            title: source.name,
            status: 22,
          };
        }) || [];

    return [
      ...fileCardData,
      ...factorCardData,
      ...outcomeCardData,
      ...selectedPulses,
    ];
  };

  const handleFetchedRoomData = (roomData) => {
    const { id, interaction_history = [], params = {} } = roomData;

    // Store the selected params for the room
    const paramsFormatted = getCardDataFromParams(params);
    setSelectedParams(paramsFormatted);
    // We want to produce two messages per interaction:
    // 1) A 'prompt' from interaction.message
    // 2) A 'response' from interaction.server?.response
    // We'll "flatten" these pairs into a single array.
    const parsedMessages = interaction_history.flatMap((interaction) => {
      // Always include a question/prompt
      const questionObj = {
        id: `${interaction.id}_prompt`, // Make sure it's unique
        type: "prompt",
        message: interaction.message || "",
        error: interaction.error || null,
      };

      // Conditionally include the server response if it exists
      const hasServerResponse = interaction.server?.response;
      if (hasServerResponse) {
        const responseObj = {
          id: `${interaction.id}_response`,
          type: "response",
          message: interaction.server.response,
          error: interaction.server.error || null,
          // copy server data if needed
          server: { ...interaction.server },
        };
        return [questionObj, responseObj];
      }

      // If there's no server response at all, just return the question
      return [questionObj];
    });

    setMessagesByRoom((prev) => ({
      ...prev,
      [id]: parsedMessages,
    }));
  };

  // -------------------------------------------------------------------
  // Merging new messages
  // -------------------------------------------------------------------
  const manageRoomMessages = (roomId, incomingObj, initialMessage = null) => {
    setMessagesByRoom((prev) => {
      // Copy state so we can modify safely
      let newState = { ...prev };

      // If we have no real roomId yet, use a temporary one so we can store messages
      const currentRoomId = roomId || "temp_room";

      // If we've just received a real roomId and we still have messages stored under "temp_room",
      // then merge them into the real room and remove the temporary entry.
      if (roomId && newState["temp_room"]) {
        const mergedMessages = [
          ...(newState[roomId] || []),
          ...newState["temp_room"],
        ];
        newState[roomId] = mergedMessages;
        delete newState["temp_room"];
      }

      // Existing messages for whichever room ID we're currently using
      const existingMsgs = newState[currentRoomId] || [];
      let updatedMessages = [...existingMsgs];

      // ---------------------------------------------------------------------------------
      // 1. Handle the initial message (prompt) if provided
      // ---------------------------------------------------------------------------------
      if (initialMessage) {
        // We'll assume the initialMessage has an id we set as "pendingId".
        setPendingId(initialMessage.id);

        // Prevent consecutive prompts: if the last message was a prompt, don't add another.
        if (existingMsgs?.[existingMsgs.length - 1]?.type === "prompt") {
          // Just return the existing state without adding a duplicate prompt
          return newState;
        }

        const messageWithPromptType = {
          ...initialMessage,
          type: "prompt",
        };

        let matchId = roomId || initialMessage.id;
        // Only add the new prompt if it doesn't already exist
        if (!existingMsgs.find((msg) => msg.id === matchId)) {
          updatedMessages.push(messageWithPromptType);
        }
      }

      // ---------------------------------------------------------------------------------
      // 2. Handle any incoming messages tied to the pending prompt
      // ---------------------------------------------------------------------------------
      if (
        incomingObj?.[pendingId]?.server?.response &&
        updatedMessages.length > 0
      ) {
        setMessagePending(false);

        // We only add an incoming response if the last message was a prompt
        const lastMessage = updatedMessages[updatedMessages.length - 1];
        if (lastMessage?.type === "prompt") {
          const newData = incomingObj[pendingId]?.server;
          if (newData) {
            updatedMessages.push(newData);
            setPendingId(null);
          }
        }
      }

      // Update the state for the correct room
      newState[currentRoomId] = updatedMessages;
      return newState;
    });
  };

  const handleDeleteRoom = (room) => {
    dispatch({
      type: DISCONNECT_WEBSOCKET,
    });
    dispatch({ type: SG_DELETE_CHAT_ROOM, payload: { id: room } });
    dispatch({ type: SG_GET_CHAT_ROOM });

    // Reset the chat window if the room being deleted is the current room
    if (selectedChatRoom?.id === room) {
      setSelectedChatRoom(null);
      setPromptText("");
      setChatStarted(false);
    }
  };

  // Handle selecting room from history.
  // It will pass the web_chat_id as id.
  const handleSelectRoom = (room) => {
    // Disconnect the current websocket connection
    dispatch({
      type: DISCONNECT_WEBSOCKET,
    });
    const chatRoom = chatTopics.find((topic) => topic.id === room);
    setSelectedChatRoom({
      id: chatRoom.id,
      name: chatRoom.name,
      uuid: null,
    });

    setChatStarted(true);

    const roomMessages = messagesByRoom[chatRoom.id] || [];
    if (roomMessages.length === 0) {
      dispatch({ type: SG_FETCH_CHAT_ROOM, payload: { id: chatRoom.id } });
    }

    dispatch({
      type: SET_WEB_CHAT_ID,
      payload: { id: chatRoom.id, name: chatRoom.name },
    });
  };

  // 4. Handling incoming messages from the websocket
  const handleUnknownError = (message) => {
    if (message && Object.values(message)?.length > 0) {
      Object.values(message).forEach((item) => {
        if (item?.error && !item?.server?.response) {
          setUnknownError(item.error);
        }
      });
    }
  };

  const isUpdateMessage = (data) => data?.type === "update";

  const isValidMessageForChat = (data, room) => {
    /**
     * In your old ChatWindow code, you had logic like:
     * (!data?.web_chat_id && only one message key) ||
     *   data?.web_chat_id === room?.id ||
     *   (data?.web_chat_id && isNaN(data.web_chat_id)) ||
     *   data?.channel?.id
     *
     * Adjust it as needed. The simplest check is:
     */
    if (data?.web_chat_id && room && !room?.id) return true;
    if (!room?.id) return false;
    if (data?.web_chat_id === room.id) return true;
    return false;
  };

  // -------------------------------------------------------------------
  // Main effect to handle incoming WebSocket messages
  // -------------------------------------------------------------------
  useEffect(() => {
    if (!webSocketConnection) return;

    // 1) If it’s an "update" type, skip
    if (isUpdateMessage(webSocketConnection?.data)) return;

    // 2) If valid for the current chat room
    if (isValidMessageForChat(webSocketConnection?.data, selectedChatRoom)) {
      // The server’s entire message object might look like:
      //   {"8a8092d4-804f-4bec-9934-beda24d4e3ed": {...}}
      // So we merge them for the room: selectedChatRoom.id
      // pendingId is used to track the prompt message
      manageRoomMessages(selectedChatRoom.id, webSocketConnection.message);
      setHideLastQuery(true);

      // Scroll to bottom
      setTimeout(() => {
        messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }, [webSocketConnection, selectedChatRoom]);

  // -------------------------------------------------------------------
  // Connect WebSocket if none
  // -------------------------------------------------------------------
  useEffect(() => {
    if (
      !webSocketConnection?.ws &&
      selectedChatRoom?.id &&
      selectedChatRoom?.id !== "temp_room"
    ) {
      dispatch({
        type: SAGA_CONNECT_WEBSOCKET,
        payload: {
          token: chatToken,
          name: selectedChatRoom?.name,
          web_chat_id: selectedChatRoom?.id,
        },
      });
    }
  }, [dispatch, webSocketConnection, selectedChatRoom, chatToken]);

  // Function to start a new conversation
  const startNewConversation = () => {
    setChatHistory([]);
    setSelectedChatRoom(null);
    setPromptText("");
    setChatStarted(false);
    // Reset params.
    setSelectedParams(null);
    dispatch({ type: CLEAR_CHAT_MESSAGES });
    dispatch({ type: SET_NEW_FILTERS, payload: [] });
    // Disconnect the current websocket connection
    dispatch({
      type: DISCONNECT_WEBSOCKET,
    });
  };

  /**
   * This function handles the full logic of:
   * 1) Checking if we are already loading
   * 2) Setting loading/chatStarted states
   * 3) Building and adding prompt to history
   * 4) Calling sendChatMessage to handle the "has WS or not?" logic
   */
  const handleChatAPI = async (promptQuery, feedbackData, feedbackParams) => {
    if (loading) return; // 1. Guard condition

    setLoading(true); // 2. Loading + chat started
    setChatStarted(true);

    try {
      // 3. Build the params object
      const params = buildParams({ ...feedbackParams });

      // 4. Optionally add prompt to local chat history (if not already a prompt)
      addPromptToHistoryIfNeeded(chatHistory, setChatHistory, promptQuery);

      // 5. Actually dispatch the WS message
      await sendChatMessage({ promptQuery, feedbackData, params });
    } catch (err) {
      console.error("Error in handleChatAPI:", err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Checks if a WebSocket connection already exists,
   * then dispatches the correct Redux action.
   */
  const sendChatMessage = async ({ promptQuery, feedbackData, params }) => {
    // Change pending state to true, update if an error occurs.
    setMessagePending(true);

    if (webSocketConnection?.ws) {
      // If there's an existing WS connection
      const msgId = uuidv4();
      setPendingId(msgId);

      dispatch({
        type: SAGA_MESSAGE_WEBSOCKET,
        payload: {
          name: selectedChatRoom?.name, // or some other name if needed
          web_chat_id: selectedChatRoom?.id,
          id: msgId,
          message: promptQuery,
        },
      });

      // Add to the pending messages if your app logic needs that
      dispatch({
        type: ADD_PENDING_MESSAGE,
        payload: {
          id: msgId,
          web_chat_id: selectedChatRoom?.id,
        },
      });

      // This should also be added to the local chat history
      manageRoomMessages(selectedChatRoom?.id, null, {
        id: msgId,
        message: promptQuery,
      });
    } else {
      // If we do NOT have a WS connection, we start one and send a message
      const name = generateTopicName(promptQuery);
      const msgId = uuidv4();

      // Add the message to the local chat history
      addPromptToHistoryIfNeeded(chatHistory, setChatHistory, promptQuery);

      manageRoomMessages(null, null, { id: msgId, message: promptQuery });
      dispatch({
        type: SAGA_START_AND_MESSAGE_WEBSOCKET,
        payload: {
          token: chatToken,
          name,
          message: {
            id: msgId,
            message: promptQuery,
          },
          contextData: feedbackData,
          params,
        },
      });
      // Clear selected filters + set default question for the new conversation
      dispatch({
        type: SET_SELECTED_QUESTION,
        payload: { title: "Overall Culture", level: 0, id: 0 },
      });

      dispatch({ type: SET_NEW_FILTERS, payload: [] });
    }
    setPromptText("");
  };

  // The main method to initiate a chat, exposed by the context
  // This could be called from the UI with (promptQuery, feedbackData)
  const startChat = async (promptQuery, feedbackData, feedbackParams) => {
    // If you want a fresh conversation each time, you can optionally clear existing:
    // startNewConversation();

    await handleChatAPI(promptQuery, feedbackData, feedbackParams);
  };

  // Function to fetch survey data
  const fetchSurveyData = () => {
    dispatch({
      type: SG_GET_SURVEY_QUESTIONS,
      payload: `survey_type=6&status=2`,
    });
  };

  // Function to handle errors (example)
  const handleError = (error) => {
    setError(error);
    setLoading(false);
  };

  // -------------------------------------------------------------------
  // Additional helper for pending messages
  // -------------------------------------------------------------------
  const getPendingMessage = (ws) => {
    if (!ws?.channel?.id || !ws?.message) return null;
    const webChatId = ws.channel.id;
    let pending = null;
    for (const key in ws.message) {
      const item = ws.message[key];
      if (
        item?.web_chat_id === webChatId &&
        !item?.server?.response &&
        !item?.error
      ) {
        pending = item;
      }
    }
    return pending;
  };

  const getPromptInfo = (wsMsg) => {
    // or just move logic from ChatWindow
    return wsMsg?.params?.usedPrompt || null;
  };

  const getPromptQues = (msg) => {
    return msg?.params?.usedPrompt?.ques || msg?.message;
  };

  return (
    <CopilotDataContext.Provider
      value={{
        promptText,
        setPromptText,
        chatHistory,
        setChatHistory,
        selectedChatRoom,
        setSelectedChatRoom,
        startNewChat: startNewConversation,
        fetchSurveyData,
        sendChatMessage,
        loading,
        error,
        handleError,
        chatStarted,
        startChat,
        messagesByRoom,
        // Methods
        handleChatAPI,
        getPendingMessage,
        getPromptInfo,
        getPromptQues,
        messagePending,
        messagesEndRef,
        chatTopics,
        handleSelectRoom,
        handleDeleteRoom,
        selectedParams,
      }}
    >
      {children}
    </CopilotDataContext.Provider>
  );
};;

// PropTypes validation
CopilotDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
