import React, { useState, useRef } from "react";
import styled from "styled-components";

const MIN_WIDTH_PERCENT = 5;

const FeedbackChart = ({
  data,
  tagline,
  onSelect,
  selectedSentiments = [],
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const barContainerRef = useRef(null);

  const filteredData = data.filter((item) => item.value > 0);
  const total = filteredData.reduce((sum, current) => sum + current.value, 0);
  const percentages = filteredData.map((item) => (item.value / total) * 100);
  const adjustedPercentages = adjustSmallBars(percentages);

  const handleItemClick = (index, item) => {
    onSelect(item?.name, index);
  };

  if (filteredData?.length === 0) {
    return null;
  }

  return (
    <ChartContainer>
      {tagline && tagline}

      <BarContainer ref={barContainerRef}>
        {filteredData.map((item, index) => (
          <BarSection
            key={index}
            adjustedPercentages={adjustedPercentages[index]}
          >
            <Bar
              color={item.color}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              dimmed={
                !(
                  hoveredIndex === index ||
                  selectedSentiments?.includes(item.name)
                ) &&
                (hoveredIndex !== null || selectedSentiments?.length > 0)
              }
              onClick={() => handleItemClick(index, item)}
            >
              <Percentage>{item.percent}%</Percentage>
            </Bar>
          </BarSection>
        ))}
      </BarContainer>

      {/* Legend Component */}
      <LegendContainer>
        {filteredData.map((item, index) => (
          <LegendItem
            key={index}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
            onClick={() => handleItemClick(index)}
            dimmed={
              !(
                hoveredIndex === index ||
                selectedSentiments?.includes(item.name)
              ) &&
              (hoveredIndex !== null || selectedSentiments?.length > 0)
            }
          >
            <ColorCircle color={item.color} />
            <LegendText>{item.name}</LegendText>
          </LegendItem>
        ))}
      </LegendContainer>
    </ChartContainer>
  );
};

const adjustSmallBars = (percentages) => {
  const smallBars = percentages.map((p) =>
    p < MIN_WIDTH_PERCENT ? MIN_WIDTH_PERCENT : p
  );
  const totalSmallBarWidth = smallBars.reduce(
    (sum, p, i) =>
      sum + (percentages[i] < MIN_WIDTH_PERCENT ? MIN_WIDTH_PERCENT : 0),
    0
  );
  const totalOriginalSmallBarWidth = percentages.reduce(
    (sum, p) => sum + (p < MIN_WIDTH_PERCENT ? p : 0),
    0
  );

  const adjustment = totalOriginalSmallBarWidth - totalSmallBarWidth;
  const largeBars = percentages.map((p) => (p >= MIN_WIDTH_PERCENT ? p : 0));
  const totalLargeBarWidth = largeBars.reduce((sum, p) => sum + p, 0);

  return percentages.map((p) => {
    if (p >= MIN_WIDTH_PERCENT) {
      const newPercentage =
        (p / totalLargeBarWidth) * (totalLargeBarWidth + adjustment);
      return newPercentage;
    }
    return MIN_WIDTH_PERCENT;
  });
};

// Styled components
const ChartContainer = styled.div`
  position: relative;
  background-color: #f9fafb;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  margin: 0 auto;
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px; // Space to avoid overlap with bars
`;

const Label = styled.div`
  font-size: 12px;
  color: #f1f1f1;
  background-color: #333;
  padding: 4px 8px;
  border-radius: 4px;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s ease;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
`;

const BarContainer = styled.div`
  display: flex;
  height: 100%;
  border-radius: 8px;
  gap: 5px;
  justify-content: space-between;
  margin-bottom: 20px; // Space for the legend
`;

const Bar = styled.div`
  width: 100%;
  background-color: ${(props) => props.color};
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
  transition: width 0.3s ease, opacity 0.3s ease;
  border-radius: 8px;
  padding-left: 10px;
  opacity: ${(props) => (props.dimmed ? 0.3 : 1)};
  cursor: pointer;
  min-height: 40px;
`;

const BarSection = styled.div`
  width: ${(props) => props.adjustedPercentages}%;
`;

const Percentage = styled.span`
  font-size: 14px;
`;

const LabelWrapper = styled.div`
  width: ${(props) => props.width}%;
  display: flex;
  justify-content: center;
`;

// Legend Styled Components
const LegendContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: ${(props) => (props.dimmed ? 0.3 : 1)};
  transition: opacity 0.3s ease;
`;

const ColorCircle = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  margin-right: 8px;
`;

const LegendText = styled.span`
  font-size: 14px;
  color: #333;
`;

export default FeedbackChart;