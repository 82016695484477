import React, { useState, useEffect, useContext, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Icon } from 'semantic-ui-react';

import { CopilotContext, CopilotProvider } from '../CopilotContext';
import ROI from '../Functions/ROI';
import { formatElements } from '../Functions/parseText';
import AICard from '../Components/AICard';
import Suggestions from '../Components/SuggestionsOutcomes';
import FollowUpCard from '../Components/FollowUpCard';
import { RunThematicOutcome } from '../Functions/ThematicsAnalysis';

function ROIHomeComponent({ goHome, initialSession, onSaveSession }) {
  const scrollRef = useRef(null);
  const endOfMessagesRef = useRef(null);

  const {
    messages,
    setMessages,
    phase,
    clearMessages,
    setPromptText,
    promptText,
    executeProcess
  } = useContext(CopilotContext);

  const [started, setStarted] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [suggestionHistory, setSuggestionHistory] = useState([]);
  const [followUp, setFollowUp] = useState(false);

  /**
   * 1) Load initialSession if provided
   */
  useEffect(() => {
    if (initialSession) {
      if (initialSession.messages?.length) {
        setMessages(initialSession.messages);
        setStarted(true);
      }
      if (initialSession.suggestionHistory) {
        setSuggestionHistory(initialSession.suggestionHistory);
      }
    }
  }, [initialSession, setMessages]);

  /**
   * 2) If user sets a new prompt & no existing messages, start
   */
  useEffect(() => {
    if (prompt && !started && messages.length === 0) {
      setStarted(true);
      setMessages((prev) => [...prev, { role: 'function', content: 'Running ROI Analysis' }]);
    }
  }, [prompt, started, messages, setMessages]);

  /**
   * 3) If user triggers a follow-up, add that once
   */
  useEffect(() => {
    if (followUp) {
      setMessages((prev) => [
        ...prev,
        { role: 'follow_up', content: 'Running thematic analysis on feedback' }
      ]);
    }
  }, [followUp, setMessages]);

  /**
   * 4) Scroll to bottom each time messages change
   */
  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  /**
   * 5) Called when user picks new data/prompt from <ROI>
   */
  const registerHistory = (data, promptString, analysisBreakdown) => {
    setPrompt(promptString);
    setSuggestionHistory((prev) => [...prev, { data, analysisBreakdown }]);
  };

  /**
   * 6) Single callback at end of streaming
   */
  const handleStreamingDone = (finalMessages) => {
    onSaveSession?.({
      type: 'roi',
      messages: finalMessages,
      suggestionHistory
    });
  };

  /**
   * 7) goBack => reset everything
   */
  const goBack = () => {
    clearMessages();
    setPromptText('');
    setPrompt('');
    setStarted(false);
  };

  /**
   * 8) If not started and we have no messages, show "intro"
   */
  if (!started && messages.length === 0) {
    return (
      <Container>
        {/* <BackButton onClick={goHome}>
          <Icon style={{ marginRight: 10 }} name="arrow left" />
          Back
        </BackButton> */}
        <ROI
          intro={true}
          onClick={({ data, prompt, organized_feedback }) => registerHistory(data, prompt, organized_feedback)}
        />
      </Container>
    );
  }

  return (
    <Container ref={scrollRef}>
      {/* <BackButton onClick={goBack}>
        <Icon style={{ marginRight: 10 }} name="arrow left" />
        Back
      </BackButton> */}
      <Title>ROI Model Workflow</Title>

      <Content>
        <MessageContainer>
          {messages.map((msg, i) => {
            // ---- If it's a function message -> <AICard> ----
            if (msg.role === 'function') {
              // Check if there's an assistant message AFTER this function
              const hasAssistantAfter = messages
                .slice(i + 1)
                .some((m) => m.role === 'assistant');

              return (
                <AICard
                  key={i}
                  alreadyRun={hasAssistantAfter}
                  start={() => {
                    // Provide onDone callback
                    executeProcess({ prompt, processName: 'ROI Analysis' }, {
                      onDone: handleStreamingDone
                    });
                  }}
                />
              );
            }

            // ---- If it's a follow_up -> <FollowUpCard> ----
            if (msg.role === 'follow_up') {
              return (
                <FollowUpCard
                  key={i}
                  start={() => {
                    RunThematicOutcome(
                      (args, cb) => executeProcess(args, cb),
                      followUp,
                      { onDone: handleStreamingDone }
                    );
                  }}
                />
              );
            }

            // ---- Otherwise (assistant/user) ----
            const retrievedSuggestion = suggestionHistory[suggestionHistory.length - 1];
            return (
              <Bubble key={i} role={msg.role}>
                {formatElements(msg.content)}
                {phase === 'idle' &&
                  i + 1 === messages.length &&
                  retrievedSuggestion && (
                    <Suggestions
                      data={retrievedSuggestion}
                      selectFollowUp={(d) => setFollowUp({ data: retrievedSuggestion, selection: d })}
                    />
                  )}
              </Bubble>
            );
          })}
          <div ref={endOfMessagesRef} />
        </MessageContainer>
{/* 
        <SettingsArea>
          <ROI
            intro={false}
            onClick={({ data, prompt, organized_feedback }) => registerHistory(data, prompt, organized_feedback)}
          />
        </SettingsArea> */}
      </Content>
    </Container>
  );
}

export default function ROIHome(props) {
  return (
    <CopilotProvider>
      <ROIHomeComponent {...props} />
    </CopilotProvider>
  );
}

// ------------------------ Styles ------------------------
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  font-family: 'Raleway', sans-serif;
  padding: 20px;
  padding-top:0px;
  width: 100%;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color:#2A3039;

`;

const Content = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: center;
  margin-top:20px;
  width:100%;
`;

const MessageContainer = styled.div`
  width:100%;
  max-width:700px;
  padding-bottom:150px;
`;

const Bubble = styled.div`
  background-color: ${(props) => (props.role === 'assistant' ? '#F8FAFF' : '#dcecff')};
  padding: 20px;
  margin-bottom: 8px;
  border-radius: 6px;
  max-width: 100%;
  align-self: ${(props) => (props.role === 'assistant' ? 'flex-start' : 'flex-end')};
  animation: ${fadeIn} 0.5s forwards;
`;

const SettingsArea = styled.div`
  width: 300px;
  padding: 20px;
  position: fixed;
  right: 0;
`;

const BackButton = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
  cursor: pointer;
  color: grey;
  margin-right: 20px;
  animation: ${fadeIn} 0.5s forwards;
`;
