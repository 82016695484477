import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";

// Components
import EmptyTemplate from "new_components/templates/states/EmptyTemplate";
import Text from "new_components/atoms/Text";
import Button from "new_components/atoms/Button";

// Constants
import colors from "styles/colors";
import typography from "styles/typography";

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 8px;
`;

const EmptyPage = () => {
  const navigation = useNavigate();

  const TitleComponent = () => (
    <TextContainer>
      <Text
        typographyStyle={typography.emptyHeader.regular}
        color={colors.text.dark}
      >
        Build a
      </Text>
      <Text
        typographyStyle={typography.emptyHeader.bold}
        color={colors.primary[500]}
      >
        Pulse AI
      </Text>
      <Text
        typographyStyle={typography.emptyHeader.regular}
        color={colors.text.dark}
      >
        {" "}
        Survey
      </Text>
    </TextContainer>
  );

  return (
    <EmptyTemplate
      heading="AI Toolkit"
      pageTitle="Pulse AI Surveys"
      titleComponent={<TitleComponent />}
      emptyText="Pulse AI keeps your team connected with targeted pulse surveys.  Gather real-time insights on engagement, morale and key issues to build a thriving workplace culture."
      actionRow={
        <Button onClick={() => navigation("survey-selection")}>
          Start Gathering Insights
        </Button>
      }
    />
  );
};

export default EmptyPage;
