import React from "react";
import styled from "styled-components";

// --------------------------------------------------------
// 1) Styled Components
// --------------------------------------------------------

const T1 = styled.h1`
  font-weight: bold;
  font-family: "Raleway";
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const T2 = styled.h2`
  font-weight: bold;
  font-family: "Raleway";
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const T3 = styled.h3`
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Raleway";
`;

const T4 = styled.h4`
  font-weight: bold;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Raleway";
`;

const P = styled.p`
  line-height: 1.7;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: "Raleway";
`;

const CodeBlock = styled.pre`
  background-color: #f4f4f4;
  padding: 10px;
  overflow: auto;
  font-size: 13px;
  line-height: 1.4;
  margin-bottom: 10px;
  font-family: "Courier New", Courier, monospace;
`;

const HR = styled.hr`
  border: 0;
  border-top: 1px solid #ccc;
  margin: 20px 0;
`;

const BlockQuote = styled.blockquote`
  border-left: 4px solid #ccc;
  padding-left: 10px;
  margin: 10px 0;
  color: #555;
  font-family: "Raleway";
`;

const TableWrapper = styled.table`
  border-collapse: collapse;
  margin-bottom: 1em;
  width: 100%;
  font-size: 14px;
  font-family: "Raleway";

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  thead {
    background: #f9f9f9;
  }
`;

const UL = styled.ul`
  margin-bottom: 10px;
  padding-left: 30px;
  font-family: "Raleway";
`;

const OL = styled.ol`
  margin-bottom: 10px;
  padding-left: 30px;
  font-family: "Raleway";
`;

const LI = styled.li`
  margin-bottom: 6px;
  line-height: 1.7;
  font-size: 14px;
`;

// --------------------------------------------------------
// 2) Inline Transformer & Table Helpers
// --------------------------------------------------------

function transformInline(text) {
  // Bold: **text**
  text = text.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");

  // Italics: *text* or _text_
  text = text.replace(/\*(.*?)\*/g, "<i>$1</i>");
  text = text.replace(/_(.*?)_/g, "<i>$1</i>");

  // Inline code: `text`
  text = text.replace(/`([^`]+)`/g, "<code>$1</code>");

  // Links: [text](url)
  text = text.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2" target="_blank">$1</a>');

  // Images: ![alt](url)
  text = text.replace(/\!\[([^\]]*)\]\(([^)]+)\)/g, '<img alt="$1" src="$2" style="max-width:100%;"/>');

  return text;
}

function isTableLine(line) {
  return /^\|.*\|$/.test(line.trim());
}
function isTableSeparator(line) {
  return /^(\|\s*[-:]+\s*)+\|$/.test(line.trim());
}

function renderTable(rows, key) {
  if (!rows.length) return null;
  const headerCells = rows[0].split("|").slice(1, -1).map((h) => h.trim());
  let bodyStartIndex = 1;

  if (rows[1] && isTableSeparator(rows[1])) {
    bodyStartIndex = 2;
  }

  const headers = (
    <thead>
      <tr>
        {headerCells.map((cell, idx) => (
          <th key={`h-${idx}`}>{cell}</th>
        ))}
      </tr>
    </thead>
  );

  const tableBodyRows = rows.slice(bodyStartIndex).map((row, rowIndex) => {
    const cells = row.split("|").slice(1, -1).map((c) => c.trim());
    return (
      <tr key={`r-${rowIndex}`}>
        {cells.map((cell, cellIndex) => (
          <td
            key={`r-${rowIndex}-c-${cellIndex}`}
            dangerouslySetInnerHTML={{ __html: transformInline(cell) }}
          />
        ))}
      </tr>
    );
  });

  return (
    <TableWrapper key={`table-${key}`}>
      {headers}
      <tbody>{tableBodyRows}</tbody>
    </TableWrapper>
  );
}

// --------------------------------------------------------
// 3) Nested Lists: Detection & Recursive Parsing
// --------------------------------------------------------

// Quick test: is this line an ordered list item? e.g. "1. text"
function isOrderedListItem(line) {
  return /^(\s*)(\d+)\.\s+(.*)$/.test(line);
}

// Quick test: is this line an unordered list item? e.g. "- text"
function isUnorderedListItem(line) {
  return /^(\s*)-\s+(.*)$/.test(line);
}

// Return an integer that represents the indentation level
// e.g., # of leading spaces / 2, or some rule you define
function getIndentLevel(line) {
  const match = line.match(/^(\s*)/);
  if (!match) return 0;
  // E.g. for every 2 spaces, we consider it one "level"
  const spaces = match[1].length;
  return Math.floor(spaces / 2);
}

// Extract the item text (remove bullet or "1." prefix) and return {text, number?, isOrdered}
function extractListItemInfo(line) {
  let match = line.match(/^(\s*)(\d+)\.\s+(.*)$/);
  if (match) {
    const number = parseInt(match[2], 10);
    const text = match[3];
    return { isOrdered: true, number, text };
  }

  match = line.match(/^(\s*)-\s+(.*)$/);
  if (match) {
    const text = match[2];
    return { isOrdered: false, number: null, text };
  }

  return null;
}

/**
 * parseListRecursively
 *
 * @param {string[]} lines - array of lines
 * @param {number} startIndex - current index in lines
 * @param {number} parentIndent - indentation level of the *parent*
 * @param {boolean} parentIsOrdered - whether the parent list is ordered
 *
 * @returns {{ listElement: ReactElement, nextIndex: number }}
 */
function parseListRecursively(lines, startIndex, parentIndent, parentIsOrdered) {
  // We'll gather items here
  let items = [];
  let i = startIndex;

  // The "start" number for an ordered list (if we want to respect the first item’s number)
  // We'll fill this once we find the first item
  let startNumber = null;

  while (i < lines.length) {
    const line = lines[i];
    if (!line.trim()) {
      // blank line => break list
      break;
    }

    // 1) Check if it's a list item
    if (!isOrderedListItem(line) && !isUnorderedListItem(line)) {
      // not a list item => break
      break;
    }

    // 2) Check indentation
    const indent = getIndentLevel(line);
    if (indent < parentIndent) {
      // It's a list item but at a *higher* (less indentation) level => end current list
      break;
    } else if (indent > parentIndent) {
      // It's deeper indentation => this belongs to a *child* list
      // We take the last item we added and parse a sub-list
      if (items.length > 0) {
        const lastItem = items[items.length - 1];
        // parse sub-list
        const { listElement, nextIndex } = parseListRecursively(
          lines,
          i,
          indent,
          lastItem.isOrdered // might not always match the parent's type, but let's keep it consistent
        );
        // store the child list in lastItem.subList
        lastItem.subList = listElement;
        i = nextIndex;
        continue;
      } else {
        // If there's no "parent" item to attach to, it’s a weird input scenario
        // We'll break to avoid infinite loop
        break;
      }
    } else {
      // indent === parentIndent => same level as parent
      const info = extractListItemInfo(line);
      if (!info) break; // fail-safe

      if (startNumber === null && info.isOrdered) {
        // capture the first item’s number
        startNumber = info.number;
      }

      // push an item
      items.push({
        isOrdered: info.isOrdered,
        number: info.number,
        text: info.text,
        subList: null,
      });
      i++;
    }
  }

  // Build the actual <ul> or <ol> from items
  // We'll pick the type from the parent's context
  // If parent is ordered, we do <ol>, else <ul>
  // But you can also decide the type from the first item if you prefer
  let listTag = parentIsOrdered ? OL : UL;

  // However, if none of them are ordered, we could do UL, etc.
  // Or if we want to respect the *first item’s* type:
  //    const isFirstOrdered = items[0]?.isOrdered;
  //    listTag = isFirstOrdered ? OL : UL;

  const listProps = {};
  if (parentIsOrdered && startNumber !== null) {
    // If it's an ordered list, set the "start" attribute to the first item’s number
    listProps.start = startNumber;
  }

  const listElement = React.createElement(
    listTag,
    { ...listProps, key: `nested-list-${startIndex}` },
    items.map((item, idx) => {
      const liChildren = [];
      // The main text
      liChildren.push(
        <span
          key={`li-text-${idx}`}
          dangerouslySetInnerHTML={{ __html: transformInline(item.text) }}
        />
      );
      // If there's a nested sub-list
      if (item.subList) {
        liChildren.push(item.subList);
      }

      return <LI key={`li-${idx}`}>{liChildren}</LI>;
    })
  );

  return { listElement, nextIndex: i };
}

/**
 * parseListEntry
 * Checks if the current line is a list item, and if so, parse a nested list from here.
 * Return {element, nextIndex} or null if not a list item.
 */
function parseListEntry(lines, startIndex) {
  const line = lines[startIndex];
  if (!line) return null;
  if (!isOrderedListItem(line) && !isUnorderedListItem(line)) return null;

  // Detect if it's ordered or not
  const info = extractListItemInfo(line);
  if (!info) return null;

  const indent = getIndentLevel(line);

  // Parse recursively from here
  const { listElement, nextIndex } = parseListRecursively(
    lines,
    startIndex,
    indent,
    info.isOrdered // The parent list type is determined by the first item
  );

  return { element: listElement, newIndex: nextIndex };
}

// --------------------------------------------------------
// 4) Main Parsing Function
// --------------------------------------------------------

export function formatElements(rawString) {
  if (!rawString) return null;

  const lines = rawString.split("\n");
  let elements = [];

  let i = 0;
  let inCodeBlock = false;
  let codeBlockContent = [];

  let inTable = false;
  let tableRows = [];

  while (i < lines.length) {
    let line = lines[i] || "";

    // 1) Check for code block delimiters: ```
    if (line.trim().startsWith("```")) {
      // Toggle code block state
      if (!inCodeBlock) {
        inCodeBlock = true;
        i++;
        continue;
      } else {
        // Closing ``` => End code block, render it
        inCodeBlock = false;
        elements.push(
          <CodeBlock key={`code-${i}`}>{codeBlockContent.join("\n")}</CodeBlock>
        );
        codeBlockContent = [];
        i++;
        continue;
      }
    }

    // If we're inside a code block, just collect lines
    if (inCodeBlock) {
      codeBlockContent.push(line);
      i++;
      continue;
    }

    // 2) Tables
    if (isTableLine(line)) {
      if (!inTable) {
        inTable = true;
        tableRows = [];
      }
      tableRows.push(line);
      i++;
      continue;
    } else if (inTable) {
      // If we were in a table, and we've reached a non-table line => close the table
      inTable = false;
      elements.push(renderTable(tableRows, i));
      tableRows = [];
      // do NOT increment i, let next checks handle this line
      continue;
    }

    // 3) Horizontal rule
    if (line.trim().match(/^(-{3,}|\*{3,})$/)) {
      elements.push(<HR key={`hr-${i}`} />);
      i++;
      continue;
    }

    // 4) Block quote
    if (line.trim().startsWith(">")) {
      const quoteText = line.replace(/^>\s?/, "");
      elements.push(
        <BlockQuote
          key={`blockquote-${i}`}
          dangerouslySetInnerHTML={{ __html: transformInline(quoteText) }}
        />
      );
      i++;
      continue;
    }

    // 5) Headings (strip out bold)
    if (line.startsWith("#### ")) {
      const headingText = line.replace(/^####\s*/, "");
      const noBold = headingText.replace(/\*\*(.*?)\*\*/g, "$1");
      elements.push(<T4 key={`t4-${i}`}>{noBold.trim()}</T4>);
      i++;
      continue;
    } else if (line.startsWith("### ")) {
      const headingText = line.replace(/^###\s*/, "");
      const noBold = headingText.replace(/\*\*(.*?)\*\*/g, "$1");
      elements.push(<T3 key={`t3-${i}`}>{noBold.trim()}</T3>);
      i++;
      continue;
    } else if (line.startsWith("## ")) {
      const headingText = line.replace(/^##\s*/, "");
      const noBold = headingText.replace(/\*\*(.*?)\*\*/g, "$1");
      elements.push(<T2 key={`t2-${i}`}>{noBold.trim()}</T2>);
      i++;
      continue;
    } else if (line.startsWith("# ")) {
      const headingText = line.replace(/^#\s*/, "");
      const noBold = headingText.replace(/\*\*(.*?)\*\*/g, "$1");
      elements.push(<T1 key={`t1-${i}`}>{noBold.trim()}</T1>);
      i++;
      continue;
    }

    // 6) Nested List Handling
    // If the line is a list item (ordered or unordered), parse the entire sub-tree
    const listCheck = parseListEntry(lines, i);
    if (listCheck) {
      elements.push(listCheck.element);
      i = listCheck.newIndex; // jump forward
      continue;
    }

    // 7) Fallback: if line is not empty, render paragraph
    if (line.trim().length > 0) {
      elements.push(
        <P
          key={`p-${i}`}
          dangerouslySetInnerHTML={{ __html: transformInline(line) }}
        />
      );
    }

    i++;
  }

  // If we ended while still in a table => close it
  if (inTable && tableRows.length) {
    elements.push(renderTable(tableRows, i));
  }

  return elements;
}
