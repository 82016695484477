import React from 'react';
import styled, { keyframes } from 'styled-components';



const Top = () => {

    return (
        <Container>
            <Title>Innerlogic <Gradient>Methodology</Gradient></Title>
            <Description>
            At Innerlogic, we understand that culture is more than a buzzword — it’s the backbone of your organization’s success. Traditional engagement surveys only provide a static snapshot, whereas our Holistic Culture Index (HCI) delivers a dynamic, comprehensive, and predictive analysis of your organization’s cultural DNA. By connecting culture to critical outcomes like engagement, retention, and productivity, we can help you turn insights into measurable impact.
            </Description>
            
        </Container>
    )

}


export default Top;


const Container = styled.div`
    font-family:"Raleway";
    margin-top:40px;
    padding:20px;
`

const Title = styled.div`
    font-size:40px;
    font-weight:500;
`
const Gradient = styled.span`
    background: linear-gradient(271.1deg, #27CDA7 36.25%, #2D70E2 79.14%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
`;

const Description = styled.div`
    margin-top:30px;
    font-size:16px;
    line-height:1.5;
    font-weight:300;
    color:#2A3039;
    width:80%;
    max-width:800px;
    margin-bottom:20px;
`