import { call, put, takeLatest, all } from "redux-saga/effects";
import * as api from "constants/api";
import {
  CHAT_WORKFLOW_HISTORY_PENDING,
  GET_CHAT_WORKFLOW_HISTORY,
  SG_GET_CHAT_WORKFLOW_HISTORY,
  ADD_CHAT_WORKFLOW_HISTORY,
  SG_ADD_CHAT_WORKFLOW_HISTORY,
  DELETE_CHAT_WORKFLOW_HISTORY,
  SG_DELETE_CHAT_WORKFLOW_HISTORY,
  UPDATE_CHAT_WORKFLOW_HISTORY,
  SG_UPDATE_CHAT_WORKFLOW_HISTORY,
  FETCH_CHAT_WORKFLOW_HISTORY,
  SG_FETCH_CHAT_WORKFLOW_HISTORY,
  CLEAR_CHAT_WORKFLOW_HISTORY,
  ALL_ERRORS,
} from "constants/actions";

function* getChatWorkflowHistory(action) {
  yield put({ type: CHAT_WORKFLOW_HISTORY_PENDING });
  try {
    const response = yield call(api.GET_CHAT_WORKFLOW_HISTORY, action.payload);
    yield put({ type: GET_CHAT_WORKFLOW_HISTORY, payload: response.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

function* addChatWorkflowHistory(action) {
  yield put({ type: CHAT_WORKFLOW_HISTORY_PENDING });
  try {
    const response = yield call(api.ADD_CHAT_WORKFLOW_HISTORY, action.payload);
    yield put({ type: ADD_CHAT_WORKFLOW_HISTORY, payload: response.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

function* deleteChatWorkflowHistory(action) {
  yield put({ type: CHAT_WORKFLOW_HISTORY_PENDING });
  try {
    yield call(api.DELETE_CHAT_WORKFLOW_HISTORY, action.payload);
    yield put({ type: DELETE_CHAT_WORKFLOW_HISTORY, payload: action.payload.id });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

function* updateChatWorkflowHistory(action) {
  yield put({ type: CHAT_WORKFLOW_HISTORY_PENDING });
  try {
    const response = yield call(api.UPDATE_CHAT_WORKFLOW_HISTORY, action.payload);
    yield put({ type: UPDATE_CHAT_WORKFLOW_HISTORY, payload: response.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

function* fetchChatWorkflowHistory(action) {
  yield put({ type: CHAT_WORKFLOW_HISTORY_PENDING });
  try {
    const response = yield call(api.FETCH_CHAT_WORKFLOW_HISTORY, action.payload);
    yield put({ type: FETCH_CHAT_WORKFLOW_HISTORY, payload: response.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export default function* chatWorkflowHistorySaga() {
  yield all([
    takeLatest(SG_GET_CHAT_WORKFLOW_HISTORY, getChatWorkflowHistory),
    takeLatest(SG_ADD_CHAT_WORKFLOW_HISTORY, addChatWorkflowHistory),
    takeLatest(SG_DELETE_CHAT_WORKFLOW_HISTORY, deleteChatWorkflowHistory),
    takeLatest(SG_UPDATE_CHAT_WORKFLOW_HISTORY, updateChatWorkflowHistory),
    takeLatest(SG_FETCH_CHAT_WORKFLOW_HISTORY, fetchChatWorkflowHistory),
  ]);
}