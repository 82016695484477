import React, { useState, useEffect, useContext, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Icon } from 'semantic-ui-react';

import { CopilotContext, CopilotProvider } from '../CopilotContext';
import Risk from '../Functions/Risk';
import { formatElements } from '../Functions/parseText';
import AICard from '../Components/AICard';
import Suggestions from '../Components/Suggestions';
import FollowUpCard from '../Components/FollowUpCard';
import { RunThematic } from '../Functions/ThematicsAnalysis';
import { useSelector, shallowEqual,useDispatch } from 'react-redux';
import { SET_NEW_FILTERS } from "constants/actions";

function RiskHomeComponent({
  goHome,
  initialSession,
  onSaveSession
}) {
  const scrollRef = useRef(null);
  const endOfMessagesRef = useRef(null);
  const dispatch = useDispatch();
  

  // Pull from CopilotContext
  const {
    messages,
    setMessages,
    phase,
    clearMessages,
    setPromptText,
    promptText,
    executeProcess
  } = useContext(CopilotContext);

  const [prompt, setPrompt] = useState('');
  const [started, setStarted] = useState(false);

  const [suggestionHistory, setSuggestionHistory] = useState([]);
  const [selectedFactors, setSelectedFactors] = useState({ type: 'Culture' });
  const [followUp, setFollowUp] = useState(false);
  const selectedFilters = useSelector((state) => state.audit?.new_filters,shallowEqual);

     useEffect(()=>{
        return ()=>{
            dispatch({ type: SET_NEW_FILTERS, payload: [] })
            }
      },[])
  

  /**
   * 1) If we have an initialSession with messages, load them and set started = true
   */
  useEffect(() => {
    if (initialSession) {
      // restore messages
      if (initialSession.messages) {
        setMessages(initialSession.messages);
      }
      // restore selectedFactors
      if (initialSession.selectedFactors) {
        setSelectedFactors(initialSession.selectedFactors);
      }
      // restore suggestionHistory
      if (initialSession.suggestionHistory) {
        setSuggestionHistory(initialSession.suggestionHistory);
      }
       if(initialSession.filters){
              dispatch({ type: SET_NEW_FILTERS, payload: initialSession.filters })
        }
    }
  }, [initialSession, setMessages]);

  /**
   * 2) If user sets a new prompt (and we have NO existing messages), do the "intro start"
   *    Only do this if messages is empty (i.e. brand-new session).
   */
  useEffect(() => {
    if (prompt && !started && messages.length === 0) {
      setStarted(true);
      setMessages(prev => [
        ...prev,
        { role: 'function', content: 'Running Risk Assessment' }
      ]);
    }
  }, [prompt, started, messages, setMessages]);

  /**
   * If user triggers a follow-up, insert that once
   */
  useEffect(() => {
    if (followUp) {
      setMessages(prev => [
        ...prev,
        { role: 'follow_up', content: 'Running thematic analysis on feedback' }
      ]);
    }
  }, [followUp, setMessages]);

  /**
   * Scroll to the bottom each time messages change
   */
  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  /**
   * Called from <Risk> whenever user sets a new data/prompt
   */
  const registerHistory = (data, promptString, analysisBreakdown) => {
    setPrompt(promptString); // store the user's prompt
    setSuggestionHistory(prev => [...prev, { data, analysisBreakdown }]);
  };

  /**
   * "Back" button resets the entire local state & context
   */
  const goBack = () => {
    clearMessages();
    setSelectedFactors({ type: 'Culture' });
    setPromptText('');
    setPrompt('');
    setStarted(false);
  };


  // If the user hasn't started and there's no loaded messages, show the "intro" <Risk> screen
  if (!started && messages.length === 0) {
    return (
      <Container>
        {/* <BackButton onClick={goHome}>
          <Icon style={{ marginRight: 10 }} name="arrow left" />
          Back
        </BackButton> */}
        <Risk
          intro={true}
          selectedFactors={selectedFactors}
          setSelectedFactors={setSelectedFactors}
          onClick={({ data, prompt, analysisBreakdown }) =>
            registerHistory(data, prompt, analysisBreakdown)
          }
        />
      </Container>
    );
  }

  // Otherwise, show the conversation
  return (
    <Container ref={scrollRef}>
      {/* <BackButton onClick={goBack}>
        <Icon style={{ marginRight: 10 }} name="arrow left" />
        Back
      </BackButton> */}
      <Title>Risk Assessment Workflow</Title>

      <Content>
        <MessageContainer>
          {messages.map((msg, i) => {
            if (msg.role === 'function') {

            // Check if there's ANY assistant message after this index:
                const hasAssistantAfter = messages
                .slice(i + 1)
                .some((m) => m.role === 'assistant');

              return (
                <AICard
                key={i}
                // Pass a prop telling AICard if it has "already run"
                alreadyRun={hasAssistantAfter}
                start={() => {
                  // The function that triggers streaming
                  executeProcess({ prompt, processName: 'Risk Assessment' }, {
                    onDone: (finalMessages) => {
                        onSaveSession?.({
                          type: 'risk',
                          messages: finalMessages,
                          selectedFactors,
                          // NEW: store the current suggestionHistory
                          suggestionHistory,
                          filters:selectedFilters
                        });
                      }
                  });
                }}
              />
              );
            }
            if (msg.role === 'follow_up') {
              return (
                <FollowUpCard
                  key={i}
                  start={() => {
                    RunThematic(
                      (args, callbacks) => executeProcess(args, callbacks),
                      followUp,
                      {
                        onDone: (finalMessages) => {
                            onSaveSession?.({
                              type: 'risk',
                              messages: finalMessages,
                              selectedFactors,
                              // NEW: store the current suggestionHistory
                              suggestionHistory,
                            });
                          }
                      }
                    );
                  }}
                />
              );
            }

            const retrievedSuggestion = suggestionHistory[suggestionHistory.length - 1];
            return (
              <Bubble key={i} role={msg.role}>
                {formatElements(msg.content)}
                {phase === "idle" && i + 1 === messages.length && retrievedSuggestion && (
                  <Suggestions
                    data={retrievedSuggestion}
                    selectFollowUp={(d) => setFollowUp({ data: retrievedSuggestion, selection: d })}
                  />
                )}
              </Bubble>
            );
          })}
          <div ref={endOfMessagesRef} />
        </MessageContainer>

        {/* <SettingsArea>
          <Risk
            intro={false}
            selectedFactors={selectedFactors}
            setSelectedFactors={setSelectedFactors}
            onClick={({ data, prompt, analysisBreakdown }) =>
              registerHistory(data, prompt, analysisBreakdown)
            }
          />
        </SettingsArea> */}
      </Content>
    </Container>
  );
}

export default function RiskHome(props) {
  return (
    <CopilotProvider>
      <RiskHomeComponent {...props} />
    </CopilotProvider>
  );
}

// -- styled components, unchanged below --
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-20px);}
  to   { opacity: 1; transform: translateY(0);}
`;

const Container = styled.div`
  font-family: 'Raleway', sans-serif;
  padding:20px;
  padding-top:0px;
  width:100%;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color:#2A3039;

`;
const Content = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: center;
  margin-top:20px;
  width:100%;
`;

const MessageContainer = styled.div`
  width:100%;
  max-width:700px;
  padding-bottom:150px;
`;

const Bubble = styled.div`
  background-color: ${(props) => (props.role === 'assistant' ? '#F8FAFF' : '#dcecff')};
  padding: 20px;
  margin-bottom: 8px;
  border-radius: 6px;
  max-width: 100%;
  animation: ${fadeIn} 0.5s forwards;
`;

const SettingsArea = styled.div`
  width:400px;
  padding:20px;
  position:fixed;
  right:0;
`;

const BackButton = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top:20px;
  cursor: pointer;
  color: grey;
  margin-right:20px;
  opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
`;
