import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

// Components
import Text from "new_components/atoms/Text";
import AILogo from "new_components/molecules/AILogo";
import ChatTemplate from "new_components/templates/ChatTemplate";
import PromptModal from "./PromptModal";
import ChatHistory from "./components/ChatHistory";
import DataSourceCard from "new_components/molecules/DataSourceCard";
// import Button from "new_components/atoms/Button";
import { ICON_SET } from "new_components/atoms/Icon/IconSet";
import Tooltip from "new_components/molecules/Tooltip";
import DescriptionInput from "new_components/molecules/DescriptionInput";
import ChatDialogBox from "./components/PromptDialog";
import ChatResponse from "./components/ChatResponse";
import DataSource from "new_components/organisms/DataSource";
import { useToasts } from "react-toast-notifications";

// Constants
import colors from "styles/colors";
import typography from "styles/typography";
import IconWithBackground from "new_components/molecules/IconWithBackground";

// Data
import {
  CopilotDataProvider,
  useCopilotDataContext,
} from "./Data/CopilotDataContext";
import {
  FeedbackProvider,
  useFeedbackContext,
} from "./Data/FeedbackDataContext";
import { FilterProvider } from "Providers/FilterProvider";
// Data
import {
  FileManagementProvider,
  useFileManagement,
} from "./UploadModalContext";
import DeleteModal from "new_components/organisms/DeleteModal";

// Styles
const CopilotContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 64px;
`;

const SelectedData = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Actions = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 8px;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const PromptOptions = styled.div`
  display: flex;
  gap: 16px;

  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 700px;
  padding-top: 30px;
`;

const MessageSpacing = styled.div`
  margin-bottom: 16px;
`;

// Allowed MIME Types
const mimeToFileType = {
  "application/pdf": "pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "docx",
  "application/msword": "doc",
};

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

// CONSIDER MOVING THIS FUNCTION TO A HELPER FILE
const getOutcomeOptions = (outcomeQ) => {
  return outcomeQ?.questions?.map((q) => {
    return {
      label: q.name,
      value: {
        id: q.id,
        title: q.name,
        level: 3,
        type: "outcome",
      },
    };
  });
};

const generateFactorConfig = (dimData, callback, removeCb) => {
  const factors = dimData?.flatMap(({ factors }) => factors);

  const factorData = {
    label: "Culture",
    value: "culture",
    settings: ["multi"],
    options: factors?.map((factor) => {
      return {
        label: factor?.title,
        value: factor,
        type: "culture",
      };
    }),
    callback: callback,
    removeCb: removeCb, // Add a remove callback
  };

  return [factorData] || [];
};

// Add an extra prop for scrollRef to pass down:
const chatScreen = (
  chatHistory,
  roomId,
  isPending,
  scrollRef,
  dataCards,
  chatStarted
) => {
  if (!chatHistory || !roomId) return null;

  const currentRoomMessages = chatHistory[roomId] || [];

  const containerStyles = chatStarted
    ? {
        overflowY: "visible",
        paddingTop: "80px",
      }
    : {
        overflowY: "scroll",
        paddingTop: "80px",
      };

  return (
    <div style={containerStyles}>
      {/* {Render datasourcecards above users first message} */}
      <SelectedData>{dataCards && dataCards}</SelectedData>

      {currentRoomMessages &&
        currentRoomMessages.map((msg, index) => {
          const isLastMessage = index === currentRoomMessages.length - 1;
          const isNewResponse = isLastMessage && msg.response;

          // Render prompt messages
          if (msg.type === "prompt") {
            return (
              <MessageSpacing key={msg.id || index}>
                <ChatDialogBox message={msg.message} />
              </MessageSpacing>
            );
          }

          // Render server responses
          //** NOTE: This is where we can add different response components */
          if (msg?.response_type || msg?.type === "response") {
            const msgStr = msg?.response || msg?.message;
            return (
              <MessageSpacing key={msg?.id || index}>
                <ChatResponse
                  message={msgStr}
                  isThinking={false}
                  isStreaming={isNewResponse}
                  scrollRef={scrollRef}
                />
              </MessageSpacing>
            );
          }

          return null; // Skip messages not matching the expected structure
        })}

      {isPending && (
        <ChatResponse key="pending" isThinking={true} scrollRef={scrollRef} />
      )}

      {/* 
        *** This is the important part! ***
        Use the scrollRef on this bottom div so that we can call
        scrollRef.current.scrollIntoView() to auto-scroll.
       */}
      <div ref={scrollRef} />
    </div>
  );
};

const Copilot = (props) => {
  const fileInputRef = useRef();
  const addToast = useToasts();
  const {
    setPromptText,
    promptText,
    chatStarted,
    startChat,
    messagesByRoom,
    selectedChatRoom,
    messagePending,
    messagesEndRef,
    chatTopics,
    handleSelectRoom,
    startNewChat,
    handleDeleteRoom: handleDelete,
    selectedParams,
  } = useCopilotDataContext();

  const {
    feedback,
    getFeedbackParams,
    pulseSurveys,
    outcomeQ,
    factorLabels,
    setSelectedFactors,
    handleSelectPulse,
    handleRemovePulse,
    selectedPulses,
    factorCardData,
    setSelectedOutcomes,
    outcomeCardData,
    handleRemoveFactor,
    handleRemoveOutcome,
  } = useFeedbackContext();

  const { uploadFile, addedFile, uploadInProgress } = useFileManagement();

  const dataSourceConfig = [
    {
      label: "Pulse",
      value: "pulse",
      options: pulseSurveys,
      callback: handleSelectPulse,
      removeCb: handleRemovePulse,
    },
    {
      label: "Outcome",
      value: "outcome",
      settings: ["multi"],
      options: getOutcomeOptions(outcomeQ), // Create a function to get the list of outcomes
      callback: setSelectedOutcomes,
      removeCb: handleRemoveOutcome, // Add a remove callback
    },
    ...generateFactorConfig(
      factorLabels,
      setSelectedFactors,
      handleRemoveFactor
    ),
  ];

  const [showPromptModal, setShowPromptModal] = useState(false);
  const [fileSources, setFileSources] = useState([]);
  const [sourceType, setSourceType] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isInvalidFile, setIsInvalidFile] = useState(false);
  const [fileCardData, setFileCardData] = useState([]);
  const [pendingDelete, setPendingDelete] = useState(null);

  useEffect(() => {
    // If a file is added, add it to the fileSources array
    if (addedFile) {
      setFileSources([...fileSources, addedFile]);
      // Add the file to the fileCardData array
      setFileCardData(
        fileCardData.concat({
          type: "file",
          id: addedFile.id,
          title: addedFile.name,
          status: "New",
        })
      );
    }
  }, [addedFile]);

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
    if (uploadInProgress) {
      setIsInvalidFile(true);
    }
  };

  const handleDragLeave = () => {
    setIsDragging(false);
    setIsInvalidFile(false); // Reset error state when drag leaves
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const dataCards = (
    <DataSourceCard
      dataSourceConfig={dataSourceConfig}
      chatStarted={chatStarted}
      // Add the type: factor to the selectedFactors array
      selectedCards={
        selectedParams?.length > 0
          ? selectedParams
          : [
              ...selectedPulses,
              ...factorCardData,
              ...outcomeCardData,
              ...fileCardData,
            ]
      }
      uploadPending={uploadInProgress}
    />
  );

  const chatHeading = (
    <CopilotContainer>
      <AILogo />
      <Text
        typographyStyle={typography.emptyHeader.regular}
        color={colors.text.dark}
      >
        Welcome to Copilot
      </Text>
      <Text
        typographyStyle={typography.body.regular}
        color={colors.text.gray}
        style={{ maxWidth: "400px" }}
      >
        Copilot is an AI assistant that helps you understand your culture and
        engagement data.
      </Text>
    </CopilotContainer>
  );

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    uploadFile(file);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    if (uploadInProgress) {
      addToast.addToast("Please wait for the current upload to finish.", {
        appearance: "info",
      });
      return;
    }

    const file = event.dataTransfer.files[0];
    const fileType = file?.type;

    if (mimeToFileType[fileType]) {
      setIsInvalidFile(false); // Reset invalid state
      uploadFile(file); // Handle valid file upload
    } else {
      setIsInvalidFile(true); // Show error state
      // add an error toast
      addToast.addToast("Invalid file type. Please upload a PDF or word doc.", {
        appearance: "error",
      });
    }
  };

  const chatComponent = (
    <ChatContainer chatStarted={chatStarted}>
      {chatStarted &&
        chatScreen(
          messagesByRoom,
          selectedChatRoom?.id,
          messagePending,
          messagesEndRef,
          dataCards,
          chatStarted
        )}
    </ChatContainer>
  );

  const inputBox = (
    <InputContainer>
      <div style={{ marginBottom: 10 }}>
        {!chatStarted && (
          <DataSource
            setSourceType={setSourceType}
            dataSourceConfig={dataSourceConfig}
          />
        )}
      </div>

      <DescriptionInput
        value={promptText}
        onChange={(e) => setPromptText(e.target.value)}
        placeholder="Ask me anything..."
        handleDragOver={handleDragOver}
        handleDragLeave={handleDragLeave}
        handleDrop={handleDrop}
        isDragging={isDragging}
        sourceCards={dataCards}
        isInvalidFile={isInvalidFile}
        chatStarted={chatStarted}
        uploadRef={
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            accept={Object.keys(mimeToFileType).join(",")}
          />
        }
        handleEnter={() => {
          startChat(promptText, feedback, getFeedbackParams(fileSources));
        }}
        bottomActionRow={
          <Actions>
            <PromptOptions>
              <Tooltip key="uploadTooltip" tooltipText="Upload a file">
                <IconWithBackground
                  name={ICON_SET.upload}
                  size={24}
                  onClick={() => handleBrowseClick()}
                />
              </Tooltip>
              <Tooltip
                key="promptTooltip"
                tooltipText="Open the prompt library"
              >
                <IconWithBackground
                  name={ICON_SET.layers}
                  size={24}
                  onClick={() => setShowPromptModal(true)}
                />
              </Tooltip>
            </PromptOptions>
            <IconWithBackground
              name={ICON_SET.send}
              size={24}
              onClick={() => startChat()}
            />
          </Actions>
        }
      />
    </InputContainer>
  );

  // Whenever messages change or we send a new message, scroll to the bottom
  React.useEffect(() => {
    if (chatStarted && messagesEndRef.current) {
      setTimeout(() => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }, [chatStarted, messagesByRoom, messagePending, selectedChatRoom]);

  return (
    <ChatTemplate
      chatHeading={chatHeading}
      chatComponent={chatComponent}
      chatStarted={chatStarted}
      activeRoom={selectedChatRoom}
      startNewChat={startNewChat}
      inputBox={inputBox}
      submenuData={{
        sectionOne: (
          <ChatHistory
            setPendingDelete={setPendingDelete}
            chatTopics={chatTopics}
            activeChatId={selectedChatRoom?.id}
            onChatSelect={handleSelectRoom}
          />
        ),
        sectionTwo: null,
      }}
    >
      <DeleteModal
        itemName="chat history"
        isOpen={!!pendingDelete}
        onClose={() => setPendingDelete(null)}
        onConfirm={() => {
          handleDelete(pendingDelete);
          setPendingDelete(null);
        }}
      />
      <PromptModal
        isOpen={showPromptModal}
        onClose={() => setShowPromptModal(false)}
        onConfirm={(selectedPrompt) => {
          setPromptText(selectedPrompt);
          setShowPromptModal(false);
        }}
      />
    </ChatTemplate>
  );
};

// PropTypes
Copilot.propTypes = {
  // ...
};

const CopilotPage = () => {
  return (
    <CopilotDataProvider>
      <FeedbackProvider>
        <FilterProvider>
          <FileManagementProvider>
            <Copilot />
          </FileManagementProvider>
        </FilterProvider>
      </FeedbackProvider>
    </CopilotDataProvider>
  );
};

export default CopilotPage;
