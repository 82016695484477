import React, { useState } from 'react';
import { Card, Heading, Paragraph, Button, ToggleLink, Table, Spacer } from '../styles/Common';

const UserSyncPreview = ({ slackUsers, internalUsers, mappings, onChangeMappings, onConfirm }) => {
  const [showDetails, setShowDetails] = useState(false);
  const numToSync = mappings.length;

  return (
    <Card>
      <Heading>Review User Sync</Heading>
      <Paragraph>We’ve identified {numToSync} users that can be synchronized with Slack. You can proceed with the suggested mappings or adjust them before syncing.</Paragraph>
      
      <Paragraph><ToggleLink onClick={() => setShowDetails(!showDetails)}>
        {showDetails ? 'Hide Details' : 'Show Mappings'}
      </ToggleLink></Paragraph>

      {showDetails && (
        <Table>
          <thead>
            <tr>
              <th>Slack User</th>
              <th>Internal User</th>
            </tr>
          </thead>
          <tbody>
            {mappings.map((mapItem, i) => (
              <tr key={mapItem.slackUser.id}>
                <td>{mapItem.slackUser.name}</td>
                <td>
                  <select
                    value={mapItem.internalUser?.id || ''}
                    onChange={(e) => {
                      const newUser = internalUsers.find(u => u.id === e.target.value) || null;
                      const updated = [...mappings];
                      updated[i] = { ...updated[i], internalUser: newUser };
                      onChangeMappings(updated);
                    }}
                  >
                    <option value="">-- Select Internal User --</option>
                    {internalUsers.map(user => (
                      <option key={user.id} value={user.id}>{user.name}</option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <Spacer height="20px" />
      <Button primary onClick={onConfirm}>Confirm and Sync</Button>
    </Card>
  );
};

export default UserSyncPreview;
