// Does not inherit the BaseTemplate.
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Components
import SubMenu from "new_components/organisms/SubMenu";
import ContentSection from "new_components/organisms/SubScrollingContent";

// Constants
import colors from "styles/colors";

// Full Page Wrapper
const LayoutWrapper = styled.div`
  display: flex;
  height: 100%; /* Full height to make the layout fixed */
  overflow: hidden; /* Prevent page scrolling */
`;

// Sidebar (SubMenu) Wrapper
const SidebarWrapper = styled.div`
  flex: 0 0 350px; /* Fixed width for the submenu */
  height: 100%; /* Full height for independent scrolling */
  overflow-y: auto; /* Enable vertical scrolling for the SubMenu */
  background-color: ${colors.background.white};
  padding: 16px;

  /* Custom Scrollbar */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.neutral[200]};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.neutral[500]};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.neutral[600]};
  }
`;

// Separator (Vertical Line)
const Separator = styled.div`
  width: 1px;
  background-color: ${colors.neutral[500]};
`;

// Content Area Wrapper
const ContentWrapper = styled.div`
  flex: 1; /* Take up remaining space */
  height: 100%; /* Full height for independent scrolling */
  overflow-y: auto; /* Enable vertical scrolling for the content */
  padding: 24px;

  /* Custom Scrollbar */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.neutral[200]};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.neutral[500]};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.neutral[600]};
  }
`;

// Main Layout Component
const ActionMenuLayout = ({
  menuItems,
  searchable,
  type = "multi", // Default to "multi" if not specified
  onItemSelect,
  loadingComponent,
  loading = false,
  selected,
  actionRow,
}) => {
  const contentWrapperRef = useRef(null);

  // State to manage active menu item when type is "single"
  const [activeItemId, setActiveItemId] = useState(
    menuItems[0]?.id || menuItems[0]?.items[0]?.id
  );

  useEffect(() => {
    if (selected) {
      setActiveItemId(selected);
    }
  }, [selected]);

  const handleMenuItemClick = (id) => {
    setActiveItemId(id);

    if (type === "single" && contentWrapperRef.current) {
      contentWrapperRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }

    // Trigger the onItemSelect callback with the newly selected item
    if (onItemSelect) {
      let selectedItem = menuItems.find((item) => item.id === id);

      if (!selectedItem) {
        // Loop through grouped menu items to find the selected item
        menuItems.forEach((group) => {
          if (group.items && !selectedItem) {
            selectedItem = group.items.find((item) => item.id === id);
          }
        });
      }

      if (selectedItem) {
        onItemSelect(selectedItem);
      }
    }
  };

  return (
    <>
      {actionRow && <div>{actionRow}</div>}

      <LayoutWrapper>
        {/* Sidebar Menu */}
        {/* Render actionRow if present */}
        <SidebarWrapper>
          <SubMenu
            categories={menuItems}
            contentRef={contentWrapperRef}
            searchable={searchable}
            type={type}
            activeItemId={activeItemId}
            onMenuItemClick={handleMenuItemClick}
          />
        </SidebarWrapper>
        <Separator />

        {/* Content Area */}
        <ContentWrapper ref={contentWrapperRef}>
          {!loading && (
            <ContentSection
              categories={menuItems}
              type={type}
              activeItemId={activeItemId}
            />
          )}
          {loading && loadingComponent && loadingComponent}
        </ContentWrapper>
      </LayoutWrapper>
    </>
  );
};

ActionMenuLayout.propTypes = {
  backAction: PropTypes.func, // Function for back button action
  backButtonText: PropTypes.string, // Customizable text for back button
  actionRowComponent: PropTypes.node, // Custom component in the action row
  menuItems: PropTypes.arrayOf(
    PropTypes.oneOfType([
      // For flat list of categories
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        categoryTitle: PropTypes.string.isRequired,
        categoryContent: PropTypes.node.isRequired,
        categoryIcon: PropTypes.string,
      }),
      // For grouped categories
      PropTypes.shape({
        groupTitle: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            categoryTitle: PropTypes.string.isRequired,
            categoryContent: PropTypes.node.isRequired,
            categoryIcon: PropTypes.string,
          })
        ).isRequired,
      }),
    ])
  ).isRequired, // Menu items for the middle menu
  heading: PropTypes.string, // Heading text
  pageTitle: PropTypes.string, // Page title
  subTitle: PropTypes.string, // Subtitle
  searchable: PropTypes.bool, // Whether the menu is searchable
  type: PropTypes.oneOf(["multi", "single"]), // Layout type
};

export default ActionMenuLayout;
