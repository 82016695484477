import React, { useState, useEffect } from "react";
import {
  Card,
  Heading,
  Paragraph,
  Button,
  Table,
  SyncIcon,
  Input,
  FlexRow,
} from "ProtoType/Slack/styles/Common";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_GET_EMPLOYEE_SLACK,
  SG_DELETE_SLACK_ID_TO_EMPLOYEE_ID,
} from "constants/actions";

const SlackAppSettings = () => {
  const dispatch = useDispatch();
  const [slackUsers, setSlackUsers] = useState([]);
  const [searchEmail, setSearchEmail] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { get_employee_slack, get_slack_calls } = useSelector(
    (state) => ({
      get_employee_slack: state.employee_slack,
      get_slack_calls: state.slack_calls,
    }),
    shallowEqual
  );
  const { slackUserUpdated } = get_slack_calls;

  useEffect(() => {
    if (get_employee_slack?.employee_slack) {
      setSlackUsers(get_employee_slack?.employee_slack?.results);
      setTotalPages(Math.ceil(get_employee_slack?.employee_slack?.count / 10)); // Assuming 10 items per page
    }
  }, [get_employee_slack]);

  useEffect(() => {
    fetchSlackUsers();
  }, [dispatch, slackUserUpdated, currentPage]);

  const fetchSlackUsers = () => {
    dispatch({
      type: SG_GET_EMPLOYEE_SLACK,
      payload: `page=${currentPage}&email=${searchEmail}`,
    });
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchSlackUsers();
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  function onRemoveSyncedUser(id) {
    dispatch({
      type: SG_DELETE_SLACK_ID_TO_EMPLOYEE_ID,
      payload: { id },
    });
  }

  const handleSync = () => {
    fetchSlackUsers();
  };

  return (
    <Card>
      <Heading>
        Synced User Accounts{" "}
        <SyncIcon onClick={handleSync} title="Start Sync Process">
          🔄 Refresh
        </SyncIcon>
      </Heading>

      <FlexRow>
        <Input
          type="text"
          placeholder="Search by email"
          value={searchEmail}
          onChange={(e) => setSearchEmail(e.target.value)}
          style={{
            borderColor: "#007BFF",
            marginRight: "8px",
            marginTop: "15px",
          }} // Highlight border and add margin
        />
        <Button
          onClick={handleSearch}
          style={{ backgroundColor: "#007BFF", color: "#FFF" }}
        >
          Search
        </Button>
      </FlexRow>

      {slackUsers?.length === 0 ? (
        <Paragraph>No user accounts are currently synced with Slack.</Paragraph>
      ) : (
        <>
          <Paragraph>
            These users are currently synchronized between Slack and your
            internal system. You can remove synced users if needed.
          </Paragraph>
          <Table>
            <thead>
              <tr>
                <th>Slack Name</th>
                <th>Internal Name</th>
                <th>Email</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {slackUsers &&
                slackUsers.map((user) => (
                  <tr key={user.id}>
                    <td>{user.slack_display_name}</td>
                    <td>{user.employee_name}</td>
                    <td>{user.employee_email}</td>
                    <td>{user.slack_id ? "Linked" : "Not linked"}</td>
                    <td>
                      {user.slack_id && (
                        <Button
                          style={{
                            background: "#FFF",
                            color: "#DC3545",
                            borderColor: "#DC3545",
                          }}
                          onClick={() => onRemoveSyncedUser(user.id)}
                        >
                          Remove
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <div>
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </Card>
  );
};

export default SlackAppSettings;
