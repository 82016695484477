import {
  EMPLOYEE_SLACK_PAGE,
    GET_EMPLOYEE_SLACK,
    DELETE_EMPLOYEE_SLACK,
    FETCH_EMPLOYEE_SLACK,
    EDIT_EMPLOYEE_SLACK,
    ADD_EMPLOYEE_SLACK,
    PENDING_EMPLOYEE_SLACK,
    CLEAR_EMPLOYEE_SLACK,
    CLEAR_SELECTED_EMPLOYEE_SLACK,
    CLEAR_ALL,
  } from "constants/actions";
  
  const initialState = {
    employee_slack: [],
    pageNo: 1,
    user_slack: {},
    pending: false,
    created: false,
  };
  
  export default function EmployeeSlack(state = initialState, action) {
    switch (action.type) {
      case PENDING_EMPLOYEE_SLACK:
        return {
          ...state,
          pending: true,
        };
        case EMPLOYEE_SLACK_PAGE:
          return {
            ...state,
            pageNo: action.payload,
          }
      case GET_EMPLOYEE_SLACK:
        return {
          ...state,
          employee_slack: action.payload,
          pending: false,
          created: false,
        };
  
      case FETCH_EMPLOYEE_SLACK:
        return {
          ...state,
         [action.payload.employee]: action.payload,
         user_slack: action.payload,
          pending: false,
          created: false,
        };
  
      case EDIT_EMPLOYEE_SLACK:
        if (state.employee_slack.filter((an) => an.id === action.payload.id)[0]) {
          state = {
            ...state,
            employee_slack: state.employee_slack.filter(
              (rw) => rw.id !== action.payload.id
            ),
          };
        }
        return {
          ...state,
          created: false,
          employee_slack: [...state.employee_slack, action.payload],
        };
  
      case DELETE_EMPLOYEE_SLACK:
        return {
          ...state,
          employee_slack: state.employee_slack.filter(
            (row) => row.id !== action.payload.id
          ),
        };
  
      case ADD_EMPLOYEE_SLACK:
        return {
          ...state,
          created: action.payload,
          employee_slack: [...state.employee_slack, action.payload],
        };
  
      case CLEAR_EMPLOYEE_SLACK:
        return {
          ...state,
          employee_slack: [],
          created: false,
        };
  
      case CLEAR_SELECTED_EMPLOYEE_SLACK:
        return {
          ...state,
          selectedFile: false,
        };
  
      case CLEAR_ALL:
        return {
          ...state,
          employee_slack: [],
          created: false,
        };
  
      default:
        return state;
    }
  }