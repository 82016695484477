// SurveyDataContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

// Action (from the snippet)
import {
  SG_FETCH_TASK_SCHEDULE,
  SG_ADD_USER_TASK,
  SG_DELETE_USER_TASK,
  SG_UPDATE_EMAIL_TEMPLATE,
  SG_ADD_EMPLOYEE_REPORT_ACCESS,
  SG_REMOVE_EMPLOYEE_REPORT_ACCESS,
} from "constants/actions";

// Utils
const getRecentResponses = (data, employees) => {
  const responses = {};
  const employeeList = employees?.employees;
  data.task_responses.forEach((response) => {
    const employeeId = response.employee;
    // Find the employee's name, and add it to the response object under the key "employee_full_name"
    // Keep the most recent response for each employee
    const employeeObj = employeeList?.find((emp) => emp.id === employeeId);
    response.employee_full_name = `${employeeObj?.first_name} ${employeeObj?.last_name}`;
    if (
      !responses[employeeId] ||
      response.created_at > responses[employeeId].created_at
    ) {
      responses[employeeId] = response;
    }
  });
  return Object.values(responses);
};

// Create the context
const SurveyDataContext = createContext(null);

// Custom Hook to use the context
export const useSurveyDataContext = () => useContext(SurveyDataContext);

// Provider component
export const SurveyDataProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { addToast } = useToasts();

  const [responses, setResponses] = useState([]);
  const [downloadableReports, setDownloadableReports] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isSurveyCreator, setIsSurveyCreator] = useState(false);
  const [surveyParticipants, setSurveyParticipants] = useState([]);
  const [surveyIsAnonymous, setSurveyIsAnonymous] = useState(false);

  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [hasEmailChanged, setHasEmailChanged] = useState(false);
  const [pendingUserAssignment, setPendingUserAssignment] = useState(null);
  const [newEmailSubject, setNewEmailSubject] = useState("");
  const [newEmailBody, setNewEmailBody] = useState("");

  const [surveyInfo, setSurveyInfo] = useState({
    title: "",
    description: "",
    createdBy: "",
    createdDate: "",
    updatedDate: "",
    surveyIsAnonymous: false,
  });

  // Redux Data
  const {
    fetched_scheduled_task,
    get_employees,
    get_auth,
    pendingReport,
    updated_email_template,
  } = useSelector(
    (state) => ({
      fetched_scheduled_task: state.task_templates?.fetched_scheduled_task,
      get_employees: state.employees,
      get_auth: state.auth,
      pendingReport: state.task_templates?.pendingReport,
      updated_email_template: state.email_templates.updated_email_template,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (fetched_scheduled_task) {
      const emailTemplate = fetched_scheduled_task?.task_template_email;
      setEmailSubject(emailTemplate?.email_subject || "");
      setEmailBody(emailTemplate?.email_content || "");
    }
  }, [fetched_scheduled_task]);

  // 1) Add user(s) to Report Access
  const addEmployeeReportAccess = async (task, employeeIdList) => {
    if (!employeeIdList || employeeIdList.length === 0) return;
    try {
      // Dispatch the Redux action
      await dispatch({
        type: SG_ADD_EMPLOYEE_REPORT_ACCESS,
        payload: {
          task_id: Number(task),
          employee_id_list: employeeIdList,
        },
      });
      addToast("User(s) successfully added to the report.", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      addToast("An error occurred while adding user(s) to the report.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  // 2) Remove user(s) from Report Access
  const removeEmployeeReportAccess = async (id, employeeIdList) => {
    if (!employeeIdList || employeeIdList.length === 0) return;
    try {
      // Dispatch the Redux action
      await dispatch({
        type: SG_REMOVE_EMPLOYEE_REPORT_ACCESS,
        payload: {
          task_id: Number(id),
          employee_id_list: employeeIdList,
        },
      });
      addToast("User access removed from the report.", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      addToast("An error occurred while removing user access.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const saveChangesAndAssignUsers = async (templateId, employeesList) => {
    console.log("saveChangesAndAssignUsers", templateId, employeesList);
    try {
      let emailTaskId = fetched_scheduled_task?.task_template_email?.id;

      if (hasEmailChanged) {
        const emailPayload = {
          email_subject: newEmailSubject,
          email_content: newEmailBody,
          task_template: templateId,
          id: emailTaskId,
        };

        // Update Email Template
        await dispatch({
          type: SG_UPDATE_EMAIL_TEMPLATE,
          payload: emailPayload,
        });
        setPendingUserAssignment({ templateId, employeesList });
        addToast("Updating email template...", {
          appearance: "info",
          autoDismiss: true,
        });
      } else {
        // Directly assign users if no email update is needed
        assignUsersToTask(templateId, employeesList);
      }
    } catch (error) {
      addToast("An error occurred while saving changes.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const assignUsersToTask = async (templateId, employeesList) => {
    try {
      const userTaskPayload = {
        task_id: templateId,
        employee_id_list: employeesList,
      };

      await dispatch({ type: SG_ADD_USER_TASK, payload: userTaskPayload });
      addToast("Users assigned successfully.", {
        appearance: "success",
        autoDismiss: true,
      });
      setPendingUserAssignment(null); // Clear pending assignment
    } catch (error) {
      addToast("An error occurred while assigning users.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  useEffect(() => {
    // Trigger user assignment after email update
    if (updated_email_template && pendingUserAssignment) {
      const { templateId, employeesList } = pendingUserAssignment;
      assignUsersToTask(templateId, employeesList);
    }
  }, [updated_email_template]);

  const handleEmailUpdate = (subject, body) => {
    if (subject !== emailSubject || body !== emailBody) {
      setHasEmailChanged(true);
      setNewEmailSubject(subject);
      setNewEmailBody(body);
    }
  };

  const fetchSurvey = (id) => {
    dispatch({ type: SG_FETCH_TASK_SCHEDULE, payload: id });
  };

  const deleteEmployeeFromSurvey = (templateId, employee) => {
    const structurePayload = {
      task_id: templateId,
      employee_id: [employee],
    };
    dispatch({ type: SG_DELETE_USER_TASK, payload: structurePayload });
    // Add toast message
    addToast("Employee removed from survey", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  // Process the responses once the scheduled_task is fetched
  useEffect(() => {
    if (fetched_scheduled_task?.task_responses && get_employees?.employees) {
      const recent = getRecentResponses(fetched_scheduled_task, get_employees);
      const surveyTitle = fetched_scheduled_task.name;
      // Format the date as Aug 28, 2024
      const createdDate = new Date(
        fetched_scheduled_task.created_at
      ).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
      const updatedDate = new Date(
        fetched_scheduled_task.updated_at
      ).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
      const createdBy =
        fetched_scheduled_task?.task_template_user_tasks?.user_tasks?.find(
          (task) => task.task_type === "created_by"
        )?.name;
      const description =
        fetched_scheduled_task?.task_template_user_tasks?.user_tasks?.find(
          (task) => task.task_type === "description"
        )?.text;

      const participants = Object?.values(
        fetched_scheduled_task?.user_requests?.user_requests || {}
      );

      const questions =
        fetched_scheduled_task?.task_template_user_tasks?.user_tasks?.filter(
          (task) => task.task_type === "question"
        );

      const downloadReports = fetched_scheduled_task?.task_reports;
      if (Object.keys(pendingReport)?.length > 0) {
        downloadReports.push(pendingReport);
      }

      const isAnon =
        fetched_scheduled_task?.task_template_user_tasks?.user_tasks?.find(
          (task) => task.task_type === "survey_is_anonymous"
        )?.value;

      setSurveyIsAnonymous(isAnon);
      setDownloadableReports(downloadReports);
      setQuestions(questions);

      // Loop through participants and add the employee_full_name
      participants.forEach((participant) => {
        const employeeObj = get_employees?.employees?.find(
          (emp) => emp.id === participant.id
        );
        participant.employee_full_name = `${employeeObj?.first_name} ${employeeObj?.last_name}`;
      });

      setSurveyParticipants(participants);

      setSurveyInfo({
        title: surveyTitle,
        description: description,
        createdBy: createdBy,
        createdDate: createdDate,
        updatedDate: updatedDate,
        surveyIsAnonymous: isAnon,
      });

      setResponses(recent);
    }
  }, [fetched_scheduled_task, get_employees, pendingReport]);

  // Determine if the current user created this survey
  useEffect(() => {
    if (Number(get_auth?.employee_id) === fetched_scheduled_task?.created_by) {
      setIsSurveyCreator(true);
    }
  }, [fetched_scheduled_task, get_auth]);

  // A callback for selecting a user & navigating to "Individual" tab
  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };

  // A callback to handle returning to the main survey list
  const goBack = () => {
    navigate("/leader/audit/AI/pulse");
  };

  // We’ll expose these as context values
  return (
    <SurveyDataContext.Provider
      value={{
        id,
        fetched_scheduled_task,
        get_employees,
        responses,
        selectedUser,
        setSelectedUser,
        handleUserSelect,
        isSurveyCreator,
        goBack,
        fetchSurvey,
        surveyInfo,
        surveyParticipants,
        questions,
        downloadableReports,
        deleteEmployeeFromSurvey,
        surveyIsAnonymous,
        handleEmailUpdate,
        emailSubject,
        emailBody,
        saveChangesAndAssignUsers,
        addEmployeeReportAccess,
        removeEmployeeReportAccess,
      }}
    >
      {children}
    </SurveyDataContext.Provider>
  );
};
