import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Components
import Text from "new_components/atoms/Text";
import Icon from "new_components/atoms/Icon";
import { ICON_SET } from "new_components/atoms/Icon/IconSet";

// Constants
import typography from "styles/typography";
import colors from "styles/colors";

const PoweredByLogo = ({
  text = "Powered by generative AI and culture experts.",
}) => {
  return (
    <StyledLogo>
      <Icon name={ICON_SET.aiIcon} color={colors.primary[100]} size={24} />
      <Text
        typographyStyle={typography.smallText.semibold}
        color={colors.text.gray}
      >
        {text}
      </Text>
    </StyledLogo>
  );
};

PoweredByLogo.propTypes = {
  imageSrc: PropTypes.string, // Allows a different image to be passed
  imageWidth: PropTypes.number, // Allows customization of the image width
  text: PropTypes.string, // Allows customization of the "Powered by" text
  style: PropTypes.object, // Allows passing custom styles to the wrapper
};

export default PoweredByLogo;

// Styled Components
const StyledLogo = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #dfdfdf;
  padding: 2px 50px;
  border-radius: 40px;
  flex-direction: row;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  margin-bottom: 16px;
`;
