// styles/Common.js
import styled from 'styled-components';

export const Card = styled.div`
  background: ${(props) => props.theme.colors.surface};
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: ${(props) => props.theme.radii.medium};
  padding: ${(props) => props.theme.spacing(3)};
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

export const Heading = styled.h2`
  color: ${(props) => props.theme.colors.text};
  font-size: ${(props) => props.theme.fontSizes.large};
  margin-bottom: ${(props) => props.theme.spacing(2)};
`;

export const Subheading = styled.h3`
  color: ${(props) => props.theme.colors.textLight};
  font-size: ${(props) => props.theme.fontSizes.medium};
  margin-bottom: ${(props) => props.theme.spacing(1)};
`;

export const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.textLight};
  font-size: ${(props) => props.theme.fontSizes.medium};
  line-height: 1.4;
`;

export const Button = styled.button`
  background: ${(props) => props.primary ? props.theme.colors.primary : props.theme.colors.surface};
  color: ${(props) => props.primary ? '#FFF' : props.theme.colors.text};
  border: 1px solid ${(props) => props.primary ? props.theme.colors.primary : props.theme.colors.border};
  border-radius: ${(props) => props.theme.radii.small};
  padding: ${(props) => props.theme.spacing(1)} ${(props) => props.theme.spacing(2)};
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes.medium};
  transition: background 0.2s;
  &:hover {
    background: ${(props) => props.primary ? props.theme.colors.primaryLight : '#F1F3F5'};
  }
`;

export const SyncButton = styled(Button)`
  background: ${(props) => props.theme.colors.success};
  border: 1px solid ${(props) => props.theme.colors.success};
  &:hover {
    background: ${(props) => props.theme.colors.successLight};
  }
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

export const Spacer = styled.div`
  width: ${(props) => props.width || '100%'}; 
  height: ${(props) => props.height || 'auto'};
`;

export const ToggleLink = styled.span`
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  text-decoration: underline;
  font-size: ${(props) => props.theme.fontSizes.small};
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  margin-top: ${(props) => props.theme.spacing(2)};
  tr {
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
  }
  th, td {
    text-align: left;
    padding: ${(props) => props.theme.spacing(1)};
    font-size: ${(props) => props.theme.fontSizes.small};
    color: ${(props) => props.theme.colors.text};
  }
  th {
    font-weight: bold;
    background: #FAFBFC;
  }
`;

export const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid ${(props) => props.theme.colors.primary};
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const SyncIcon = styled.span`
  display: inline-block;
  margin-left: ${(props) => props.theme.spacing(1)};
  font-size: ${(props) => props.theme.fontSizes.medium};
  color: ${(props) => props.theme.colors.success};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.successLight};
  }
`;

export const Input = styled.input`
  padding: ${(props) => props.theme.spacing(1)};
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: ${(props) => props.theme.radii.small};
  font-size: ${(props) => props.theme.fontSizes.medium};
  margin-bottom: ${(props) => props.theme.spacing(2)};
  width: 100%;
  box-sizing: border-box;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: #FFF;
  font-size: 1.5em;
`;