const convo_id = 'my-convo-id';


export function RunThematic(executeProcess,data){

console.log(data)

const category_label = data.data.data[data.selection].categoryColumn
const category_group = data.data.data[data.selection].groupValue
const risk = data.data.data[data.selection].relativeRisk
const filtered_data = data.data.analysisBreakdown.filter(f=>f.category_values.includes(category_group))

// Build the prompt to execute the thematic analysis here




const prompt = `
    We ran a likelihood risk analysis on the ${category_group} ${category_label}  and found that the relative risk is ${risk}
    to score culture lower than other groups.

    We want to run a thematic analysis on the feedback from the data collected during this process.

    ${filtered_data.map((d,i)=>{
        return d.feedback.map((f)=>{
            return `Feedback: ${f?.feedback? f.feedback : f? f : 'No feedback provided'}`
        }).join('\n')
    }).join('\n')}

    ### END OF FEEDBACK

    Based on the feedback, generate a thematic analysis that idenfitifies the key themes for this group that may be contributing to the higher risk score.

`

executeProcess({conversationId: convo_id, prompt: prompt, processName: 'my-process' })


return;


}



export function RunThematicOutcome(executeProcess,data){
    
    const factor_index = data.data.data[data.selection].index.factor
    const dimension_index = data.data.data[data.selection].index.dimension
    const filtered_feedback = data.data.analysisBreakdown.filter(f=>f.factor == factor_index && f.dimension == dimension_index)

    // Build the prompt to execute the thematic analysis here
    
    
    
    
    const prompt = `   
        We want to run a thematic analysis on the feedback from the data collected during this process.
        ${filtered_feedback.map((d,i)=>{
                return `Feedback: ${d?.feedback? d.feedback : d? d : 'No feedback provided'}`
            }).join('\n')

        }
        ### END OF FEEDBACK
    
        Based on the feedback, generate a thematic analysis that idenfitifies the top 3 key themes.
    
    `
    
    executeProcess({conversationId: convo_id, prompt: prompt, processName: 'my-process' })
    
    
    return;
    
    
    }