import {
    CHAT_WORKFLOW_HISTORY_PENDING,
    GET_CHAT_WORKFLOW_HISTORY,
    ADD_CHAT_WORKFLOW_HISTORY,
    DELETE_CHAT_WORKFLOW_HISTORY,
    UPDATE_CHAT_WORKFLOW_HISTORY,
    FETCH_CHAT_WORKFLOW_HISTORY,
    CLEAR_CHAT_WORKFLOW_HISTORY,
  } from "constants/actions";
  
  const initialState = {
    data: [],
    loading: false,
    error: null,
  };
  
  export default function chatWorkflowHistoryReducer(state = initialState, action) {
    switch (action.type) {
      case CHAT_WORKFLOW_HISTORY_PENDING:
        return {
          ...state,
          loading: true,
        };
      case GET_CHAT_WORKFLOW_HISTORY:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case ADD_CHAT_WORKFLOW_HISTORY:
        return {
          ...state,
          loading: false,
          data: [...state.data, action.payload],
        };
      case DELETE_CHAT_WORKFLOW_HISTORY:
        return {
          ...state,
          loading: false,
          data: state.data.filter(item => item.id !== action.payload),
        };
      case UPDATE_CHAT_WORKFLOW_HISTORY:
        return {
          ...state,
          loading: false,
          data: state.data.map(item => item.id === action.payload.id ? action.payload : item),
        };
      case FETCH_CHAT_WORKFLOW_HISTORY:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case CLEAR_CHAT_WORKFLOW_HISTORY:
        return initialState;
      default:
        return state;
    }
  }