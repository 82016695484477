import React, { useEffect, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
    SG_GET_SLACK_USER_LIST,
  SG_GET_SLACK_CHANNEL_LIST,
} from "constants/actions";

const Container = styled.div`
  padding: 20px;
  min-height: 10vh;
  margin-top: 30px;
`;

const LandingPage = styled.div`
  text-align: center;
`;

const Header = styled.header`
  background: ${(props) => props.theme.colors.primary};
  padding: 20px;
  color: white;
`;

const MainContent = styled.main`
  padding: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const Th = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #f2f2f2;
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

const SlackLandingPage = () => {
  const dispatch = useDispatch();
  // show current domain
//   console.log("Current domain:", window.location.hostname);
  const { get_auth, get_employee, get_employee_slack, get_slack_calls } =
    useSelector(
      (state) => ({
        get_employee_slack: state.employee_slack,
        get_auth: state.auth,
        get_employee: state.employees,
        get_slack_calls: state.slack_calls,
      }),
      shallowEqual
    );
  const defaultUrl = "https://slack-bot.innerlogic.ca";
  const [hashedEmployeeId, setHashedEmployeeId] = useState(null);
  const [callBackUrl, setCallBackUrl] = useState(
    `${defaultUrl}/slack/oauth/callback`
  );

  useEffect(() => {
    if (window.location.hostname === "app.innerlogic.com") {
      setCallBackUrl("https://slack-bot.innerlogic.ca/slack/oauth/callback");
    }
    if (
      window.location.hostname === "stg-app.innerlogic.com" ||
      window.location.hostname === "demo.innerlogic.com"
    ) {
      setCallBackUrl(
        "https://stg-slack-bot.innerlogic.ca/slack/oauth/callback"
      );
    }
  }, []);

//   console.log("callbackurl", callBackUrl);
//   console.log("get_auth", get_auth);
//   console.log("get_employee", get_employee);
//   console.log("get_employee", get_employee?.userEmp);
//   console.log("get_employee_slack", get_employee_slack);
//   console.log("get_employee_slack", get_employee_slack?.employee_slack);
//   console.log("get_slack_calls", get_slack_calls.slackChannels);
//   // this shows the chanel list and employee list
//   // console.log("get_slack_calls", get_slack_calls);

  useEffect(() => {
    setHashedEmployeeId(get_employee_slack?.user_slack?.hashed_employee_id);
  }, [get_employee_slack.user_slack]);

  const handleFetchSlackEmployees = () => {
    dispatch({ type: SG_GET_SLACK_USER_LIST });
  };

  const handleFetchSlackChannels = () => {
    dispatch({ type: SG_GET_SLACK_CHANNEL_LIST });
  };

  return (
    <Container>
      <LandingPage>
        <Header>
          <h1>
            Welcome to Slack Integration - app 1* NOT WORKING PARAMS HAVE BEEN
            SET FOR APP2
          </h1>
        </Header>
        <MainContent>
          <p>
            Integrate your application with Slack to enhance your team's
            productivity. *(1231925461201.4204129117126)
          </p>
          <a
            href={`{https://slack.com/oauth/v2/authorize?client_id=1231925461201.4204129117126&scope=app_mentions:read,channels:history,channels:join,channels:read,chat:write,chat:write.customize,chat:write.public,commands,dnd:read,groups:history,groups:read,im:history,im:read,im:write,incoming-webhook,users.profile:read,users:read,mpim:read&user_scope=users.profile:read,groups:read,channels:read,mpim:read&redirect_uri=${callBackUrl}&state=${hashedEmployeeId}`}
          >
            <img
              alt="Add to Slack"
              height="40"
              width="139"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            />
          </a>
        </MainContent>
      </LandingPage>

      <LandingPage>
        <Header>
          <h1>Welcome to Slack Integration - app 2* WORKING</h1>
        </Header>
        <MainContent>
          <p>
            Integrate your application with Slack to enhance your team's
            productivity *(1231925461201.7537808644229).
          </p>
          <a
            href={`https://slack.com/oauth/v2/authorize?client_id=1231925461201.7537808644229&scope=app_mentions:read,channels:history,channels:join,channels:read,chat:write,chat:write.customize,chat:write.public,commands,dnd:read,groups:history,groups:read,im:history,im:read,im:write,incoming-webhook,users.profile:read,users:read,mpim:read&user_scope=users.profile:read,groups:read,channels:read,mpim:read&redirect_uri=${callBackUrl}&state=${hashedEmployeeId}`}
          >
            <img
              alt="Add to Slack"
              height="40"
              width="139"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            />
          </a>
        </MainContent>
      </LandingPage>

      {new URLSearchParams(window.location.search).get("success") ===
        "true" && (
        <>
          <button onClick={handleFetchSlackEmployees}>
            Fetch Slack Employees
          </button>
          <button onClick={handleFetchSlackChannels}>
            Fetch Slack Channels
          </button>

          <Table>
            <thead>
              <tr>
                <Th>Slack Employees</Th>
              </tr>
            </thead>
            <tbody>
              {get_slack_calls?.slackEmployees.map((employee, index) => (
                <tr key={index}>
                  <Td>{employee.name}</Td>
                  <Td>{employee?.email ? employee?.email : "No email"}</Td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Table>
            <thead>
              <tr>
                <Th>Channel Name (Public Channels)</Th>
                <Th>Purpose</Th>
                <Th>Members</Th>
              </tr>
            </thead>
            {get_slack_calls?.slackChannels?.channels && (
              <tbody>
                {get_slack_calls?.slackChannels?.channels.map(
                  (channel, index) => (
                    <tr key={index}>
                      <Td>{channel?.name}</Td>
                      <Td>{channel?.purpose.value}</Td>
                      <Td>{channel?.num_members}</Td>
                    </tr>
                  )
                )}
              </tbody>
            )}
          </Table>

          <Table>
            <thead>
              <tr>
                <Th>Channel Name (Private Conversations)</Th>
                <Th>Purpose</Th>
                <Th>Members</Th>
              </tr>
            </thead>
            {get_slack_calls?.slackChannels?.private_conversations && (
              <tbody>
                {get_slack_calls?.slackChannels?.private_conversations.map(
                  (channel, index) => (
                    <tr key={index}>
                      <Td>{channel?.name}</Td>
                      <Td>{channel?.purpose.value}</Td>
                      <Td>{channel?.num_members}</Td>
                    </tr>
                  )
                )}
              </tbody>
            )}
          </Table>
        </>
      )}
    </Container>
  );
};

export default SlackLandingPage;
