// ChatResponse.js
import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";
import { formatElements } from "./parseText"; // Adjust the path based on your project structure

// Components
import Text from "new_components/atoms/Text";
import IconWithBackground from "new_components/molecules/IconWithBackground";

// Constants
import colors from "styles/colors";
import typography from "styles/typography";
import { ICON_SET } from "new_components/atoms/Icon/IconSet";

// Keyframe animation for streaming text
const streamIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled Components
const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 16px;
  animation: ${streamIn} 0.5s ease-in-out;
`;

const MessageBubble = styled.div`
  max-width: 70%;
  word-wrap: break-word;
`;

const TypingIndicator = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  font-size: 14px;
  color: #888;

  span {
    animation: ${streamIn} 1s infinite alternate;
  }
`;

// Updated: Removed position: absolute; replaced with margin to keep icon offset and allow scrolling
const IconPosition = styled.div`
  /* Moves the icon roughly 50px to the left of the text by adding margin on the right side */
  margin-right: 50px;
`;

const ChatResponse = ({ message, isStreaming, isThinking, scrollRef }) => {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    // If we're NOT streaming, just show the entire message at once
    if (!isStreaming) {
      setDisplayedText(formatElements(message));
      // Scroll once to ensure we see it
      if (scrollRef?.current) {
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }

    // If streaming, reveal the message one character (or chunk) at a time
    let i = 0;
    const interval = setInterval(() => {
      i++;
      // Show the slice from 0 up to i
      const partialMessage = message.slice(0, i);
      setDisplayedText(formatElements(partialMessage));

      // Continuously scroll as new characters appear
      if (scrollRef?.current) {
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
      }

      // Once we've shown the full message, clear the interval
      if (i >= message.length) {
        clearInterval(interval);
      }
    }, 5); // Adjust speed as desired (ms per chunk)

    // Cleanup
    return () => clearInterval(interval);
  }, [message, isStreaming, scrollRef]);

  return (
    <MessageContainer>
      <IconPosition>
        <IconWithBackground
          name={ICON_SET.aiIcon}
          color={colors.background.white}
          stroke={colors.background.white}
          bgColor={colors.primary[500]}
        />
      </IconPosition>
      <MessageBubble>
        <Text typographyStyle={typography.body.regular}>{displayedText}</Text>
      </MessageBubble>
      {isThinking && (
        <TypingIndicator>
          <span>Thinking...</span>
        </TypingIndicator>
      )}
    </MessageContainer>
  );
};

ChatResponse.propTypes = {
  message: PropTypes.string.isRequired,
  isStreaming: PropTypes.bool,
  isThinking: PropTypes.bool,
};

ChatResponse.defaultProps = {
  isStreaming: false,
  isThinking: false,
};

export default ChatResponse;
