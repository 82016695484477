import React, { useState, useEffect } from "react";
import {
  Card,
  Heading,
  Paragraph,
  Button,
  ToggleLink,
  Table,
  Spacer,
  SyncIcon,
} from "ProtoType/Slack/styles/Common";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_GET_SLACK_USER_LIST,
  SG_LINK_SLACK_ID_TO_EMPLOYEE_ID,
} from "constants/actions";
import Select from "react-select";

const UserSyncPreview = () => {
  const dispatch = useDispatch();
  const { get_employee, get_employee_slack, get_slack_calls } = useSelector(
    (state) => ({
      get_errors: state.errors,
      get_employee: state.employees,
      get_employee_slack: state.employee_slack,
      get_slack_calls: state.slack_calls,
    }),
    shallowEqual
  );
  const [showDetails, setShowDetails] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [slackUsers, setSlackUsers] = useState([]);
  const [internalUsers, setInternalUsers] = useState([]);

  const itemsPerPage = 10; // Adjust this value as needed

  const {
    slackEmployees,
    nextCursor,
    previousCursor,
    totalPages,
    slackUserUpdated,
  } = get_slack_calls;

  useEffect(() => {
    if (get_employee?.employees) {
      setInternalUsers(get_employee?.employees);
    }
  }, [get_employee]);

  useEffect(() => {
    if (slackUserUpdated) {
      handleSync();
    }
  }, [slackUserUpdated]);

  const handleSync = () => {
    dispatch({
      type: SG_GET_SLACK_USER_LIST,
    });
    setShowDetails(true);
  };

  const fetchPage = (cursor) => {
    dispatch({
      type: SG_GET_SLACK_USER_LIST,
      payload: `cusor=${cursor}&page_size=${itemsPerPage}`,
    });
  };

  const handleNextPage = () => {
    if (nextCursor) {
      fetchPage(nextCursor);
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (previousCursor) {
      fetchPage(previousCursor);
      setCurrentPage(currentPage - 1);
    }
  };

  function onConfirm() {
    // Handle the confirm and sync action
    console.log("Confirming sync");
  }

  function connectSlackIdtoEmployee(slackId, employeeEmail, slackDisplayName) {
    // Implement mapping logic
    dispatch({
      type: SG_LINK_SLACK_ID_TO_EMPLOYEE_ID,
      payload: {
        slack_id: slackId,
        email: employeeEmail,
        slack_display_name: slackDisplayName,
      },
    });
  }

  return (
    <Card>
      <Heading>
        Review User Sync
        <SyncIcon onClick={handleSync} title="Start Sync Process">
          🔄 Start Sync
        </SyncIcon>
      </Heading>
      <Paragraph>
        We’ve identified {slackEmployees?.length} users that can be synchronized
        with Slack. You can proceed with the suggested mappings or adjust them
        before syncing.
      </Paragraph>

      <Paragraph>
        <ToggleLink onClick={() => setShowDetails(!showDetails)}>
          {showDetails ? "Hide Details" : "Show Mappings"}
        </ToggleLink>
      </Paragraph>

      {showDetails && (
        <>
          <Table>
            <thead>
              <tr>
                <th>Slack User</th>
                <th>Employee</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {slackEmployees &&
                slackEmployees.map((member, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        {member.id} # {member.email}
                      </td>
                      <td>
                        <EmployeeSelect
                          member={member}
                          internalUsers={internalUsers}
                          connectSlackIdtoEmployee={connectSlackIdtoEmployee}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>

          <Spacer height="20px" />
          <div>
            <Button onClick={handlePreviousPage} disabled={!previousCursor}>
              Previous
            </Button>
            <span>
              {" "}
              Page {currentPage} of {totalPages}{" "}
            </span>
            <Button onClick={handleNextPage} disabled={!nextCursor}>
              Next
            </Button>
          </div>
          <Spacer height="20px" />
          <Button primary onClick={onConfirm}>
            Confirm and Sync
          </Button>
        </>
      )}
    </Card>
  );
};

export default UserSyncPreview;

const EmployeeSelect = ({
  member,
  internalUsers,
  connectSlackIdtoEmployee,
}) => {
  const [selectedEmployee, setSelectedEmployee] = useState(
    internalUsers.find((user) => user?.email === member?.email) || null
  );
  return (
    <>
      <Select
        placeholder="Select Employee"
        onChange={setSelectedEmployee}
        value={selectedEmployee}
        options={internalUsers}
        getOptionLabel={(option) => option.email}
        getOptionValue={(option) => option.id}
      />

      <Button
        onClick={() =>
          connectSlackIdtoEmployee(
            member?.id,
            selectedEmployee?.email,
            member?.display_name
          )
        }
      >
        Connect
      </Button>
    </>
  );
};
