import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Modal, Input } from "semantic-ui-react";
import make_call from "./Request";
import { Icon,Dropdown } from "semantic-ui-react";
import { SG_CREATE_DEBRIEF_PDF_REPORT } from "constants/actions";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useToasts } from "react-toast-notifications";
import PlanPages from "./PlanPages";

const LanguageTranslations = {
  'English':'Plan generated for',
  'Turkish':'Plan oluşturuldu',
}

const languageOptions = [{ key: "English", text: "English", value: "English" }, { key: "Turkish", text: "Turkish", value: "Turkish" }];

const DiagnosticReport = ({
  context,
  action,
  selectedFactor,
  method,
  selectedOutcomeID,
  selectedOutcomeName,
  data,
  goBack,
  recommendationLevel,
  resetSteps,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [feedback, setFeedback] = useState(""); // Store feedback text
  const [isModalOpen, setModalOpen] = useState(false); // Modal visibility
  const [history, setHistory] = useState([]); // Chat history
  const [isInitialCall, setIsInitialCall] = useState(true); // Flag for first call
  const [feedbackData, setFeedbackData] = useState(null);
  const [language, setLanguage] = useState("English");
  const [translatedMessages, setTranslatedMessages] = useState({});

  let navigate = useNavigate();
  const { addToast } = useToasts();

  const selectedFilters = useSelector((state) => state.audit?.new_filters,shallowEqual);
  

  const handleLanguageChange = (e, { value }) => {
    if (value !== language) {
      setLanguage(value);
      if (translatedMessages[value]) {
        setMessage(translatedMessages[value]);
      } else {
        translateMessage(value);
      }
    }
  };


  const core_data = useSelector(
    (state) => state.audit?.core_data,
    shallowEqual
  );

  const organization = useSelector(
    (state) => state.organizations,
    shallowEqual
  );


  const get_auth = useSelector((state) => state.auth, shallowEqual);

  const requestRecommendation = () => {
    const uuid = uuidv4();
    const currentDate = new Date().toLocaleDateString();


    

    const report = {
      template_id: 11, // 11 is the template id for the action plan
      organization: Number(get_auth?.organization_id),
      employee: Number(get_auth?.employee_id),
      tracking_code: uuid,
      params: {
        report_data: message,
        feedback: feedbackData,
        planFor: `${LanguageTranslations[language]} ${getPlanFor()} - ${currentDate}`,
        filters: selectedFilters,
        selectedFactor,
        selectedOutcomeName,
        orgName: organization.organization.name,
        logo: organization.organization.logo,
        language: language,
        category_title:`${getPlanFor()}`
      },
    };

    //Call the api to generate the recommendation
    //The api will return a recommendation object
    //The recommendation object will be added to the table
    dispatch({
      type: SG_CREATE_DEBRIEF_PDF_REPORT,
      payload: report,
    });

    addToast("Action Plan saved successfully", {
      appearance: "success",
      autoDismiss: true,
    });
    resetSteps();
    navigate("/leader/audit/reports/recV4");
  };

  useEffect(() => {
    if (data) {
      let _data = [];
      let outcome_data = [];
      let _feedback = "";

      let { dimensionId, factorId } = selectedFactor;

      if (method == "ROI") {
        dimensionId = selectedFactor.dimension;
        factorId = selectedFactor.factor;
      }

      data
        ?.filter((f) => "feedback" in f)
        ?.filter((f) => f?.feedback?.length > 0)
        ?.map((item) => {
          item?.feedback?.map((f) => {
            if (f.id == dimensionId && f.factor == factorId) {
              _data.push({ feedback: f, categories: item.categories });
            }
          });
        });

      if (selectedOutcomeName) {
        data
          ?.filter((f) => "feedback_builder" in f)
          ?.filter((f) => {
            return f.feedback_builder?.responses?.length > 0;
          })
          ?.map((item) => {
            item?.feedback_builder?.responses
              ?.filter((f) => f?.response)
              ?.map((f) => {
                if (f.q == selectedOutcomeID) {
                  outcome_data.push({
                    feedback: f?.responses || f,
                    categories: item?.categories,
                    type: "outcome",
                  });
                }
              });
          });
      }

      _data.map((item) => {
        const isFollowUp = item.feedback?.prompt_feedback;
        if (isFollowUp) {
          _feedback += `## Question:\n${item.feedback.prompt_question}\n`;
          _feedback += `## Follow-up Feedback:\n${isFollowUp}\n\n`;
        }
        if (item.feedback.feedback) {
          _feedback += `## Question:\n${item.feedback.question}\n`;
          _feedback += `## Feedback:\n${item.feedback.feedback}\n\n`;
        }
      });

      if (method == "ROI") {
        _feedback += `## Feedback for the outcome ${selectedOutcomeName}\n\n`;
        outcome_data.map((item) => {
          _feedback += `## Question:\n${item.feedback.question}\n`;
          _feedback += `## Feedback:\n${item.feedback.response}\n\n`;
        });
      }

      setFeedbackData(_feedback);
    }
  }, [data]);

  useEffect(() => {
    if (feedbackData) {
      make_gpt_call();
    }
  }, [feedbackData]);


  const selectedContext = () =>{
    let context_string = ''
    if(selectedFilters[0].id == null){
      orgContext.map((item, index) => {
        context_string += `- ${item} \n`
      })
    }else{
      teamContext.map((item, index) => {
        context_string += `- ${item} \n`
      })
    }

    return context_string


  }

  const make_gpt_call = async (userFeedback = "") => {
    setLoading(true);
    setMessage("");


    let orgPrompt =`
    Using the selected culture factor ${selectedFactor.name }, and culture feedback, act as a People and Culture consultant/Organizational Psychologist to create an organization-wide action plan. The plan should focus on improving the selected culture feedback, guided from the top down. This plan is designed for you as a leader, executive, or HR/People leader, empowering you to take clear, confident action, ​​whether it is a one-time event or part of an ongoing cadence. In cases where there is an ongoing cadence, ensure the timeline for achieving all three action steps is no more than 4 months, with each step assigned a specific period within that time frame.
    
    
    The following is the feedback data for the ${
      selectedFactor.name
    } culture factor ${
  selectedOutcomeName ? `and the ${selectedOutcomeName} outcome` : ""
}:
    ${feedbackData},

Structure of the Action Plan:
Objective: Provide a detailed overview (2-3 sentences or more) that summarizes the purpose and expected outcomes of the action plan at a high or macro level. The objective should be engaging and inspiring, emphasizing the value and encouraging leaders to act. Reference how these steps will enhance the culture factor and ensure it highlights the importance of each action and sets the stage for the steps to follow.
Action Steps: Create three sequential action steps, each including:
A detailed, clear description of the step that you can execute easily. 
Clarify if this step is meant for a one-time scenario or a recurring cadence.
Key activities that outline specific actions to perform to accomplish the step effectively.
Suggested timelines for each action step (all steps should be achievable within the timeframe of the scenario (e.g., months 1 - 2, months 3 - 4 etc.).
A measurable KPI (key performance indicator) for each step, expressed in 1-2 sentences for easy tracking.
Qualitative success indicators in addition to KPIs to give you a fuller picture of what success looks like, in 1 - 2 sentences, when applicable. 
Guidelines:
Use accessible language that feels conversational and engaging, avoiding jargon while staying professional.
The tone should be motivating, as if speaking directly to you as the leader, encouraging you to take action and see the tangible benefits of following the plan.
Avoid any biased or discriminatory language to ensure the plan is inclusive and respectful.
Refrain from recommending additional surveys, external assessments, or unrelated training programs.
Avoid mentioning competitor platforms or tools unless explicitly relevant.

Action Plan Template (use markdown syntax, remove example titles where appropriate):

# [Title: {Fluid Title Incorporating -Action Plan, {factor}]

## Objective
[Provide a compelling summary of the action plan objective that outlines the main approach, key actions, and expected outcomes. Engage leaders by highlighting how these steps will create transparent, supportive communication and align employee contributions with organizational goals.]

## [Action Step 1: Subtitle (Timeline: Within Month 1)]
[Detailed, conversational description of Action Step 1 that directly encourages leaders to take action]

*Key Activities*: 
- [List of 1 to 3 specific activities you should carry out to implement this step]

*Follow-Up Strategy*: [Suggest a simple follow-up action to maintain momentum, such as sending a summary email]

### KPI for Action Step 1
[Description of KPI for Action Step 1]  

## [Action Step 2: Subtitle (Timeline: Within months 2 - 3 )]
[Engaging and detailed description of Action Step 2 that keeps leaders motivated to proceed]

*Key Activities*: 
- [List of 1 to 3 specific activities you should carry out to implement this step]

*Follow-Up Strategy*: [Suggest a simple follow-up action to maintain momentum, such as sending a summary email]

### KPI for Action Step 2
[Description of KPI for Action Step 2]  

## [Action Step 3: Subtitle (Timeline: Within month 4)]
[Clear and motivating description of Action Step 3, encouraging leaders to take the final step]

*Key Activities*: 
- [List of 1 to 3 specific activities you should carry out to implement this step]

*Follow-Up Strategy*: [Suggest a simple follow-up action to maintain momentum, such as sending a summary email]

### KPI for Action Step 3
[Description of KPI for Action Step 3]  
    `

//     let orgPrompt = `
//         The following is the feedback data for the ${
//           selectedFactor.name
//         } culture factor ${
//       selectedOutcomeName ? `and the ${selectedOutcomeName} outcome` : ""
//     }:
//         ${feedbackData}

      
//       Based on the feedback, please select a context area that would best solve the issues brought up in the feedback.
//       Select a context area that would best fit your current needs or goals. Base your plan on one of the following contexts:
//       ${selectedContext()}
      
// Act as a People and Culture Consultant/Organizational Psychologist to create a department or team-level action plan. This plan should focus on improving the chosen culture theme through intentional, manageable actions that departmental/team managers can seamlessly integrate into their regular interactions. Each action step should be practical and sequential, gradually increasing in complexity to encourage continuous growth. The plan should emphasize working on the culture factor within the selected context, ensuring that the actions are directly applicable and effective in that specific environment. The action plan should not rely on senior leadership or external resources but should be fully actionable within the individual manager’s sphere of influence.
// Structure of the Action Plan:
// Objective: Provide a concise overview (2-3 sentences) that summarizes the purpose of the action plan and its expected outcomes. It should motivate managers to engage with the plan and align with the culture theme. The objective should be engaging, emphasizing the value and encouraging the manager to act. Reference how these steps will enhance the culture factor and ensure it highlights the importance of each action and sets the stage for the steps to follow.
// Action Steps: Create three sequential action steps that build upon one another, each including:
// A clear, actionable description of the step that can be practiced within day-to-day interactions in the selected context focusing on improving the culture factor. 
// Key activities that outline specific actions managers can take to implement the step effectively.
// Practical tips for managers on how to approach and embody the culture factor during the action step.
// A brief mention of potential challenges they might face and solutions to help overcome these challenges.
// A follow-up strategy for maintaining momentum and reinforcing progress.
// Suggested timelines for each action step, aligning with a phased approach (e.g., Weeks 1-2, Weeks 3-4, Weeks 5-6).
// A measurable KPI (key performance indicator) for tracking progress, expressed in 1-2 sentences.
// Qualitative success indicators to give a fuller picture of what success looks like.
// Guidelines:
// Use accessible, engaging language that avoids jargon while maintaining a professional tone.
// The tone should be supportive and encouraging, guiding managers to incorporate the actions confidently.
// Ensure that each action step is specifically applicable to the selected context to provide relevant, practical guidance.
// Keep the actions practical, manageable, and relevant to the manager’s normal day-to-day activities.
// Ensure that the action steps are easy to understand so managers know exactly how to proceed.
// Include probing questions or examples within the action step descriptions to make them actionable.
// Avoid any reliance on resources that require approval or involvement from senior leadership.
// Ensure the plan is designed for immediate, realistic implementation within the manager’s control.
// Action Plan Template:
// # {Fluid Title Incorporating "Action Plan," {factor}, and {context}}
// ## Objective
// [Provide a concise overview of the action plan that aligns with the culture theme and motivates managers to engage in the suggested practices within the selected context.]
// ## Action Step 1: Subtitle (Timeline: Weeks 1-2)
// [Detailed description of Action Step 1 that explains what the manager should do and why it matters within the context]
// *Key Activities*: 
// - [List of specific activities to carry out during the context]
// *Tips for Managers*: 
// - [Tip 1 for how managers can approach and embody the culture factor]
// - [Tip 2 for how managers can approach and embody the culture factor]
// *Potential Challenges and Solutions*: [Mention common challenges and practical solutions]
// *Follow-Up Strategy*: [Suggest a simple follow-up action to maintain momentum, such as reflecting on the outcome or asking team members for feedback]
// ### KPI for Action Step 1
// [Description of KPI for Action Step 1]  
// *Qualitative Success Indicator*: [Description of qualitative success indicator]
// ## Action Step 2: Subtitle (Timeline: Weeks 3-4)
// [Engaging and detailed description of Action Step 2 that builds on Action Step 1 and is relevant to the context]
// *Key Activities*: 
// - [List of specific activities to carry out during the context]
// *Tips for Managers*: 
// - [Tip 1 for how managers can approach and embody the culture factor]
// - [Tip 2 for how managers can approach and embody the culture factor]
// *Potential Challenges and Solutions*: [Mention common challenges and practical solutions]
// *Follow-Up Strategy*: [Suggest a follow-up action to reinforce the positive impact, such as a recap or appreciation note]

// ### KPI for Action Step 2
// [Description of KPI for Action Step 2]  
// *Qualitative Success Indicator*: [Description of qualitative success indicator]
// ## Action Step 3: Subtitle (Timeline: Weeks 5-6)
// [Clear and motivating description of Action Step 3 that builds on previous actions and remains context-specific]
// *Key Activities*: 
// - [List of specific activities to carry out during the context]
// *Tips for Managers*: 
// - [Tip 1 for how managers can approach and embody the culture factor]
// - [Tip 2 for how managers can approach and embody the culture factor]
// *Potential Challenges and Solutions*: [Mention common challenges and practical solutions]
// *Follow-Up Strategy*: [Suggest a follow-up action, such as a reflection exercise or a team discussion to reinforce the step]
// ### KPI for Action Step 3
// [Description of KPI for Action Step 3]  
// *Qualitative Success Indicator*: [Description of qualitative success indicator]
//         `;

    // Prepare updated history, adding initial message on the first call
    const updatedHistory = isInitialCall
      ? [
          ...history,
          {
            role: "user",
            content:
              selectedFilters[0].id == null
                ? orgPrompt
                : `
                    Using the culture factor ${selectedFactor.name}, and culture feedback, act as a People and Culture Consultant/Organizational Psychologist to create a department or team-level action plan. This plan should focus on improving the chosen culture feedback through intentional, manageable actions that departmental/team managers can seamlessly integrate into their regular interactions. Each action step should be practical and sequential, gradually increasing in complexity to encourage continuous growth. The plan should emphasize working on the culture factor and associated feedback, ensuring that the actions are directly applicable and effective in that specific environment. The action plan should not rely on senior leadership or external resources but should be fully actionable within the individual manager’s sphere of influence.
                    The following is the feedback data for the ${
                      selectedFactor.name
                    } culture factor ${
                    selectedOutcomeName
                      ? `and the ${selectedOutcomeName} outcome`
                      : ""
                  }:
                    ${feedbackData}

                    ${
                      selectedOutcomeName
                        ? `Our ultimate goal is to improve ${selectedOutcomeName} via ${selectedFactor.name}`
                        : ""
                    }
Structure of the Action Plan:
Objective: Provide a concise overview (2-3 sentences) that summarizes the purpose of the action plan and its expected outcomes. It should motivate managers to engage with the plan and align with the culture feedback. The objective should be engaging, emphasizing the value and encouraging the manager to act. Reference how these steps will enhance the culture factor and ensure it highlights the importance of each action and sets the stage for the steps to follow.
Action Steps: Create three sequential action steps that build upon one another, each including:
A clear, actionable description of the step that can be practiced within day-to-day interactions focusing on improving the culture factor. 
Key activities that outline specific actions managers can take to implement the step effectively.
Suggested timelines for each action step, aligning with a phased approach (e.g., Weeks 1-2, Weeks 3-4, Weeks 5-6).
A measurable KPI (key performance indicator) for tracking progress, expressed in 1-2 sentences.
Qualitative success indicators to give a fuller picture of what success looks like, when applicable.
Guidelines:
Use accessible, engaging language that avoids jargon while maintaining a professional tone.
The tone should be supportive and encouraging, guiding managers to incorporate the actions confidently.
Ensure that each action step is specifically applicable to provide relevant, practical guidance.
Keep the actions practical, manageable, and relevant to the manager’s normal day-to-day activities.
Ensure that the action steps are easy to understand so managers know exactly how to proceed.
Include probing questions or examples within the action step descriptions to make them actionable.
Avoid any reliance on resources that require approval or involvement from senior leadership.
Ensure the plan is designed for immediate, realistic implementation within the manager’s control.
Avoid any biased or discriminatory language to ensure the plan is inclusive and respectful.
Refrain from recommending additional surveys, external assessments, or unrelated training programs.
Avoid mentioning competitor platforms or tools.
Action Plan Template (use markdown syntax, remove example titles where appropriate):
# [Title: {Fluid Title Incorporating: Action Plan, {factor}]
## Objective
[Provide a concise overview of the action plan that aligns with the culture feedback and motivates managers to engage in the suggested practices]
## [Action Step 1: Subtitle (Timeline: Weeks 1-2)]
[Detailed description of Action Step 1 that explains what the manager should do and why it matters]
*Key Activities*: 
- [List of 1-3 specific activities to carry out]
### KPI for Action Step 1
[Description of KPI for Action Step 1]  
## [Action Step 2: Subtitle (Timeline: Weeks 3-4)]
[Engaging and detailed description of Action Step 2 that builds on Action Step 1 and is relevant]
*Key Activities*: 
- [List of 1-3 specific activities to carry out]
### KPI for Action Step 2
[Description of KPI for Action Step 2]  
## [Action Step 3: Subtitle (Timeline: Weeks 5-6)]
[Clear and motivating description of Action Step 3 that builds on previous actions and is specific]
*Key Activities*: 
- [List of 1-3 specific activities to carry out]
### KPI for Action Step 3
[Description of KPI for Action Step 3]  
 `,
          },
        ]
      : [...history, { role: "user", content: userFeedback }];

      console.log(updatedHistory)

    const assistantResponse = await make_call(updatedHistory, (chunk) =>
      setMessage((prev) => prev + chunk)
    );

        // Inside make_gpt_call after receiving assistantResponse
    setTranslatedMessages((prev) => ({
      ...prev,
      [language]: assistantResponse.trim(),
    }));

    setLoading(false);
    setHistory((prevHistory) => [
      ...updatedHistory,
      { role: "assistant", content: assistantResponse.trim() },
    ]);
    setIsInitialCall(false);
  };

  const handleRegenerateClick = () => {
    setModalOpen(true); // Open the modal
  };

  const translateMessage = async (targetLanguage) => {
    setLoading(true);
    setMessage("");
  
    const translationPrompt = `Please translate the following text into ${targetLanguage} without changing the formatting or structure:
  
    ${translatedMessages["English"] || message}
    `;
  
    const updatedHistory = [
      ...history,
      { role: "user", content: translationPrompt },
    ];
  
    const assistantResponse = await make_call(updatedHistory, (chunk) =>
      setMessage((prev) => prev + chunk)
    );
  
    setLoading(false);
    setHistory((prevHistory) => [
      ...prevHistory,
      { role: "user", content: translationPrompt },
      { role: "assistant", content: assistantResponse.trim() },
    ]);
  
    setTranslatedMessages((prev) => ({
      ...prev,
      [targetLanguage]: assistantResponse.trim(),
    }));
  };

  const handleRegenerateWithFeedback = () => {
    setModalOpen(false); // Close the modal
    make_gpt_call(feedback); // Call the function with feedback
    setFeedback(""); // Clear feedback after use
  };

  const handleRegeneratePDFClick = () => {
    // Save the plan and generate PDF
    requestRecommendation();
  };

  //function to get the category parent name based on the category id
  const getCategoryParentName = (categoryId) => {
    if (core_data?.categories?.[0]?.categories) {
      const category = core_data.categories[0].categories.find(
        (category) => category.id === categoryId
      );
      return category?.name ? category.name : "";
    }
  };


  const getPlanFor = () => {

    if(selectedFilters[0].id == null){
      return 'entire organization'

    }

    let names = ''
    selectedFilters.map((item, index) => {
      names += getCategoryParentName(item.parent) + ': ' + item.name + ' '
    }
    )
    return names
  };



  return (
    <Container>
      <BackButton onClick={goBack}>
        <Icon name="chevron left" /> Back
      </BackButton>
      <Title>Action Plan</Title>

      <T2>
        This plan is being developed for <Label>{selectedFactor.name}</Label>
      </T2>
      

      <Line />
      <div style={{display:'flex'}}>
        <PlanPages 
        planFor={getPlanFor()}
        orgName={organization.organization.name}
        message={message}
        language={language} />

        {!loading && (<Options>
          <T3>Plan Options</T3>

          <LanguageSelect>
            <SubTitle>Plan Language</SubTitle>
            <Description>
              Select the language for the generated action plan.
            </Description>
            <Dropdown
              placeholder="Select Language"
              fluid
              selection
              options={languageOptions}
              onChange={handleLanguageChange}
              value={language}
            />
        </LanguageSelect>
        </Options>)}

      </div>
     
     
      {!loading && (
        <Steps>
          <T4>Next Steps</T4>

          <Description>
            Now that we've generated your action plan, here are your next steps.
          </Description>

          <ButtonArea>
            <Button
              onClick={() => {
                handleRegeneratePDFClick();
              }}
            >
              Save Plan & Generate PDF
            </Button>
          </ButtonArea>
        </Steps>
      )}

      {/* Modal for feedback */}
      <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Header>Provide Feedback for Regeneration</Modal.Header>
        <Modal.Content>
          <Input
            fluid
            placeholder="Enter your feedback here..."
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />
        </Modal.Content>
        <Modal.Actions>
          <ButtonArea>
            <Button onClick={() => setModalOpen(false)}>Cancel</Button>
            <Button primary onClick={handleRegenerateWithFeedback}>
              Regenerate
            </Button>
          </ButtonArea>
        </Modal.Actions>
      </Modal>
    </Container>
  );
};

export default DiagnosticReport;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  width: 100%;
  font-family: "Raleway";
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  color: grey;
`;

const Description = styled.div`
  color:#666D79;
  margin-bottom:10px;
`;

const Line = styled.div`
  width: 100%;
  border-top: 1px solid #ddd;

  margin-top: 0px;
  margin-bottom: 20px;
`;

const T2 = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1.2;
`;

const Label = styled.span`
  color: #2d70e2;
  font-weight: bold;
`;

const T3 = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
  color:#2A3039;
`;

const SubTitle = styled.div`
  color:#2A3039;
  font-size: 14px;
  font-weight: 600;
`

const Summary = styled.div`
  margin-top: 20px;
  padding-bottom: 20px;
  position: relative;
`;

const Steps = styled.div`
  margin-top: 30px;
  width: 100%;
  padding: 20px;
  border-top: 1px solid #ddd;
  margin-bottom: 50px;

  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
`;
const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: flex-end;
`;
const Button = styled.div`
  background-color: #2d70e2;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
  font-weight: bold;
`;

const T4 = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #2a3039;
`;

const Regenerate = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  background-color: #2d70e2;
  padding: 8px 14px;
  border-radius: 5px;

  position: absolute;
  right: 0px;
  top: -50px;
`;

const IMG = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const BackButton = styled.div`
  color: #2d70e2;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 10px;
`;

const LanguageSelect = styled.div`

`

const Options = styled.div`
  margin-left: 20px;
   opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
`





const orgContext = [
  "Town halls or all hands meetings",
  "Executive/SLT led Q&A’s or ask me anything sessions",
  "Video updates to remote organizations or multi location organizations",
  "Company-wide update communication (i.e. weekly, biweekly or month email)",
  "Annual or biannual company retreats",
  "Onboarding and orientation",
  "Celebratory or milestone events",
  "Crisis communication"
]

const teamContext = [
  "Teambuilding",
  "Feedback and retrospective sessions",
  "Ongoing feedback loops",
  "One-on-one meetings or check ins",
  "Cross-departmental/cross-functional meetings",
  "Team-meetings or team stand ups or daily check ins",
  "Celebratory events or wins meetings",
  "Recognition and appreciation moments",
  "Goal-setting and planning sessions",
  "Performance management/evaluation/performance reviews/feedback sessions",
  "Brainstorm/ideation sessions",
  "Project status or progress meetings",
  "Crisis or high stress situations",
  "Cross-functional collaboration"
]

