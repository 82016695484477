import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Components
import SubMenuTemplate from "new_components/templates/SubMenuTemplate";
import Text from "new_components/atoms/Text";
import SectionHeader from "./SectionHeader";

// Constants
import colors from "styles/colors";
import typography from "styles/typography";
import { ICON_SET } from "new_components/atoms/Icon/IconSet";

const ResponsesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ResponseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 10px 40px;

  border-bottom: 1px solid ${colors.neutral[200]};
`;

const QuestionWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`;


const FollowupSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 10px;
  background-color: ${colors.neutral[200]};
  border-radius: 8px;
`;

const ResponseContainer = styled.div`
  margin-top: 10px;
`;

const removeFeedback = (question) => {
  return question.replace(/\*\*Feedback:\*\*.*$/, "").trim();
};

const IndividualResponse = ({ responses, participantName,questions }) => {
  const questionResponses = responses?.task_list;
  return (
    <ResponsesWrapper>
      <Text typographyStyle={typography.subhead.semibold}>
        {participantName}
      </Text>
      {questionResponses.map((response, index) => {
        const scale = questions[index]?.type === "scale";
        const scaleValue = scale
          ? questions[index]?.scale === "0-5" ||
            questions[index]?.scale === "1-5"
            ? 5
            : 10
          : null;
        return (
          <ResponseWrapper key={index}>
            <QuestionWrapper>
              <Text typographyStyle={typography.smallText.semibold}>
                {response?.question}
              </Text>
            </QuestionWrapper>
            <Text typographyStyle={typography.body.regular}>
              {response?.response} {scale ? "/" + scaleValue : null}
            </Text>
            {response?.follow_up && (
              <FollowupSection key={index}>
                <Text
                  typographyStyle={typography.caption.semibold}
                  color={colors.primary[500]}
                >
                  AI Follow up
                </Text>
                <Text typographyStyle={typography.caption.semibold}>
                  {removeFeedback(response?.follow_up?.question)}
                </Text>
                <ResponseContainer>
                  <Text typographyStyle={typography.caption.regular}>
                    {response?.follow_up?.response}
                  </Text>
                </ResponseContainer>
              </FollowupSection>
            )}
          </ResponseWrapper>
        );
      })}
    </ResponsesWrapper>
  );
};

const IndividualView = ({ responses, clearUser, selectedParticipant,questions }) => {
  const [selectedUser, setSelectedUser] = useState();

  const handleUserSelect = (user) => {
    clearUser();
    setSelectedUser(user.id);
  };
  useEffect(() => {
    if (selectedParticipant) {
      setSelectedUser(selectedParticipant?.employee_id);
    }
    clearUser();
  }, [selectedParticipant]);

  const menuItems = responses.map((participant, index) => {
    return {
      id: participant.employee,
      categoryTitle: participant.employee_full_name,
      categoryContent: (
        <IndividualResponse
          responses={participant.task_response}
          participantName={participant.employee_full_name}
          questions={questions}
        />
      ),
    };
  });

  return (
    <SubMenuTemplate
      onItemSelect={handleUserSelect}
      menuItems={menuItems}
      selected={selectedUser}
      type="single"
      actionRow={<SectionHeader icon={ICON_SET.assign} text="Individuals" />}
      searchable
    />
  );
};

export default IndividualView;
