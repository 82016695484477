import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  Dimmer,
  Loader,
  Header,
  Icon,
  Dropdown,
  Modal,
} from "semantic-ui-react";
import Select from "react-select";

import { LockIcon, SaveIcon, XIcon, CategoryIcon, TrashIcon } from "members/audit/Icons";
import {
  SG_GET_EMPLOYEE_PERMISSIONS,
  SG_FETCH_EMPLOYEE_PERMISSION,
  SG_EDIT_EMPLOYEE_PERMISSION,
  SG_ADD_EMPLOYEE_PERMISSION,
  SG_ADD_EMPLOYEE_LOG,
  SG_GET_PROD_ORG_CONTRACT,
} from "constants/actions";
import { useParams } from "react-router";

import format from "date-fns/format";


const CategoryPermission = ({
  selectedEmployee, employeeCategory, currentUser,
  primaryApiOptions }) => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [currentPermission, setCurrentPermission] = useState([]);
  const [currentEmpCategory, setCurrentEmpCategory] = useState([]);
  const [allSurveyOptions, setAllSurveyOptions] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState([]);
  const { id } = useParams();

  const {
    get_survey_structure,
    get_employee_permission,
  } = useSelector(
    (state) => ({
      get_survey_structure: state.surveystructure.survey_structure,
      get_employee_permission: state.employee_permission,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch({
      type: SG_GET_PROD_ORG_CONTRACT,
    });
    if (id) {
      dispatch({
        type: SG_GET_EMPLOYEE_PERMISSIONS,
        payload: `employee=${id}`,
      });
    }
  }, [dispatch, id]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (loading === true) {
        setLoading(false)
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [loading]);

  useEffect(() => {
    setCurrentEmpCategory(
      employeeCategory?.[0]?.category.filter(
        ec => ec.priority === "primary"))
  }, [employeeCategory]);

  useEffect(() => {
    if (get_employee_permission) {
      setLoading(get_employee_permission?.pending)
    }
    if (get_employee_permission?.updated) {
      setCurrentPermission(get_employee_permission?.updated)
      return
    }
    if (get_employee_permission?.created) {
      setCurrentPermission(get_employee_permission?.created)
      return
    }
    setCurrentPermission(get_employee_permission?.employee_permission?.[0])
  }, [get_employee_permission]);


  // console.log(currentUser)

  useEffect(() => {
    const _optionList = [];
    primaryApiOptions.map((po) => {
      return _optionList.push({
        name_id: po?.name_id,
        name: po?.value,
        value_id: po?.value_id,
        value: `Primary - ${po?.value}`,
        text: "Primary",
      });
    });
    if (currentUser?.account_type === 5) {
      if (get_survey_structure?.[0]?.depth === 1) {
        get_survey_structure?.[0]?.categories
          .filter(
            (ca) => ca.priority !== "primary" && ca.visibility === "public"
          )
          .map((ss) => {
            ss?.options.map((op) =>
              _optionList.push({
                name_id: ss?.id,
                name: ss?.name,
                value_id: op?.id,
                value: `${ss.name} - ${op.name}`,
                text: op.name,
              })
            );
            return null;
          });
      }
      // subfactors where depth === 2
      if (get_survey_structure?.[0]?.depth === 2) {
        get_survey_structure?.[0]?.categories
          .filter(
            (ca) => ca.priority !== "primary" && ca.visibility === "public"
          )
          .subfactors?.map((ss) => {
            ss?.options.map((op) =>
              _optionList.push({
                name_id: ss?.id,
                name: ss?.name,
                value_id: op?.id,
                value: `${ss.name} - ${op.name}`,
                text: op.name,
              })
            );
            return null;
          });
      }
    } else {
      // non admin user
      const _validList = currentUser?.employee_employee_category?.category
        .filter((ca) => ca.priority !== "primary")
        .map((cc) => cc.value_id);
      if (get_survey_structure?.[0]?.depth === 1) {
        get_survey_structure?.[0]?.categories
          .filter(
            (ca) => ca.priority !== "primary" && ca.visibility === "public"
          )
          .map((ss) => {
            ss?.options.map((op) => {
              if (_validList.includes(op?.id)) {
                _optionList.push({
                  name_id: ss?.id,
                  name: ss?.name,
                  value_id: op?.id,
                  value: `${ss.name} - ${op.name}`,
                  text: op.name,
                });
              }
              return null;
            });
            return null;
          });
      }
      // subfactors where depth === 2
      if (get_survey_structure?.[0]?.depth === 2) {
        get_survey_structure?.[0]?.categories
          .filter(
            (ca) => ca.priority !== "primary" && ca.visibility === "public"
          )
          .subfactors?.map((ss) => {
            ss?.options.map((op) => {
              if (_validList.includes(op?.id)) {
                _optionList.push({
                  name_id: ss?.id,
                  name: ss?.name,
                  value_id: op?.id,
                  value: `${ss.name} - ${op.name}`,
                  text: op.name,
                });
              }
              return null;
            });
            return null;
          });
      }
    }

    setAllSurveyOptions(_optionList);
  }, [get_survey_structure, currentUser, primaryApiOptions]);

  function handlePermissionChange(e) {
    setSelectedPermission(e)
  }

  function removePermission(rec) {
    const _curList = currentPermission?.details.filter(
      cp => cp?.value_id !== rec?.value_id
    )

    dispatch({
      type: SG_EDIT_EMPLOYEE_PERMISSION,
      payload: {
        body: {
          id: currentPermission?.id,
          details: _curList,
        },
        query: `organization=${currentUser?.organization}`,
      },
    });

  }


  function handleSavePermission() {
    if (currentPermission) {
      if (currentPermission?.id) {
        const _curList = currentPermission?.details
        _curList.push(selectedPermission)

        dispatch({
          type: SG_EDIT_EMPLOYEE_PERMISSION,
          payload: {
            body: {
            id: currentPermission?.id,
           details: _curList
          },
          query: `organization=${currentUser?.organization}`,}
        });
      }
    } else {
      dispatch({
        type: SG_ADD_EMPLOYEE_PERMISSION,
        payload: {
          body: {
            employee: id,
          details: [selectedPermission]
        },
        query: `organization=${currentUser?.organization}`,}
      });
      
    }

  }

  return (
    <Dimmer.Dimmable blurring dimmed={loading}>
      <EditContainer>
        {/* CATEGORY SELECTION SECTION */}
        <CategorySection>
          <CategoryHeader>
            <CategoryLabel>Detailed Permissions</CategoryLabel>
            <SubCopy>Assign granular permissions based on any category. When a category is assigned to a user, they will only ever be able to see data and users based on that category
            </SubCopy>
          </CategoryHeader>
         {/* <CategoryListRow>
            <CategoryListItem>
              Reports Access*
            </CategoryListItem>
            <CategoryListItem found={selectedEmployee?.access_data_analytics === 1}>
              {selectedEmployee?.access_data_analytics ? "Can " : "Cannot "}
              Access Data Analytics
            </CategoryListItem>
            <CategoryListItem found={selectedEmployee?.access_subfactor_data === 1}>
              {selectedEmployee?.access_subfactor_data ? "Can " : "Cannot "}
              Access Sub Factor Data
            </CategoryListItem>
          </CategoryListRow>
          <CategoryListRow>
            <CategoryListItem>
              Default Access For*
            </CategoryListItem>
            {currentEmpCategory && currentEmpCategory.map((ec, i) => {
              return <CategoryListItem found key={i}>
                {ec?.value}
              </CategoryListItem>
            })}
          </CategoryListRow>
          {currentEmpCategory ?
            currentUser?.account_type === 5 ?
              <CategoryListItem found={true}>
                The user is Admin and these filters would NOT be useful</CategoryListItem> :
              <CategoryLabel>The values below would be filtered for Category above</CategoryLabel> :
            <CategoryLabel>Valid only if you have a Category Selected</CategoryLabel>
          }*/}
          {currentEmpCategory &&
            <>
              <CategoryRowInfo>
                <TableContainer>
                  <TopTableRow>
                    {/*<TableHeading>Id</TableHeading>*/}
                    <TableHeading>Category</TableHeading>
                    <TableHeading></TableHeading>
                  </TopTableRow>
                  {currentPermission?.details &&
                    currentPermission?.details.map((rec, ii) => {
                      return (
                        <TableRow key={ii}>
                          {/*<TableContent>
                            {rec?.value_id}
                          </TableContent>*/}
                          <TableContent>
                            {rec?.value}
                          </TableContent>
                          <TableContent>
                            <Icon onClick={() => removePermission(rec)} link name='trash' />
                          </TableContent>
                        </TableRow>
                      );
                    })}
                </TableContainer>
              </CategoryRowInfo>
              <CategoryRowInfo>
                <ControlWrapper>
                  <CategoryTag>Category List</CategoryTag>
                  <TableContent>
                    <SelectionContainer>
                      <Select
                        placeholder="Select an option"
                        onChange={(e) => handlePermissionChange(e)}
                        // value={SetValue}
                        options={allSurveyOptions}
                        // isLoading={isLoading === variable ? isLoading : false}
                        getOptionLabel={(allSurveyOptions) => allSurveyOptions?.value}
                        getOptionValue={(allSurveyOptions) => allSurveyOptions?.value_id}
                      />
                    </SelectionContainer>
                  </TableContent>
                  <TableContent>
                    <SaveButton onClick={handleSavePermission}>
                      <SaveIconWrapper>{SaveIcon()}</SaveIconWrapper>
                      <SaveText>Add permission</SaveText>
                    </SaveButton>
                  </TableContent>
                </ControlWrapper>
              </CategoryRowInfo>
            </>
          }
        </CategorySection>
      </EditContainer>
      <Dimmer active={loading}>
        <Loader />
        <Header as="h2" icon inverted>
          Loading, Please wait.
        </Header>
      </Dimmer>
    </Dimmer.Dimmable>
  );
};

export default CategoryPermission;



const TableRow = styled.div`
  height: 82px;
  display: flex;
  background: #ffffff;
  /* drop */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  /* Inside auto layout */
  margin-top: 5px;
  order: 0;
  flex-direction: row;
  flex-grow: 0;
  :hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.87);
  }
`;


const TableContentLabel = styled.div`
  width: 300px;
  padding-left: 40px;
  text-align: inherit;
  align-self: center;
  font-family: "Poppins";
  font-style: normal;
  flex-direction: row;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #7e7e7e;
`;

const TableContent = styled.div`
  width: 75%;
  padding-left: 10px;
  text-align: inherit;
  align-self: center;
  font-family: "Poppins";
  font-style: normal;
  flex-direction: row;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  // margin-right: 150px;
  color: #7e7e7e;
`;

const CategoryTag = styled.div`
  width: 250px;
  text-align: inherit;
  align-self: center;
  font-family: "Poppins";
  font-style: normal;
  flex-direction: row;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #7e7e7e;
`;



const SelectionContainer = styled.div`
  padding-left: 25px;
  width: 300px;
`;


const SaveIconWrapper = styled.div`
  padding-right: 8px;
  cursor: pointer;
`;

const SaveText = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 15px;
  /* identical to box height */
  cursor: pointer;

  color: #ffffff;
`;

const SaveButton = styled.div`
  padding: 3px 20px;
  cursor: pointer;
  width:150px;
  background: #476dfa;
  border-radius: 100px;
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left:10px;
`;

const TableHeading = styled.div`
  width: 25%;
  padding-left: 10px;
  text-align: inherit;
  align-self: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #414141;
`;

const TopTableRow = styled.div`
  display: flex;
  background: #ffffff;
  height: 51px;
  padding-bottom: 5px;
  /* drop */
  flex-direction: row;

  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
`;

const TableContainer = styled.div`
  width: 100%;
  padding-top: 35px;
`;

const ControlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 5px;
  padding-left:20px;
`;


const ToggleSlider = styled.div`
  position: relative;
  width: 40px;
  height: 16px;
  background: #e7e7e7;
  border-radius: 26.6667px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 15px;
    height: 16px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: ${(props) => (props.isChecked ? "#66d686" : "#c0c0c0")};
    transform: translate(0, -50%);
  }
`;

const CategorySection = styled.div`
  padding-left: 83px;
  padding-top: 40px;
`;

const CategoryHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;

const CategoryLabel = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* identical to box height */
  color: #414141;
  margin-bottom:10px;
`;

const IconWrapper = styled.div``;

const RowInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
  width: 566px;
  justify-content: space-between;
  padding-left: 83px;
`;

const CategoryRowInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 50px;
  width: 566px;
  justify-content: space-between;
`;

const CategoryListRow = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-bottom: 20px;
`;

const CategoryListItem = styled.div`
font-family: "Poppins";
font-style: normal;
font-weight: 500;
font-size: 16px;
background: ${(props) => (props.found ? "#66d686" : "#c0c0c0")};
padding: 5px 10px;
margin: 0 5px
`

const EditContainer = styled.div`
  flex: 1;
  padding-bottom: 50px;
`;


const SubCopy = styled.div`
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 24px;
display: flex;
align-items: center;
max-width:800px;
color: #7E7E7E;

`