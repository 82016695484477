import React from "react";
import styled from "styled-components";
import colors from "styles/colors"; // Adjust this to your color configuration

// Styled components for the toggle
const StyledSwitch = styled.div`
  min-width: 32px; /* Smaller width */
  height: 12px; /* Shorter height */
  background-color: ${({ isActive }) =>
    isActive ? colors.secondary[500] : colors.neutral[200]};
  border-radius: 9999px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 2px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  box-shadow: ${({ isActive }) =>
    isActive
      ? "0 3px 5px rgba(39, 205, 167, 0.3)"
      : "0 2px 3px rgba(0, 0, 0, 0.1)"};
`;

const StyledThumb = styled.div`
  width: 12px; /* Smaller thumb */
  height: 12px; /* Smaller thumb */
  background-color: ${colors.background.white};
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isActive }) =>
    isActive ? "translateX(20px)" : "translateX(0)"};
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
`;

const Toggle = ({ isActive, onToggle }) => {
  const handleClick = () => {
    if (onToggle) {
      onToggle(!isActive);
    }
  };

  return (
    <StyledSwitch isActive={isActive} onClick={handleClick}>
      <StyledThumb isActive={isActive} />
    </StyledSwitch>
  );
};

export default Toggle;
