import React from "react";
import styled from "styled-components";

// Components
import Text from "new_components/atoms/Text";

// Constants
import colors from "styles/colors";
import typography from "styles/typography";

const Card = styled.div`
  align-self: flex-end;
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px;
  background-color: ${colors.background.lightBlue};
  border-radius: 8px;
  transition: box-shadow 0.2s ease;
  max-width: 60%;
`;

const ChatDialogBox = (props) => {
  const { message } = props;

  return (
    <Card>
      <Text typographyStyle={typography.body.regular}>{message}</Text>
    </Card>
  );
};

export default ChatDialogBox;
