import { call, put, all, takeLatest, takeEvery } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_EMPLOYEE_SLACK,
  SG_GET_EMPLOYEE_SLACK,
  ADD_EMPLOYEE_SLACK,
  SG_ADD_EMPLOYEE_SLACK,
  DELETE_EMPLOYEE_SLACK,
  SG_DELETE_EMPLOYEE_SLACK,
  UPDATE_EMPLOYEE_SLACK,
  SG_UPDATE_EMPLOYEE_SLACK,
  FETCH_EMPLOYEE_SLACK,
  SG_FETCH_EMPLOYEE_SLACK,
  ALL_ERRORS,
} from "constants/actions";

function* loadEmployeeSlack(action) {
  try {
    const json = yield call(api.GET_EMPLOYEE_SLACK, action.payload);
    yield put({ type: GET_EMPLOYEE_SLACK, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeSlackLoad() {
  yield takeLatest(SG_GET_EMPLOYEE_SLACK, loadEmployeeSlack);
}

function* fetchEmployeeSlack(action) {
  try {
    const json = yield call(api.FETCH_EMPLOYEE_SLACK, action.payload);
    yield put({ type: FETCH_EMPLOYEE_SLACK, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeSlackFetch() {
  yield takeLatest(SG_FETCH_EMPLOYEE_SLACK, fetchEmployeeSlack);
}

function* updateEmployeeSlack(action) {
  try {
    const json = yield call(api.UPDATE_EMPLOYEE_SLACK, action.payload);
    yield put({ type: UPDATE_EMPLOYEE_SLACK, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeSlackUpdate() {
  yield takeEvery(SG_UPDATE_EMPLOYEE_SLACK, updateEmployeeSlack);
}

function* addEmployeeSlack(action) {
  try {
    const json = yield call(api.ADD_EMPLOYEE_SLACK, action.payload);
    yield put({ type: ADD_EMPLOYEE_SLACK, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeSlackAdd() {
  yield takeLatest(SG_ADD_EMPLOYEE_SLACK, addEmployeeSlack);
}

function* deleteEmployeeSlack(action) {
  try {
    yield call(api.DELETE_EMPLOYEE_SLACK, action.payload);
    yield put({ type: DELETE_EMPLOYEE_SLACK, payload: action.payload });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeSlackDelete() {
  yield takeLatest(SG_DELETE_EMPLOYEE_SLACK, deleteEmployeeSlack);
}

export default function* index() {
  yield all([
    employeeSlackLoad(),
    employeeSlackFetch(),
    employeeSlackUpdate(),
    employeeSlackAdd(),
    employeeSlackDelete(),
  ]);
}